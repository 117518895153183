import {
  InfoCircleOutlined, SecurityScanOutlined
} from "@ant-design/icons";
import { Card, Col, Popover, Row, Space, Statistic } from "antd";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Images } from "../../common/images";
import ProviderCard from "../../components/providerCard/provider-card";
import {
  useDocumentstatsQuery,
  useGetProviderQuery,
} from "../../redux/api/provider/provider";
import { skipToken } from "@reduxjs/toolkit/query";
import IncredableButton from "../../components/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import Exclusions, { NoExlusions } from "../modify/excution";

export default function Overview() {
  const { providerId } = useParams();

  const { data: selectedProvider } = useGetProviderQuery(
    !!providerId
      ? {
          providerId: providerId,
        }
      : skipToken
  );

  const { data: providerDocumentsStats } = useDocumentstatsQuery(
    !!providerId ? { providerID: providerId } : skipToken
  );

  const navigate = useNavigate();

  const generalInfo = [
    { label: "First Name", value: selectedProvider?.firstName || "N/A" },
    { label: "Last Name", value: selectedProvider?.lastName || "N/A" },
    { label: "Email", value: selectedProvider?.email || "N/A" },
    { label: "NPI Number", value: selectedProvider?.npiNumber || "N/A" },
    { label: "Facility", value: selectedProvider?.facility?.name || "N/A" },
    {
      label: "License Type",
      value: selectedProvider?.licenseType?.name || "N/A",
    },
  ];

  return (
    <>
      <ProviderCard
        title="General Information"
        extraButton={{
          text: "Edit",
          icon: Images.edit2,
          onClick: () => {
            if (!!providerId) navigate(`/manageprovider/${providerId}/edit`);
          },
        }}
        data={generalInfo}
      />
      <Card
        title={"Documents Summary"}
        extra={
          <IncredableButton
            type="primary"
            icon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
            iconPosition="end"
            onClick={() => navigate(`/safe?providerId=${providerId}`)}
          >
            Safe
          </IncredableButton>
        }
        style={{ marginTop: "24px" }}
      >
        <Row gutter={16}>
          <Col span={8}>
            <Card bordered={true}>
              <Statistic
                title="Total count"
                value={providerDocumentsStats?.totalDocument ?? "0"}
                valueStyle={{ color: "#3f8600" }}
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card bordered={true}>
              <Statistic
                title={
                  <Space>
                    Expiring count
                    <Popover
                      content={
                        "Documents with expiration dates within the next 90 days are considered expiring documents."
                      }
                    >
                      <InfoCircleOutlined />
                    </Popover>
                  </Space>
                }
                value={providerDocumentsStats?.expiringDocument ?? "0"}
                valueStyle={{ color: "#faad14" }}
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card bordered={true}>
              <Statistic
                title="Expired count"
                value={providerDocumentsStats?.expiredDocument ?? "0"}
                valueStyle={{ color: "#cf1322" }}
              />
            </Card>
          </Col>
        </Row>
      </Card>
        <Card
        style={{ margin: "24px 0 20px 0" }}
        title={
          <span style={{ fontSize: "22px", fontWeight: "700" }}>
            Exclusions
          </span>
        }
        extra={ selectedProvider && selectedProvider?.npiNumber? "" :
          <IncredableButton
            icon={<SecurityScanOutlined />}
            iconPosition="end"
            type="primary"
          >
            Scan
          </IncredableButton>
        }
        styles={{
          header: { borderBottom: "1px solid #A884C9" },
        }}
      >
        {selectedProvider && selectedProvider?.npiNumber ? (
          <Exclusions
            key={selectedProvider?.npiNumber}
            npi_number={selectedProvider?.npiNumber}
          />
        ) : (
          <NoExlusions />
        )}
      </Card>
    </>
  );
}

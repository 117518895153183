import { Space, Spin, Table, theme, Typography } from "antd";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import IncredableButton from "../components/button";
import IncredableContent from "../components/incredable-content";
import TableTitle from "../components/incredable-table/table-title";
import { DigitalFormType } from "../redux/api/digitalform/types";
import { useDeleteDigitalFormTypeMutation, useGetAllDigitalFormTypesQuery } from "../redux/api/digitalform/digitalforms";
import { TablePreferenceModal } from "../common/TablePreferenceModal";
import DeleteAlertModal from "../common/Modal/delete-alert-modal";
import { SpinnerContext } from "../context/spinner/spinner";
import { ToastContext } from "../context/toast/incredable-toast";

const { getDesignToken } = theme;
const globalToken = getDesignToken();

export const digitalformtypeColumns = [
    {
        title: () => <Typography.Text>Type</Typography.Text>,
        key: "type",
        ellipsis: true,
        dataIndex: ["type"],
        render: (type: string) => <Typography.Text>{type || "-"}</Typography.Text>,
        sorter: (a: DigitalFormType, b: DigitalFormType) => {
            return a?.type?.toLowerCase()?.localeCompare(b?.type?.toLowerCase());
        },
    },
];

export default function ReadyFormTypeList() {
    const navigate = useNavigate();
    const [selectedRow, setSelectedRow] = useState<DigitalFormType>();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [selectedColumns, setSelectedColumns] = useState<string[]>(
        digitalformtypeColumns.map((col) => col.key as string)
    );
    const [visibleColumnOrder, setVisibleColumnOrder] = useState<string[]>(
        digitalformtypeColumns.map((col) => col.key as string)
    );
    const spinnerContext = useContext(SpinnerContext);
    const toastContext = useContext(ToastContext);
    const [deleteDigitalFormType] = useDeleteDigitalFormTypeMutation();

    const {
        data: digitalformType,
        isLoading,
        isFetching,
        error,
        refetch,
    } = useGetAllDigitalFormTypesQuery();

    const visibleColumns = visibleColumnOrder
        .filter((colKey) => selectedColumns.includes(colKey))
        .map((colKey) => digitalformtypeColumns.find((col) => col.key === colKey)!);

    if (isLoading) {
        return <Spin />;
    }

    if (error) {
        return <div>Error loading ready form types</div>;
    }
    const selectRow = (record: DigitalFormType) => {
        if (selectedRow?.id + "" === record?.id + "") {
            setSelectedRow(undefined);
        } else {
            setSelectedRow(record);
        }
    };

    const handleOpenModal = () => {
        setIsModalVisible(true);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
    };

    const handleOpenDeleteModal = () => {
        setDeleteModalOpen(true);
    };

    const handleSavePreferences = (
        selectedColumns: string[],
        columnOrder: string[]
    ) => {
        setSelectedColumns(selectedColumns);
        setVisibleColumnOrder(columnOrder);
        handleCloseModal();
    };

    return (
        <>
            <IncredableButton style={{
                margin: "12px 0 0 48px",
                padding: "12px",
            }}
                secondary
                onClick={() => navigate(-1)}
            >
                Go back
            </IncredableButton>
            <IncredableContent style={{ margin: "12px 0 0 48px" }} spinning={isLoading || isFetching}>
                <Table
                    size="small"
                    rowKey="id"
                    dataSource={
                        !!digitalformType
                            ? [...digitalformType]
                            : []
                    }
                    columns={visibleColumns}
                    loading={isLoading || isFetching}
                    onRow={(record) => ({
                        onClick: () => {
                            selectRow(record);
                        },
                    })}
                    rowSelection={{
                        type: "radio",
                        selectedRowKeys: !!selectedRow?.id ? [selectedRow?.id] : [],
                        onChange: (selectedRowKeys, selectedRows, info) => {
                            if (!!selectedRows && !!selectedRows?.at(0))
                                setSelectedRow(selectedRows?.at(0));
                        },
                        columnWidth: 60,
                    }}
                    pagination={{
                        position: ["bottomRight"],
                        style: { backgroundColor: "white", padding: "10px", margin: "0" },
                        pageSizeOptions: [10, 25, 50],
                        showSizeChanger: true,
                        total: digitalformType?.length,
                        showTotal: (total) => `Total ${total} items`,
                    }}
                    scroll={{ y: 400, x: "100vw" }}
                    title={() => (
                        <Space
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <span style={{ fontSize: "22px", fontWeight: "700" }}>
                                {`Ready Form Types (${digitalformType?.length})`}
                            </span>
                            <Space>
                                <IncredableButton
                                    onClick={() =>
                                        navigate(`add`)
                                    }
                                    type="primary"
                                    icon={<PlusOutlined />}
                                    size="middle"
                                    style={{
                                        backgroundColor: "#598BEB",
                                        border: "2px solid #598BEB",
                                        boxShadow: "none",
                                    }}
                                >
                                    Add Ready Form Type
                                </IncredableButton>
                                <TableTitle
                                    filter={[]}
                                    actionDropDownProps={{
                                        disabled: !selectedRow,
                                        menu: {
                                            items: [
                                                {
                                                    label: "Edit",
                                                    key: "edit",
                                                    icon: <EditOutlined />,
                                                },
                                                {
                                                    label: "Delete",
                                                    key: "delete",
                                                    icon: <DeleteOutlined />,
                                                    danger: true,
                                                },
                                            ],
                                            onClick: async (e) => {
                                                if (e?.key === "edit") {
                                                    navigate(`${selectedRow?.id}/edit`);
                                                } else if (e?.key === "delete") {
                                                    handleOpenDeleteModal();
                                                }
                                            },
                                        },
                                    }}
                                    onSettingsClick={handleOpenModal}
                                    onReloadClick={refetch}
                                />
                            </Space>
                            <TablePreferenceModal
                                visible={isModalVisible}
                                onCancel={handleCloseModal}
                                onSave={handleSavePreferences}
                                availableColumns={digitalformtypeColumns.map((col) => col.key as string)}
                                selectedColumns={selectedColumns}
                                columnOrderState={visibleColumnOrder}
                            />
                            <DeleteAlertModal
                                visible={isDeleteModalOpen}
                                action={async () => {
                                    if (selectedRow?.id) {
                                        setDeleteModalOpen(false);
                                        spinnerContext?.showSpinner();
                                        await deleteDigitalFormType({
                                            id: selectedRow?.id,
                                        });
                                        spinnerContext?.hidespinner();
                                        toastContext?.openSuccessNotification({
                                            message: `Ready Form Type deleted successfully`,
                                            placement: "topRight",
                                        });
                                    }
                                }}
                                closeModal={() => setDeleteModalOpen(false)}
                                header="Delete Ready Form Type"
                                content={
                                    <Typography.Text>
                                        Ready Form Type:{" "}
                                        <Typography.Text>{selectedRow?.type}</Typography.Text>
                                    </Typography.Text>
                                }
                                description="Are you sure you want to delete this ready form type?"
                            />
                        </Space>
                    )}
                />
            </IncredableContent>
        </>
    );
}

import { useContext, useState } from "react";
import { Flex, Layout, Space, Table, theme, Typography } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Payers } from "../../redux/api/payers/types";
import { useDeletePayerMutation } from "../../redux/api/payers/payers";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import IncredableButton from "../../components/button";
import TableTitle from "../../components/incredable-table/table-title";
import { TablePreferenceModal } from "../../common/TablePreferenceModal";
import DeleteAlertModal from "../../common/Modal/delete-alert-modal";
import usePayerFilter from "../../table-filters/payer-filters";
const { useToken } = theme;

export const payerColumns = [
  {
    title: () => <Typography.Text>Name</Typography.Text>,
    key: "name",
    width: "120px",
    ellipsis: true,
    render: (payer: Payers) => (
      <Typography.Link href={`/payers/${payer?.id}/edit`}>
        {payer?.name}
      </Typography.Link>
    ),
    sorter: (a: Payers, b: Payers) =>
      a?.name?.toLowerCase()?.localeCompare(b?.name?.toLowerCase()),
  },
  {
    title: () => <Typography.Text>Payer ID</Typography.Text>,
    dataIndex: "payerId",
    key: "payerId",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Website</Typography.Text>,
    dataIndex: "website",
    key: "website",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Address Lane 1</Typography.Text>,
    dataIndex: ["payerAddress", "addressLane1"],
    key: "addressLane1",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Address Lane 2</Typography.Text>,
    dataIndex: ["payerAddress", "addressLane2"],
    key: "addressLane2",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>City</Typography.Text>,
    dataIndex: ["payerAddress", "city"],
    key: "city",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>State</Typography.Text>,
    dataIndex: ["payerAddress", "state"],
    key: "state",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Country</Typography.Text>,
    dataIndex: ["payerAddress", "country"],
    key: "country",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },

  {
    title: () => <Typography.Text>Zip</Typography.Text>,
    dataIndex: ["payerAddress", "zipcode"],
    key: "zip",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Contact Email</Typography.Text>,
    dataIndex: ["payerAddress", "contactEmail"],
    key: "contactEmail",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Contact Name</Typography.Text>,
    dataIndex: ["payerAddress", "contactName"],
    key: "contactName",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Contact Phone</Typography.Text>,
    dataIndex: ["payerAddress", "contactPhone"],
    key: "contactPhone",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text> Contact Fax</Typography.Text>,
    dataIndex: ["payerAddress", "contactFax"],
    key: "contactFax",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
];

export default function PayersList() {
  const [selectedRow, setSelectedRow] = useState<Payers>();
  const { TableFilters, filteredPayers, UseQueryHookResult } = usePayerFilter();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const filteredColumns = ["name", "payerId", "website", "state"];
  const [selectedColumns, setSelectedColumns] =
    useState<string[]>(filteredColumns);
  const [visibleColumnOrder, setVisibleColumnOrder] = useState<string[]>(
    payerColumns.map((col) => col.key as string)
  );

  const navigate = useNavigate();
  const { token } = useToken();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  const selectRow = (record: Payers) => {
    if (selectedRow?.id + "" === record?.id + "") {
      setSelectedRow(undefined);
    } else {
      setSelectedRow(record);
    }
  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const handleSavePreferences = (
    selectedColumns: string[],
    columnOrder: string[]
  ) => {
    setSelectedColumns(selectedColumns);
    setVisibleColumnOrder(columnOrder);
    handleCloseModal();
  };
  const visibleColumns = visibleColumnOrder
    .filter((colKey) => selectedColumns.includes(colKey))
    .map((colKey) => payerColumns.find((col) => col.key === colKey)!);

  const [deletePayer] = useDeletePayerMutation();

  return (
    <Layout style={{ margin: "20px 30px 0 30px" }}>
      <Space size="large" direction="vertical">
        <Table
          style={{
            boxShadow: "0px 10px 25px 0px #5911920D",
            borderRadius: "16px",
            border: "1px solid #E5E7EB",
            overflow: "hidden",
          }}
          size="small"
          columns={visibleColumns}
          loading={
            UseQueryHookResult.isLoading || UseQueryHookResult.isFetching
          }
          rowKey="id"
          dataSource={!!filteredPayers ? [...filteredPayers] : []}
          onRow={(record) => ({
            onClick: () => {
              selectRow(record);
            },
          })}
          rowSelection={{
            type: "radio",
            selectedRowKeys: !!selectedRow?.id ? [selectedRow?.id] : [],
            onChange: (selectedRowKeys, selectedRows, info) => {
              if (!!selectedRows && !!selectedRows?.at(0))
                setSelectedRow(selectedRows?.at(0));
            },
            columnWidth: 60,
          }}
          pagination={{
            position: ["bottomRight"],
            style: { backgroundColor: "white", padding: "10px", margin: "0" },
            pageSizeOptions: [10, 25, 50],
            showSizeChanger: true,
            total: filteredPayers?.length,
            showTotal: (total) => `Total ${total} items`,
          }}
          title={() => (
            <Space direction="vertical" style={{ display: "flex" }}>
              <Flex
                align="start"
                justify={"space-between"}
                style={{ margin: "16px 10px 0 10px" }}
              >
                <Space size="middle">
                  <Typography.Title
                    style={{ paddingTop: "0", marginTop: "0" }}
                    level={4}
                  >
                    {`Payers (${filteredPayers?.length})`}
                  </Typography.Title>
                </Space>
                <Space size="middle">
                  <IncredableButton
                    onClick={() => navigate("add")}
                    type="primary"
                    icon={<PlusOutlined />}
                    size="large"
                  >
                    Add Payer
                  </IncredableButton>
                </Space>
              </Flex>
              <TableTitle
                filter={TableFilters}
                onReloadClick={UseQueryHookResult.refetch}
                actionDropDownProps={{
                  disabled: !selectedRow,
                  menu: {
                    items: [
                      {
                        label: "Edit",
                        key: "edit",
                        icon: <EditOutlined />,
                      },
                      {
                        label: "Delete",
                        key: "delete",
                        icon: <DeleteOutlined />,
                        danger: true,
                      },
                    ],
                    onClick: async (e) => {
                      if (e?.key === "edit") {
                        navigate(`${selectedRow?.id}/edit`);
                      }
                      if (e?.key === "delete") {
                        handleOpenDeleteModal();
                      }
                    },
                  },
                }}
                onSettingsClick={handleOpenModal}
              />
            </Space>
          )}
        />
        <TablePreferenceModal
          visible={isModalVisible}
          onCancel={handleCloseModal}
          onSave={handleSavePreferences}
          availableColumns={payerColumns.map((col) => col.key as string)}
          selectedColumns={selectedColumns}
          columnOrderState={visibleColumnOrder}
        />
        <DeleteAlertModal
          visible={isDeleteModalOpen}
          action={async () => {
            if (selectedRow?.id) {
              setDeleteModalOpen(false);
              spinnerContext?.showSpinner();
              await deletePayer({
                payerId: selectedRow?.id,
              });
              spinnerContext?.hidespinner();
              toastContext?.openSuccessNotification({
                message: `Payer deleted successfully`,
                placement: "topRight",
              });
            }
          }}
          closeModal={() => setDeleteModalOpen(false)}
          header="Delete Payer"
          content={
            <Typography.Text>
              Payer Name:{" "}
              <Typography.Text strong>{selectedRow?.name}</Typography.Text>
            </Typography.Text>
          }
          description="Are you sure you want to delete this payer?"
        />
      </Space>
    </Layout>
  );
}

import {
    ColumnLayout, Link
} from "@cloudscape-design/components";
import { useGetExclusionsQuery } from "../../redux/api/provider/provider";
import { Button as AntButton, Spin } from "antd";

interface ExclusionProps {
  npi_number: string;
}
export const NoExlusions = () => {
  return (
    <>
        <div>No Exclusions Found.</div>
    </>
  );
};

function Exclusions({ npi_number }: ExclusionProps) {
    const { data, isLoading } = useGetExclusionsQuery({ npi_number: npi_number });     
    if (isLoading) {
      return <Spin/>;
    }
  
    if (!data || data.length === 0) {
      return <div>No exclusions found.</div>;
    }
  
    return (
      <ColumnLayout columns={2} variant="text-grid">
        {data.map((exclusion) => (
          <div className="awsui-child-vertical" key={exclusion.name}>
            <div className="awsui-key-label">
              {exclusion.name}
              <Link
                external
                externalIconAriaLabel="Opens in a new tab"
                href={exclusion.link}
              />
            </div>
            <div>Exclusion Date: {exclusion.exclusionDate}</div>
          </div>
        ))}
      </ColumnLayout>
    );
  }

export default Exclusions;

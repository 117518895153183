import { Box, Header, PieChart } from "@cloudscape-design/components";
import { WidgetConfig } from "../types";
import { useGetAllTasksQuery } from "../../../redux/api/task";

export const taskStatus: WidgetConfig = {
  title: "Task Status",
  description: "Overview of all your task",
  header: TaskStatusHeader,
  content: TaskStatusContent,
};

function TaskStatusHeader() {
  return (
    <Header variant="h2" description={<i>{taskStatus.description}</i>}>
      {taskStatus.title}
    </Header>
  );
}

function TaskStatusContent() {
  const { data = [] } = useGetAllTasksQuery();
  return (
    <div style={{ height: "400px" }}>
      <PieChart
        data={[
          {
            title: "Completed",
            percentage:
              data?.filter((task) => !!task.completedDate).length ?? 0,
            value: data?.filter((task) => !!task.completedDate).length ?? 0,
            color: "#521262",
          },
          {
            title: "In Progress",
            percentage: data?.filter((task) => !task.completedDate).length ?? 0,
            value: data?.filter((task) => !task.completedDate).length ?? 0,
            color: "#2B6BE6",
          },
        ]}
        segmentDescription={(datum, sum) =>
          `${datum.value} tasks, ${((datum.value / sum) * 100).toFixed(0)}%`
        }
        detailPopoverContent={(datum, sum) => [
          {
            key: "Tasks count",
            value: datum.value,
          },
          {
            key: "Percentage",
            value: `${((datum.value / sum) * 100).toFixed(0)}%`,
          },
        ]}
        ariaDescription="Donut chart showing generic example data."
        ariaLabel="Donut chart"
        innerMetricDescription="Total Tasks"
        innerMetricValue={`${data?.length}` ?? "0"}
        size="medium"
        fitHeight
        variant="donut"
        hideFilter
        empty={
          <Box textAlign="center" color="inherit">
            <b>No data available</b>
            <Box variant="p" color="inherit">
              There is no data available
            </Box>
          </Box>
        }
      />
    </div>
  );
}

import { useState } from "react";
import {
  RecipientType,
  SignatureRequestType,
} from "../../../redux/api/signaturerequest/types";
import { v4 as uuidv4 } from "uuid";
import useIsLoading from "../../../hooks/useIsLoading";
import { Core } from "@pdftron/webviewer";
import { get } from "lodash";
import { captureException } from "@sentry/react";
import { Button, Container, SelectProps } from "@cloudscape-design/components";
import { SignatureRequestStepTwo } from "./signature-request-step-two";
import { useNavigate } from "react-router-dom";
import { Provider } from "../../../redux/api/provider/types";
import { AddlRecipientColors } from "./recipient";
import { SignatureRequestStepOne } from "./signature-request-step-one";
import { Space } from "antd";

export type RequestSignatureProps = {
  name: string;
  fileKey: string;
  isLoading: boolean;
  savetosafe: (xfdfString: string, providerId: string) => Promise<void>;
  addsignaturerequest: (
    primaryRecipient: RecipientType,
    addlRecipients: RecipientType[],
    signatureRequest: Pick<
      SignatureRequestType,
      "ccRecipientEmailList" | "subject" | "message" | "categoryId"
    >
  ) => Promise<void>;
  loadingSubmitSignatureRequest: boolean;
};

export default function RequestSignature({
  fileKey,
  name,
  isLoading,
  savetosafe,
  addsignaturerequest,
  loadingSubmitSignatureRequest,
}: RequestSignatureProps) {
  useIsLoading(isLoading || loadingSubmitSignatureRequest);
  const [primaryRecipient, setPrimaryRecipient] = useState<RecipientType>();
  const [activeStep, setActiveStep] = useState(1);
  const [addlRecipients, setAddlRecipients] = useState<RecipientType[]>([
    {
      id: uuidv4(),
      action: "sign",
      backgroundColor: AddlRecipientColors[0],
    },
  ]);
  const [alertDays, setAlertDays] = useState<SelectProps.Option | null>(null);
  const [categoryOption, setCategoryOption] =
    useState<SelectProps.Option | null>(null);
  const [expirationDate, setExpirationDate] = useState<Date | string>();
  const [subject, setSubject] = useState<string>(`Signature Request: ${name}`);
  const [messageBody, setMessageBody] = useState<string>("");
  const [errorText, setErrorText] = useState<string>();
  const navigate = useNavigate();

  return (
    <div key="main" style={{ margin: "24px 32px 24px 32px" }}>
      {activeStep === 1 && (
        <Container
          key="one"
          footer={
            <Space direction="horizontal" size="middle">
              <Button
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  if (!primaryRecipient) {
                    setErrorText("Primary Recipient is mandatory");
                    return;
                  }
                  if (!categoryOption) {
                    setErrorText("Category is mandatory");
                    return;
                  }
                  setActiveStep(2);
                }}
              >
                Next
              </Button>
            </Space>
          }
        >
          <SignatureRequestStepOne
            primaryRecipient={primaryRecipient}
            setPrimaryRecipient={setPrimaryRecipient}
            addlRecipients={addlRecipients}
            setAddlRecipients={setAddlRecipients}
            subject={subject}
            alertDaysOption={alertDays}
            setAlertDaysOption={setAlertDays}
            expirationDate={expirationDate}
            setExpirationDate={setExpirationDate}
            setSubject={setSubject}
            messageBody={messageBody}
            setMessageBody={setMessageBody}
            errorText={errorText}
            setErrorText={setErrorText}
            categoryOption={categoryOption}
            setCategoryOption={setCategoryOption}
          />
        </Container>
      )}
      {activeStep === 2 && !!primaryRecipient && (
        <SignatureRequestStepTwo
          key="two"
          headerText={name}
          fileKey={fileKey}
          isLoading={false}
          addsignaturerequest={addsignaturerequest}
          savetosafe={savetosafe}
          addlRecipients={addlRecipients}
          primaryRecipient={primaryRecipient}
          subject={subject}
          messageBody={messageBody}
          categoryId={categoryOption?.value}
          expirationDate={expirationDate}
          alertDays={alertDays?.value ?? "-1"}
        />
      )}
    </div>
  );
}

export const selectValueFromData = (
  data: Provider | undefined,
  annot: Core.Annotations.Annotation
): string | undefined => {
  let defaultValue = undefined; //annot.getCustomData("name");

  if (!data || !annot.getCustomData("key")) return defaultValue;
  else {
    try {
      let property_path = annot.getCustomData("key")?.split(".");

      let index = annot.getCustomData("index");
      if (!!index) {
        property_path.splice(1, 0, index);
      }

      const value = get(data, property_path, defaultValue);

      if (annot.getCustomData("type") === "bool")
        return value === "true" || value === true ? "On" : "Off";
      else return value;
    } catch (error) {
      captureException(error);
      return defaultValue;
    }
  }
};

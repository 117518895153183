import { Col, Divider, Flex, Form, Row, theme, Typography, Space } from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import { ProviderDeaTypeRequest } from "../../redux/api/provider/types";
import {
  useAddProviderDeaMutation,
  useGetProviderQuery,
} from "../../redux/api/provider/provider";
import IncredableButton from "../../components/button";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { useContext, useEffect } from "react";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import IncredableContent from "../../components/incredable-content";
import { USStatesList } from "../../common/constants";
import RHFSelect from "../../components/RHF/RHFSelect";
import RHFTextArea from "../../components/RHF/RHFTextArea";

const { useToken } = theme;

export default function EditProviderDEA() {
  const { providerId, deaId } = useParams();
  const methods = useForm<ProviderDeaTypeRequest>();

  const {
    data: provider,
    isLoading,
    isFetching,
  } = useGetProviderQuery(!!providerId ? { providerId } : skipToken);
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();
  const { token } = useToken();

  useEffect(() => {
    if (provider && provider.providerDeas) {
      const dea = provider.providerDeas.find(
        (dea) => dea?.id + "" === deaId + ""
      );
      if (dea) {
        methods.setValue("id", dea.id);
        methods.setValue("type", dea?.type);
        methods.setValue("deaNumber", dea?.deaNumber);
        methods.setValue("startDate", dea?.startDate);
        methods.setValue("endDate", dea?.endDate);
        methods.setValue("status", dea?.status);
        methods.setValue("state", dea?.state);
      }
    }
  }, [provider, deaId]);

  const [updateProviderDea] = useAddProviderDeaMutation();

  return (
    <IncredableContent spinning={isLoading || isFetching}>
      <FormProvider {...methods}>
        <Form
          name="editproviderdea"
          layout="vertical"
          onFinish={methods.handleSubmit(
            async (data: ProviderDeaTypeRequest) => {
              if (!!providerId) {
                spinnerContext?.showSpinner();
                await updateProviderDea({ ...data, providerId: providerId });
                spinnerContext?.hidespinner();
                toastContext?.openSuccessNotification({
                  message: `DEA saved successfully`,
                  placement: "topRight",
                });
                navigate(-1);
              }
            }
          )}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                color: "#1D1A1F",
                lineHeight: "25px",
                fontWeight: "700",
              }}
            >
              DEA
            </Typography.Text>
            <Space>
              <IncredableButton
                secondary
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" htmlType="submit">
                Save
              </IncredableButton>
            </Space>
          </Flex>
          <Divider />
          <Form.List name="dea" initialValue={[{}]}>
            {(fields, { add }) => {
              return (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div key={key}>
                      {index > 0 && <Divider />}
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Type" }}
                            controllerProps={{
                              name: `type`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select Type",
                              options: ["Federal", "State"].map((type) => ({
                                value: type,
                              })),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "DEA Number",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "deaNumber",
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Status",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "status",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{
                              label: "Start Date",
                            }}
                            controllerProps={{
                              name: `startDate`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{
                              label: "End Date",
                            }}
                            controllerProps={{
                              name: `endDate`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "State" }}
                            controllerProps={{
                              name: `state`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select state",
                              options: USStatesList?.map((state) => {
                                return {
                                  value: state?.value,
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={24}>
                          <RHFTextArea
                            formItemProps={{ label: "Notes" }}
                            controllerProps={{ name: "notes" }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}
                </>
              );
            }}
          </Form.List>
        </Form>
      </FormProvider>
    </IncredableContent>
  );
}

import { Col, Divider, Flex, Form, Row, theme, Typography, Space } from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import {
  useAddProviderLiabilityInsuranceMutation,
  useGetProviderQuery,
} from "../../redux/api/provider/provider";
import IncredableButton from "../../components/button";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { useContext, useEffect } from "react";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import IncredableContent from "../../components/incredable-content";
import { ProviderLiabilityInsuranceRequestType } from "../../redux/api/provider/types";
import RHFCheckBox from "../../components/RHF/RHFCheckbox";
import RHFPhoneNumber from "../../components/RHF/RHFPhonenumber";
import { Countries, USStatesList } from "../../common/constants";
import RHFSelect from "../../components/RHF/RHFSelect";

const { useToken } = theme;

export default function EditProviderLiabilityInsurance() {
  const { providerId, liabilityinsuranceId } = useParams();
  const methods = useForm<ProviderLiabilityInsuranceRequestType>();

  const {
    data: provider,
    isLoading,
    isFetching,
  } = useGetProviderQuery(!!providerId ? { providerId } : skipToken);
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (provider && provider.providerLiabilityInsurances) {
      const liabilityinsurance = provider?.providerLiabilityInsurances?.find(
        (liability) => liability?.id + "" === liabilityinsuranceId + ""
      );
      if (liabilityinsurance) {
        methods.setValue("id", liabilityinsurance.id);
        methods.setValue("isCurrent", liabilityinsurance?.isCurrent);
        methods.setValue("name", liabilityinsurance?.name);
        methods.setValue("policyNumber", liabilityinsurance?.policyNumber);
        methods.setValue("selfInsured", liabilityinsurance?.selfInsured);
        methods.setValue("effectiveDate", liabilityinsurance?.effectiveDate);
        methods.setValue("expirationDate", liabilityinsurance?.expirationDate);
        methods.setValue("email", liabilityinsurance?.email);
        methods.setValue("phone", liabilityinsurance?.phone);
        methods.setValue("fax", liabilityinsurance?.fax);
        methods.setValue("typeOfCoverage", liabilityinsurance?.typeOfCoverage);
        methods.setValue(
          "timeWithCurrentCarrierValue",
          liabilityinsurance?.timeWithCurrentCarrierValue
        );
        methods.setValue(
          "timeWithCurrentCarrierUnit",
          liabilityinsurance?.timeWithCurrentCarrierUnit
        );
        methods.setValue(
          "coveragePerOccurrence",
          liabilityinsurance?.coveragePerOccurrence
        );
        methods.setValue(
          "aggregateCoverage",
          liabilityinsurance?.aggregateCoverage
        );
        methods.setValue("addressLane1", liabilityinsurance?.addressLane1);
        methods.setValue("addressLane2", liabilityinsurance?.addressLane2);
        methods.setValue("city", liabilityinsurance?.city);
        methods.setValue("state", liabilityinsurance?.state);
        methods.setValue("zip", liabilityinsurance?.zip);
        methods.setValue("country", liabilityinsurance?.country);
      }
    }
  }, [provider, liabilityinsuranceId]);

  const [updateProviderLiabilityInsurance] =
    useAddProviderLiabilityInsuranceMutation();

  return (
    <IncredableContent spinning={isLoading || isFetching}>
      <FormProvider {...methods}>
        <Form
          name="editproviderliabilityinsurance"
          layout="vertical"
          onFinish={methods.handleSubmit(
            async (data: ProviderLiabilityInsuranceRequestType) => {
              if (!!providerId) {
                spinnerContext?.showSpinner();
                await updateProviderLiabilityInsurance({
                  ...data,
                  providerId: providerId,
                });
                spinnerContext?.hidespinner();
                toastContext?.openSuccessNotification({
                  message: `Liability Insurance saved successfully`,
                  placement: "topRight",
                });
              }
              navigate(-1);
            }
          )}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                color: "#1D1A1F",
                lineHeight: "25px",
                fontWeight: "700",
              }}
            >
              Liability Insurance
            </Typography.Text>
            <Space>
              <IncredableButton secondary onClick={() => navigate(-1)}>
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" htmlType="submit">
                Save
              </IncredableButton>
            </Space>
          </Flex>
          <Divider />
          <Form.List name="liabilityinsurance" initialValue={[{}]}>
            {(fields, { add }) => {
              return (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div key={key}>
                      {index > 0 && <Divider />}
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Name",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "name",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Policy Number",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "policyNumber",
                            }}
                          />
                        </Col>

                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{
                              label: "Effective Date",
                            }}
                            controllerProps={{
                              name: `effectiveDate`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{
                              label: "Expiration Date",
                            }}
                            controllerProps={{
                              name: `expirationDate`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Type Of Coverage",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "typeOfCoverage",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Time With Current Carrier Value",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "timeWithCurrentCarrierValue",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Time With Current Carrier Unit",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "timeWithCurrentCarrierUnit",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Coverage Per Occurrence",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "coveragePerOccurrence",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Aggregate Coverage",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "aggregateCoverage",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFCheckBox
                            formItemProps={{ label: "" }}
                            controllerProps={{ name: "selfInsured" }}
                            checkboxProps={{ label: "Self Insured" }}
                          />
                        </Col>
                      </Row>
                      <Divider />
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Email",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "email",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFPhoneNumber
                            formItemProps={{
                              label: "Phone",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "phone",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Fax",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "fax",
                            }}
                          />
                        </Col>
                      </Row>
                      <Divider />
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Address Lane 1",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "addressLane1",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Address Lane 2",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "addressLane2",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "City",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "city",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "State" }}
                            controllerProps={{
                              name: `state`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select state",
                              options: USStatesList?.map((state) => {
                                return {
                                  value: state?.value,
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Country" }}
                            controllerProps={{
                              name: `country`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select country",
                              options: Countries?.map((country) => {
                                return {
                                  value: country?.value,
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Zip",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "zip",
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}
                </>
              );
            }}
          </Form.List>
        </Form>
      </FormProvider>
    </IncredableContent>
  );
}

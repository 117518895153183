import { Space, Table, Typography } from "antd";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TableTitle from "../../components/incredable-table/table-title";
import {
  CalendarOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { TablePreferenceModal } from "../../common/TablePreferenceModal";
import { ProviderLicenseType } from "../../redux/api/provider/types";
import {
  useDeleteProviderLicenseMutation,
  useGetAllLicenseTypesQuery,
  useGetProviderQuery,
} from "../../redux/api/provider/provider";
import { skipToken } from "@reduxjs/toolkit/query";
import IncredableButton from "../../components/button";
import IncredableDateFormat from "../../common/incredable-date-format";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import DeleteAlertModal from "../../common/Modal/delete-alert-modal";

export default function License() {
  const { providerId } = useParams();
  const [selectedRow, setSelectedRow] = useState<ProviderLicenseType>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const { data: licenseType } = useGetAllLicenseTypesQuery();

  const {
    data: provider,
    isLoading,
    isFetching,
  } = useGetProviderQuery(
    !!providerId ? { providerId: providerId } : skipToken
  );
  const navigate = useNavigate();
  const selectRow = (record: ProviderLicenseType) => {
    if (selectedRow?.id + "" === record?.id + "") {
      setSelectedRow(undefined);
    } else {
      setSelectedRow(record);
    }
  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const handleSavePreferences = (
    selectedColumns: string[],
    columnOrder: string[]
  ) => {
    setSelectedColumns(selectedColumns);
    setVisibleColumnOrder(columnOrder);
    handleCloseModal();
  };

  const [deleteProviderLicense] = useDeleteProviderLicenseMutation();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  const licenseColumns = [
    {
      title: () => "License Number",
      dataIndex: "licenseNumber",
      key: "licenseNumber",
      ellipsis: true,
      render: (text: string) => (
        <Typography.Text>{text || "-"}</Typography.Text>
      ),
    },
    {
      title: () => "License Type",
      key: "licenseType",
      ellipsis: true,
      render: (license: ProviderLicenseType) =>
        licenseType?.find((item) => item?.id + "" === license.licenseType + "")
          ?.name || "-",
      sorter: (a: ProviderLicenseType, b: ProviderLicenseType) => {
        const nameA = licenseType?.find((item) => item?.id + "" === a.licenseType + "")?.name || "";
        const nameB = licenseType?.find((item) => item?.id + "" === b.licenseType + "")?.name || "";
        return nameA.localeCompare(nameB);
      },
    },
    {
      title: () => "State",
      dataIndex: "state",
      key: "state",
      ellipsis: true,
      render: (text: string) => (
        <Typography.Text>{text || "-"}</Typography.Text>
      ),
      sorter: (a: ProviderLicenseType, b: ProviderLicenseType) =>
        a?.state?.toLowerCase()?.localeCompare(b?.state?.toLowerCase()),
    },
    {
      title: () => "Notes",
      dataIndex: "notes",
      key: "notes",
      ellipsis: true,
      render: (text: string) => (
        <Typography.Text>{text || "-"}</Typography.Text>
      ),
    },
    {
      title: () => "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      ellipsis: true,
      render: (startDate: string | null) => (
        <Typography.Text>
          {startDate ? (
            <Space>
              {" "}
              <CalendarOutlined /> {IncredableDateFormat(startDate)}
            </Space>
          ) : (
            "-"
          )}
        </Typography.Text>
      ),
      sorter: (a: ProviderLicenseType, b: ProviderLicenseType) =>
        new Date(a?.startDate).getTime() - new Date(b?.startDate).getTime(),
    },
    {
      title: () => "End Date",
      dataIndex: "endDate",
      key: "endDate",
      ellipsis: true,
      render: (endDate: string | null) => (
        <Typography.Text>
          {endDate ? (
            <Space>
              <CalendarOutlined /> {IncredableDateFormat(endDate)}
            </Space>
          ) : (
            "-"
          )}
        </Typography.Text>
      ),
      sorter: (a: ProviderLicenseType, b: ProviderLicenseType) =>
        new Date(a?.endDate).getTime() - new Date(b?.endDate).getTime(),
    },
  ];

  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    licenseColumns.map((col) => col.key as string)
  );
  const [visibleColumnOrder, setVisibleColumnOrder] = useState<string[]>(
    licenseColumns.map((col) => col.key as string)
  );

  const visibleColumns = visibleColumnOrder
    .filter((colKey) => selectedColumns.includes(colKey))
    .map((colKey) => licenseColumns.find((col) => col.key === colKey)!);

  return (
    <>
      <Table
        style={{
          boxShadow: "0px 10px 25px 0px #5911920D",
          borderRadius: "16px",
          border: "1px solid #E5E7EB",
          overflow: "hidden",
        }}
        size="small"
        columns={visibleColumns}
        loading={isLoading || isFetching}
        rowKey="id"
        dataSource={
          !!provider?.providerLicenses ? [...provider?.providerLicenses] : []
        }
        onRow={(record) => ({
          onClick: () => {
            selectRow(record);
          },
        })}
        rowSelection={{
          type: "radio",
          selectedRowKeys: !!selectedRow?.id ? [selectedRow?.id] : [],
          onChange: (selectedRowKeys, selectedRows, info) => {
            if (!!selectedRows && !!selectedRows?.at(0))
              setSelectedRow(selectedRows?.at(0));
            navigate("");
          },
          columnWidth: 60,
        }}
        pagination={{
          position: ["bottomRight"],
          style: { backgroundColor: "white", padding: "10px", margin: "0" },
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          total: provider?.providerLicenses?.length,
          showTotal: (total) => `Total ${total} items`,
        }}
        title={() => (
          <Space
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span style={{ fontSize: "22px", fontWeight: "700" }}>
              {`License (${provider?.providerLicenses?.length})`}
            </span>
            <Space>
              <IncredableButton
                onClick={() =>
                  navigate(`/manageprovider/${providerId}/license/add`)
                }
                type="primary"
                icon={<PlusOutlined />}
                size="middle"
                style={{
                  backgroundColor: "#598BEB",
                  border: "2px solid #598BEB",
                  boxShadow: "none",
                }}
              >
                Add License
              </IncredableButton>
              <TableTitle
                filter={[]}
                actionDropDownProps={{
                  disabled: !selectedRow,
                  menu: {
                    items: [
                      {
                        label: "Edit",
                        key: "edit",
                        icon: <EditOutlined />,
                      },
                      {
                        label: "Delete",
                        key: "delete",
                        icon: <DeleteOutlined />,
                        danger: true,
                      },
                    ],
                    onClick: async (e) => {
                      if (e?.key === "edit") {
                        navigate(`${selectedRow?.id}/edit`);
                      }
                      if (e?.key === "delete") {
                        handleOpenDeleteModal();
                      }
                    },
                  },
                }}
                onSettingsClick={handleOpenModal}
              />
            </Space>
          </Space>
        )}
        scroll={{ y: 400, x: "100vw" }}
      />
      <TablePreferenceModal
        visible={isModalVisible}
        onCancel={handleCloseModal}
        onSave={handleSavePreferences}
        availableColumns={licenseColumns.map((col) => col.key as string)}
        selectedColumns={selectedColumns}
        columnOrderState={visibleColumnOrder}
      />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (!!providerId && !!selectedRow?.id) {
            setDeleteModalOpen(false);
            spinnerContext?.showSpinner();
            await deleteProviderLicense({
              providerId: providerId,
              licenseId: selectedRow?.id + "",
            });
            spinnerContext?.hidespinner();
            toastContext?.openSuccessNotification({
              message: `License deleted successfully`,
              placement: "topRight",
            });
          }
        }}
        closeModal={() => setDeleteModalOpen(false)}
        header="Delete License"
        content={
          <Typography.Text>
            License Number :{" "}
            <Typography.Text strong>
              {selectedRow?.licenseNumber}
            </Typography.Text>
          </Typography.Text>
        }
        description="Are you sure you want to delete this license?"
      />
    </>
  );
}

import { api } from "..";
import Comment from "./types";

const CommentRequestApi = api.injectEndpoints({
  endpoints: (build) => ({
    addCommentToDocument: build.mutation<
      void,
      { providerId: string; documentId: string; message: string }
    >({
      query: ({ providerId, documentId, message }) => ({
        url: `/provider/${providerId}/document/${documentId}/comment`,
        method: "POST",
        body: message,
      }),
      invalidatesTags: (result, error, { documentId }) => [
        { type: "Comments", id: documentId },
      ],
    }),
    deleteCommentFromDocument: build.mutation<
      Comment,
      { providerId: string; documentId: string; commentId: string }
    >({
      query: ({ providerId, documentId, commentId }) => ({
        url: `/provider/${providerId}/document/${documentId}/comment/${commentId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, { documentId }) => [
        { type: "Comments", id: documentId },
      ],
    }),
    updateCommentInDocument: build.mutation<
      Comment,
      {
        providerId: string;
        documentId: string;
        commentId: string;
        message: string;
      }
    >({
      query: ({ providerId, documentId, commentId, message }) => ({
        url: `/provider/${providerId}/document/${documentId}/comment/${commentId}`,
        method: "PUT",
        body: message,
      }),
      invalidatesTags: (result, error, { documentId }) => [
        { type: "Comments", id: documentId },
      ],
    }),
    getDocumentComments: build.query<
      Comment[],
      { providerId: string; documentId: string }
    >({
      query: ({ providerId, documentId }) =>
        `/provider/${providerId}/document/${documentId}/comments`,
      providesTags: (result, error, { documentId }) => [
        { type: "Comments", id: documentId },
      ],
    }),
    addPayerEnrollmentComment: build.mutation<
      void,
      { enrollmentId: string; message: string }
    >({
      query: ({ enrollmentId, message }) => ({
        url: `/payer/enrollments/${enrollmentId}/comment`,
        method: "POST",
        body: message,
      }),
      invalidatesTags: (result, error, { enrollmentId }) => [
        { type: "Comments", id: enrollmentId },
      ],
    }),
    deletePayerEnrollmentComment: build.mutation<
      Comment,
      { enrollmentId: string; commentId: string }
    >({
      query: ({ enrollmentId, commentId }) => ({
        url: `/payer/enrollments/${enrollmentId}/comment/${commentId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, { enrollmentId }) => [
        { type: "Comments", id: enrollmentId },
      ],
    }),
    updatePayerEnrollmentComment: build.mutation<
      Comment,
      { enrollmentId: string; commentId: string; message: string }
    >({
      query: ({ enrollmentId, commentId, message }) => ({
        url: `/payer/enrollments/${enrollmentId}/comment/${commentId}`,
        method: "PUT",
        body: message,
      }),
      invalidatesTags: (result, error, { enrollmentId, commentId }) => [
        { type: "Comments", id: enrollmentId },
        { type: "Comments", id: commentId },
      ],
    }),
    getPayerEnrolmentComments: build.query<Comment[], { enrollmentId: string }>(
      {
        query: ({ enrollmentId }) =>
          `/payer/enrollments/${enrollmentId}/comments`,
        providesTags: (result, error, { enrollmentId }) => [
          { type: "Comments", id: enrollmentId },
        ],
      }
    ),
  }),
});
export const {
  useAddCommentToDocumentMutation,
  useDeleteCommentFromDocumentMutation,
  useUpdateCommentInDocumentMutation,
  useGetDocumentCommentsQuery,
  useAddPayerEnrollmentCommentMutation,
  useDeletePayerEnrollmentCommentMutation,
  useUpdatePayerEnrollmentCommentMutation,
  useGetPayerEnrolmentCommentsQuery,
} = CommentRequestApi;

import { SearchOutlined } from "@ant-design/icons";
import { Input, Select, Space } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useGetAllWorkflowTemplateQuery } from "../redux/api/workflow/workflow";

export default function useWorkFlowTemplateFilter() {
    const [selectedFilterkey, setSelectedFilterKey] = useState<string>("name");
    const [selectedFiltervalue, setSelectedFiltervalue] = useState<
        string | undefined
    >(undefined);

    const {
        data: workflowTemplate = [],
        refetch,
        isLoading: workflowTemplateLoading,
        isFetching,
    } = useGetAllWorkflowTemplateQuery();

    const filteredWorkFlowTemplate = useMemo(() => {
        if (!!selectedFiltervalue) {
            if (selectedFilterkey === "name")
                return workflowTemplate?.filter(
                    (template) =>
                        template?.name
                            ?.toLowerCase()
                            .includes(selectedFiltervalue?.toLowerCase())
                );

        }
        return workflowTemplate;
    }, [selectedFiltervalue, workflowTemplate]);

    useEffect(() => {
        setSelectedFiltervalue(undefined);
    }, [selectedFilterkey]);

    const TableFilters = (
        <Space size="small">
            {selectedFilterkey === "name" && (
                <Input
                    onChange={(event) => {
                        setSelectedFiltervalue(event?.target?.value);
                    }}
                    value={selectedFiltervalue}
                    style={{
                        width: "320px",
                    }}
                    placeholder="Find Name"
                    prefix={<SearchOutlined />}
                />
            )}
            <Select
                value={selectedFilterkey}
                onChange={(value) => {
                    setSelectedFilterKey(value);
                }}
                style={{ width: "140px" }}
                options={[
                    { value: "name", label: "Name" },
                ]}
            />
        </Space>
    );

    return {
        TableFilters,
        filteredWorkFlowTemplate,
        UseQueryHookResult: {
            refetch,
            isLoading: workflowTemplateLoading,
            isFetching,
        },
    };
}

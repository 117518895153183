import {
  Space,
  Form,
  Spin,
  Menu,
  Divider,
  Dropdown,
  Card,
  ConfigProvider,
} from "antd";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import IncredableButton from "../../components/button";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import RHFSelect from "../../components/RHF/RHFSelect";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import RHFTextField from "../../components/RHF/RHFTextField";
import {
  useGetDocumentQuery,
  useUpdateDocumentMutation,
} from "../../redux/api/document/document";
import { skipToken } from "@reduxjs/toolkit/query";
import { useContext, useEffect } from "react";
import { useGetAllCategoryQuery } from "../../redux/api/category/category";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import { DownOutlined } from "@ant-design/icons";
import { ApplicationState } from "../../redux/store";
import { useSelector } from "react-redux";
import { fileAxios } from "../../context/axios";
import { getFileUrl } from "../../config";
import { alertReminderOptions } from "../../common/constants";

type FormInputType = {
  name: string;
  expirationDate?: string | Date;
  createdDate: string;
  alertDays: number;
  notes: string;
  categoryId: string;
  isVersioned: boolean;
};
export default function DocumentDetails({
  uploadedFile,
}: {
  uploadedFile: File | undefined;
}) {
  const methods = useForm<FormInputType>();
  const { providerId, documentId } = useParams();
  const { data: document, isLoading } = useGetDocumentQuery(
    documentId && providerId ? { documentId, providerId } : skipToken
  );
  const navigate = useNavigate();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const { data: categories } = useGetAllCategoryQuery();
  const [updateDocument] = useUpdateDocumentMutation();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false)
  );
  useEffect(() => {
    if (!document) return;
    methods.reset({
      name: document?.name,
      alertDays: document?.alertDays,
      expirationDate: document?.expirationDate,
      notes: document?.notes,
      categoryId: document?.category?.id,
    });
  }, [document, methods]);
  const handelOnclick = async () => {
    if (!hasPermission || !providerId || !document?.id) return;
    try {
      await updateDocument({
        providerId: providerId,
        documentId: document.id,
        isVersioned: true,
        document: {
          name: methods.getValues("name"),
          alertDays: methods.getValues("alertDays"),
          expirationDate: methods.getValues("expirationDate"),
          categoryId: methods.getValues("categoryId"),
          file: uploadedFile,
          notes: methods.getValues("notes"),
        },
      })
        .unwrap()
        .then(async (result) => {
          if (!!uploadedFile && result?.attachment?.key) {
            try {
              await fileAxios.put(
                getFileUrl(result?.attachment?.key),
                uploadedFile
              );
            } catch (error) {}
          }
          spinnerContext?.hidespinner();
          toastContext?.openSuccessNotification({
            message: `document updated successfully`,
            placement: "topRight",
          });
        });
    } catch (error) {
      spinnerContext?.hidespinner();
      toastContext?.openErrorNotification({
        message: `document updated faild successfully`,
        placement: "topRight",
      });
    }
  };
  const menu = (
    <Menu style={{ margin: "20px 0px 20px 0px" }}>
      <Menu.Item key="1" onClick={handelOnclick}>
        Add New Versions
      </Menu.Item>
    </Menu>
  );
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "20vh",
        }}
      >
        <Spin tip="Loading..." />
      </div>
    );
  }
  return (
    <Card title="Document details">
      <FormProvider {...methods}>
        <ConfigProvider
          theme={{
            components: {
              Form: {
                verticalLabelMargin: -4,
                itemMarginBottom: 16,
              },
            },
          }}
        >
          <Form
            layout="vertical"
            onFinish={methods.handleSubmit(async (data: FormInputType) => {
              spinnerContext?.showSpinner();
              await updateDocument({
                providerId: String(providerId),
                documentId: String(documentId),
                isVersioned: !!data.isVersioned,
                document: {
                  name: data?.name,
                  alertDays: data?.alertDays,
                  expirationDate: data?.expirationDate,
                  categoryId: data.categoryId,
                  // file: uploadFile,
                  notes: data.notes,
                },
              });
              spinnerContext?.hidespinner();
              toastContext?.openSuccessNotification({
                message: `document updated successfully`,
                placement: "topRight",
              });
            })}
            size="middle"
            style={{ maxWidth: "360px" }}
            autoComplete="off"
          >
            <RHFTextField
              formItemProps={{
                label: "Document Name",
              }}
              inputProps={{}}
              controllerProps={{ name: "name" }}
              rules={{ required: "This field is required" }}
            />
            <RHFSelect
              formItemProps={{
                label: "Category",
              }}
              controllerProps={{ name: "categoryId" }}
              selectProps={{
                allowClear: true,
                showSearch: true,
                style: { width: "100%" },
                placeholder: "Select Category",
                options: categories?.map((category) => {
                  return {
                    label: category?.name,
                    value: category?.id,
                  };
                }),
              }}
            />
            <RHFDatePicker
              formItemProps={{
                label: "Expiration Date",
              }}
              controllerProps={{
                name: "expirationDate",
              }}
            />
            <RHFSelect
              formItemProps={{
                label: "Expiration Alert Reminder",
              }}
              controllerProps={{ name: "alertDays" }}
              selectProps={{
                allowClear: true,
                showSearch: true,
                style: { width: "100%" },
                placeholder: "Select Expiration Reminder",
                options: alertReminderOptions?.map((alert) => {
                  return {
                    label: alert?.label,
                    value: alert?.value,
                  };
                }),
              }}
            />
            <RHFTextArea
              formItemProps={{
                label: "Notes",
              }}
              controllerProps={{ name: "notes" }}
              textAreaProps={{}}
            />
            <Space
              style={{ width: "100%", justifyContent: "end" }}
              size="large"
            >
              <IncredableButton
                secondary
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" htmlType="submit">
                Save
                <Divider
                  type="vertical"
                  style={{ backgroundColor: "#877070" }}
                />
                <Dropdown
                  overlay={menu}
                  trigger={["hover"]}
                  placement="bottomRight"
                >
                  <DownOutlined />
                </Dropdown>
              </IncredableButton>
            </Space>
          </Form>
        </ConfigProvider>
      </FormProvider>
    </Card>
  );
}

import { useContext, useState } from "react";
import { Flex, Space, Table, Typography } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { TablePreferenceModal } from "../../common/TablePreferenceModal";
import IncredableButton from "../../components/button";
import TableTitle from "../../components/incredable-table/table-title";
import { AllDocumentResponse } from "../../redux/api/document/types";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  useGetAllCredentialPackageDocumentsQuery,
  useDeleteCredentialPackageDocumentsMutation,
} from "../../redux/api/credentialpackages/credentialpackages";
import CredentialPackageAddDocumentsModal from "./add-credential-package-modal";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";

export const columns = [
  {
    title: () => <Typography.Text>Document Name</Typography.Text>,
    key: "name",
    width: "620px",
    ellipsis: true,
    render: (document: AllDocumentResponse) => (
      <Typography.Link>
        {document?.name ? `${document.name}` : "-"}
      </Typography.Link>
    ),
    sorter: (a: AllDocumentResponse, b: AllDocumentResponse) =>
      a?.name?.toLowerCase()?.localeCompare(b?.name?.toLowerCase()),
  },
  {
    title: () => <Typography.Text>Category</Typography.Text>,
    dataIndex: ["category", "name"],
    key: "category",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
];

export default function DocumentList({
  selectedDocuments,
  setSelectDocuments,
}: {
  documents: AllDocumentResponse[];
  selectedDocuments: AllDocumentResponse[];
  setSelectDocuments: (selectedDocuments: AllDocumentResponse[]) => void;
}) {
  const { id } = useParams();

  const [selectedRow, setSelectedRow] = useState<AllDocumentResponse>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    columns.map((col) => col.key as string)
  );
  const [visibleColumnOrder, setVisibleColumnOrder] = useState<string[]>(
    columns.map((col) => col.key as string)
  );

  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleSavePreferences = (
    selectedColumns: string[],
    columnOrder: string[]
  ) => {
    setSelectedColumns(selectedColumns);
    setVisibleColumnOrder(columnOrder);
    handleCloseModal();
  };

  const visibleColumns = visibleColumnOrder
    .filter((colKey) => selectedColumns.includes(colKey))
    .map((colKey) => columns.find((col) => col.key === colKey)!);

  const { data: documentsData = [], isLoading } =
    useGetAllCredentialPackageDocumentsQuery(
      id ? { credentialPackageId: id } : skipToken
    );

  const [isAddDocumentModalVisible, setIsAddDocumentModalVisible] =
    useState<boolean>(false);

  const [deleteCredentialPackageDocuments] =
    useDeleteCredentialPackageDocumentsMutation();

  const handleRowClick = (detail: { item: AllDocumentResponse }) => {
    const temp = selectedDocuments;
    if (!detail.item) return;
    if (temp?.includes(detail.item)) {
      setSelectDocuments(temp.filter((item) => item !== detail.item));
    } else {
      setSelectDocuments([detail.item, ...temp]);
    }
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      <Table
        size="small"
        columns={visibleColumns}
        loading={isLoading}
        rowKey="id"
        dataSource={documentsData}
        onRow={(record) => ({
          onClick: () => {
            handleRowClick({ item: record });
            setSelectedRow(record);
          },
        })}
        rowSelection={{ ...rowSelection, columnWidth: 60 }}
        pagination={{
          position: ["bottomRight"],
          style: { backgroundColor: "white", padding: "10px", margin: "0" },
          pageSizeOptions: [10, 25, 50, 100, 300],
          showSizeChanger: true,
          total: documentsData?.length,
          showTotal: (total) => `Total ${total} items`,
        }}
        scroll={{ y: "50vh" }}
        title={() => (
          <Space direction="vertical" style={{ display: "flex" }}>
            <Flex
              align="start"
              justify={"space-between"}
              style={{ margin: "16px 10px 0 10px" }}
            >
              <Space size="middle">
                <Typography.Title
                  style={{ paddingTop: "0", marginTop: "0" }}
                  level={4}
                >
                  {`Documents (${documentsData?.length ?? ""})`}
                </Typography.Title>
              </Space>
              <Space size="middle">
                <IncredableButton
                  type="primary"
                  icon={<PlusOutlined />}
                  size="large"
                  onClick={() => setIsAddDocumentModalVisible(true)}
                >
                  Add Document
                </IncredableButton>
              </Space>
            </Flex>
            <TableTitle
              actionDropDownProps={{
                disabled: selectedRowKeys?.length === 0,
                menu: {
                  items: [
                    {
                      label: "Delete",
                      key: "delete",
                      icon: <DeleteOutlined />,
                      danger: true,
                    },
                  ],
                  onClick: async (e) => {
                    if (e.key === "delete") {
                      if (!!selectedRowKeys || !!id) {
                        spinnerContext?.showSpinner();
                        await deleteCredentialPackageDocuments({
                          credentialPackageId: String(id),
                          documents: selectedRowKeys.map(String),
                        });
                        spinnerContext?.hidespinner();
                        toastContext?.openSuccessNotification({
                          message: `credential package document deleted successfully`,
                          placement: "topRight",
                        });
                      }
                    }
                  },
                },
              }}
              onSettingsClick={handleOpenModal}
            />
          </Space>
        )}
      />
      <TablePreferenceModal
        visible={isModalVisible}
        onCancel={handleCloseModal}
        onSave={handleSavePreferences}
        availableColumns={columns.map((col) => col.key as string)}
        selectedColumns={selectedColumns}
        columnOrderState={visibleColumnOrder}
      />
      <CredentialPackageAddDocumentsModal
        closeModal={() => setIsAddDocumentModalVisible(false)}
        visible={isAddDocumentModalVisible}
      />
    </>
  );
}

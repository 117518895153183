import { useContext, useState } from "react";
import { Button, ConfigProvider, Dropdown, Layout, Space, Typography } from "antd";
import { DeleteOutlined, DownOutlined, EditOutlined, PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import IncredableDateFormat from "../../common/incredable-date-format";
import DeleteAlertModal from "../../common/Modal/delete-alert-modal";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import { WorkFlowTask } from "../../redux/api/workflow/types";
import { useGetWorkflowQuery } from "../../redux/api/workflow/workflow";
import { skipToken } from "@reduxjs/toolkit/query";
import { useDeleteTaskMutation } from "../../redux/api/task";
import { Header, Table } from "@cloudscape-design/components";
import { TablePreferences, TASKS_CONTENT_DISPLAY_OPTIONS, TASKS_TABLE_DEFAULT_PREFERENCES } from "./table-config";
import { useLocalStorage } from "../../common/localStorage";

export default function TasksListV2() {
    const [selectedRows, setSelectedRows] = useState<WorkFlowTask[]>([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const spinnerContext = useContext(SpinnerContext);
    const toastContext = useContext(ToastContext);
    const navigate = useNavigate();
    const { workflowId } = useParams();
    const { data: workflow, isLoading, isFetching, refetch } = useGetWorkflowQuery(
        !!workflowId ? { id: workflowId } : skipToken
    );
    const [tablePreferences, setTablePreferences] = useLocalStorage(
        "RD-Tasks-Table-Preferences",
        TASKS_TABLE_DEFAULT_PREFERENCES
    );

    const [deleteTask] = useDeleteTaskMutation();
    return (
        <>
            <Table
                loading={isFetching || isLoading}
                onSelectionChange={({ detail }) => {
                    setSelectedRows([...detail.selectedItems]);
                }}
                onRowClick={(onRowClickDetails) => {
                    const selectedItem = onRowClickDetails?.detail?.item;
                    if (selectedItem) {
                        const temp = selectedRows ? [...selectedRows] : [];
                        const selectedItemIndex = temp.findIndex(
                            (record) => record?.id + "" === "" + selectedItem?.id
                        );
                        if (selectedItemIndex !== -1) {
                            temp?.splice(selectedItemIndex, 1);
                            setSelectedRows(temp);
                        } else {
                            setSelectedRows([...temp, selectedItem]);
                        }
                    }
                }}
                selectedItems={selectedRows || []}
                columnDefinitions={[
                    {
                        id: "id",
                        header: <div>Task ID</div>,
                        cell: (item) => <span>{item.id}</span>,
                    },
                    {
                        id: "taskType",
                        header: <div>Task Type</div>,
                        cell: (item) => (
                            item?.taskType?.name
                        )
                    },
                    {
                        id: "name",
                        header: <div>Name</div>,
                        cell: (item) => <span>{!!item?.name ? item?.name : "-"}</span>,
                        sortingField: "name",
                        sortingComparator: (a, b) =>
                            (a?.name ?? "").localeCompare(b?.name ?? ""),
                    },
                    {
                        id: "admin",
                        header: <div>Assigned To</div>,
                        cell: (item) => (
                            <span>
                                {!!item?.admin
                                    ? `${item?.admin?.firstName} ${item?.admin?.lastName}`
                                    : "-"}
                            </span>
                        ),
                    },
                    {
                        id: "provider",
                        header: <div>Provider</div>,
                        cell: (item) => (
                            <span>
                                {!!item?.provider
                                    ? `${item?.provider?.firstName} ${item?.provider?.lastName}`
                                    : "-"}
                            </span>
                        ),
                    },
                    {
                        id: "facility",
                        header: <div>Facility</div>,
                        cell: (item) => (
                            <span>{!!item?.facility ? `${item?.facility?.name}` : "-"}</span>
                        ),
                    },
                    {
                        id: "taskStatusId",
                        header: <div>Task Status</div>,
                        cell: (item) => <span>{item?.taskStatus?.name}</span>,
                    },
                    {
                        id: "startDate",
                        header: <div>Start Date</div>,
                        cell: (item) => <span>{IncredableDateFormat(item?.startDate) ?? "-"}</span>,
                    },
                    {
                        id: "dueDate",
                        header: <div>Expiration Date</div>,
                        cell: (item) => <span>{IncredableDateFormat(item?.dueDate) ?? "-"}</span>,
                        sortingField: "dueDate",
                    },
                    {
                        id: "createdDate",
                        header: <div>Created Date</div>,
                        cell: (item) => <span>{IncredableDateFormat(item?.createdDate)}</span>,
                    },
                ]}
                items={!!workflow ? workflow?.tasks : []}
                preferences={
                    <TablePreferences
                        preferences={tablePreferences}
                        setPreferences={(preferences) => {
                            setTablePreferences(preferences);
                        }}
                        contentDisplayOptions={TASKS_CONTENT_DISPLAY_OPTIONS}
                    />
                }
                loadingText="Loading resources"
                selectionType="multi"
                trackBy="id"
                header={
                    <div style={{ marginTop: "8px", marginBottom: "12px" }}>
                        <Header
                            actions={
                                <Space size="middle">
                                    <Button
                                        onClick={() => {
                                            !!refetch && refetch();
                                        }}
                                    >
                                        <ReloadOutlined />
                                    </Button>
                                    <Dropdown
                                        menu={{
                                            items: [
                                                {
                                                    label: "Edit",
                                                    key: "edit",
                                                    disabled: selectedRows?.length !== 1,
                                                    icon: <EditOutlined />,
                                                    onClick: () => {
                                                        navigate(`task/${selectedRows?.at(0)?.id}`);
                                                    },
                                                },
                                                {
                                                    label: "Delete",
                                                    key: "delete",
                                                    onClick: () => setIsDeleteModalOpen(true),
                                                    disabled: selectedRows?.length !== 1,
                                                    icon: <DeleteOutlined />,
                                                    danger: true,
                                                },
                                            ],
                                        }}
                                    >
                                        <Button>
                                            Actions
                                            <DownOutlined />
                                        </Button>
                                    </Dropdown>
                                    <ConfigProvider
                                        theme={{
                                            token: {
                                                colorPrimary: "#2B6BE6",
                                            },
                                        }}
                                    >
                                        <Button
                                            onClick={() => navigate(`add`)}
                                            type="primary"
                                            icon={<PlusOutlined />}
                                        >
                                            Add Task
                                        </Button>
                                    </ConfigProvider>
                                </Space>
                            }
                        >
                            <Typography.Title level={3} style={{ marginTop: "0" }}>
                                Tasks{" "}
                                <Typography.Text
                                    type="secondary"
                                    style={{ fontSize: "16px" }}
                                >{`(${workflow?.tasks?.length})`}</Typography.Text>
                            </Typography.Title>
                        </Header>
                    </div>
                }
                columnDisplay={tablePreferences?.contentDisplay}
                wrapLines={tablePreferences?.wrapLines}
                stripedRows={tablePreferences?.stripedRows}
                contentDensity={tablePreferences?.contentDensity}
                stickyColumns={tablePreferences?.stickyColumns}
            />
            <DeleteAlertModal
                visible={isDeleteModalOpen}
                action={async () => {
                    if (selectedRows?.at(0)?.id) {
                        setIsDeleteModalOpen(false);
                        spinnerContext?.showSpinner();
                        await deleteTask({
                            id: String(selectedRows?.at(0)?.id),
                        });
                        spinnerContext?.hidespinner();
                        toastContext?.openSuccessNotification({
                            message: `Task deleted successfully`,
                            placement: "topRight",
                        });
                    }
                }}
                closeModal={() => setIsDeleteModalOpen(false)}
                header="Delete Workflow Task "
                content={
                    <>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <Typography.Text>Workflow Task Name</Typography.Text>
                            <Typography.Text strong style={{ marginTop: "8px" }}>
                                {selectedRows?.at(0)?.name}
                            </Typography.Text>
                        </div>
                    </>
                }
                description="Are you sure you want to delete this workflowTask?"
            />
        </>
    );
}

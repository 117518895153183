import { ConfigProvider, Flex, Layout, Space, Tag, Typography } from "antd";
import { CalendarOutlined, DownloadOutlined } from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";
import IncredableButton from "../components/button";
import { Images } from "../common/images";
import IncredableDateFormat from "../common/incredable-date-format";
import { PayerEnrollment } from "../redux/api/payerenrollment/types";
import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { Header, Pagination, Table } from "@cloudscape-design/components";
import { useLazyGetPayerEnrollmentsReportQuery } from "../redux/api/reporting/reporting";

export default function PayerEnrollmentsReport() {
    const [selectedRows, setSelectedRows] = useState<PayerEnrollment[]>();
    const [pageSize, setPageSize] = useState<number>(10);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [sortingDetails, setSortingDetails] = useState<{
        sortingField?: string;
        isDescending?: boolean;
    }>({ sortingField: "id", isDescending: false });

    const navigate = useNavigate();
    const [
        trigger,
        { data: payerEnrollmentReportsData, isLoading, isFetching },
    ] = useLazyGetPayerEnrollmentsReportQuery({});

    // Debounced trigger function
    const debouncedTrigger = useCallback(
        debounce((filters) => {
            trigger(filters);
        }, 1200),
        [trigger]
    );

    // Clean up debounce on unmount
    useEffect(() => {
        return () => {
            debouncedTrigger.cancel();
        };
    }, [debouncedTrigger]);

    useEffect(() => {
        debouncedTrigger({
            pageNumber,
            pageSize,
            sortBy: sortingDetails?.sortingField,
            direction: !!sortingDetails?.isDescending ? "DSC" : "ASC",
        });
    }, [
        pageNumber,
        pageSize,
        sortingDetails,
        debouncedTrigger,
    ]);
    return (
        <div style={{ padding: "32px 32px 0 32px" }}>
            <Table
                loading={isFetching || isLoading}
                onSelectionChange={({ detail }) => {
                    setSelectedRows([...detail.selectedItems]);
                }}
                onRowClick={(onRowClickDetails) => {
                    const selectedItem = onRowClickDetails?.detail?.item;
                    if (selectedItem) {
                        const temp = selectedRows ? [...selectedRows] : [];
                        const selectedItemIndex = temp.findIndex(
                            (record) => record?.id + "" === "" + selectedItem?.id
                        );
                        if (selectedItemIndex !== -1) {
                            temp?.splice(selectedItemIndex, 1);
                            setSelectedRows(temp);
                        } else {
                            setSelectedRows([...temp, selectedItem]);
                        }
                    }
                }}
                selectedItems={selectedRows || []}
                sortingColumn={{ sortingField: sortingDetails?.sortingField }}
                sortingDescending={sortingDetails?.isDescending}
                onSortingChange={({ detail }) => {
                    setSortingDetails({
                        sortingField: detail?.sortingColumn?.sortingField,
                        isDescending: detail?.isDescending,
                    });
                }}
                columnDefinitions={[
                    {
                        id: "id",
                        header: "ID",
                        cell: (item) => item?.id,
                        sortingField: "id",
                    },
                    {
                        id: "network",
                        header: "Network",
                        cell: (item) => item?.payerGroup?.name,
                        sortingField: "payerGroup.name",
                        sortingComparator: (a, b) =>
                            (a?.payerGroup?.name ?? "").localeCompare(
                                b?.payerGroup?.name ?? ""
                            ),
                    },
                    {
                        id: "payer",
                        header: "Payer Name",
                        cell: (item) => (
                            <NavLink
                                to={`/payer/${item?.payer?.id
                                    }/payerenrollment/${item?.id
                                    }/edit `}
                            >{item?.payer?.name}</NavLink>
                        ),
                        sortingField: "payer.name",
                        isRowHeader: true,
                    },
                    {
                        id: "status",
                        header: "Status",
                        cell: (item) => <span>{item?.status?.name ?? "-"}</span>,
                        sortingField: "status.name",
                        sortingComparator: (a, b) =>
                            (a?.status?.name ?? "").localeCompare(b?.status?.name ?? ""),
                    },
                    {
                        id: "payerProviderId",
                        header: "Payer Provider ID",
                        cell: (item) => <div>{item?.payerProviderId}</div>,
                        sortingField: "payerProviderId",
                        sortingComparator: (a, b) =>
                            (a?.payerProviderId ?? "").localeCompare(
                                b?.payerProviderId ?? ""
                            ),
                    },
                    {
                        id: "provider",
                        header: "Provider",
                        cell: (item) => (
                            <span>
                                {(item?.provider &&
                                    item?.provider?.firstName + " " + item?.provider?.lastName) ??
                                    "-"}
                            </span>
                        ),
                        sortingField: "provider.firstName",
                        sortingComparator: (a, b) =>
                            (a?.provider?.firstName ?? "").localeCompare(
                                b?.provider?.firstName ?? ""
                            ),
                    },
                    {
                        id: "facility",
                        header: "Facility",
                        cell: (item) => <span>{item?.facility?.name ?? "-"}</span>,
                        sortingField: "facility.name",
                        sortingComparator: (a, b) =>
                            (a?.facility?.name ?? "").localeCompare(b?.facility?.name ?? ""),
                    },
                    {
                        id: "facilityGroup",
                        header: "Facility Group",
                        cell: (item) => <span>{item?.facilityGroup?.name ?? "-"}</span>,
                        sortingField: "facilityGroup.name",
                        sortingComparator: (a, b) =>
                            (a?.facilityGroup?.name ?? "").localeCompare(b?.facilityGroup?.name ?? ""),
                    },
                    {
                        id: "submittedDate",
                        header: "Submitted Date",
                        cell: (item) => (
                            <span>{IncredableDateFormat(item?.submittedDate) ?? "-"}</span>
                        ),
                    },
                    {
                        id: "approvedDate",
                        header: "Approved Date",
                        cell: (item) => (
                            <span>{IncredableDateFormat(item?.approvedDate) ?? "-"}</span>
                        ),
                    },
                    {
                        id: "effectiveDate",
                        header: "Effective Date",
                        cell: (item) => (
                            <span>{IncredableDateFormat(item?.effectiveDate) ?? "-"}</span>
                        ),
                    },
                    {
                        id: "recredentialingDate",
                        header: "Recredentialing Date",
                        cell: (item) => (
                            <span>
                                {IncredableDateFormat(item?.recredentialingDate) ?? "-"}
                            </span>
                        ),
                    },
                    {
                        id: "followupDate",
                        header: "Followup Date",
                        cell: (item) => (
                            <span>
                                {item?.alertDays > 0 ? `${item?.alertDays} days` : "-"}
                            </span>
                        ),
                    },
                    {
                        id: "description",
                        header: "Description",
                        cell: (item) => <span>{item?.description ?? "-"}</span>,
                    },
                    {
                        id: "workflow",
                        header: "Workflow",
                        cell: (item) => <span>{item?.workflow?.name ?? "-"}</span>,
                    },
                ]}
                items={!!payerEnrollmentReportsData ? payerEnrollmentReportsData?.payerEnrollments : []}
                pagination={
                    <Pagination
                        currentPageIndex={pageNumber + 1}
                        onChange={({ detail }) => {
                            setPageNumber(detail.currentPageIndex - 1);
                        }}
                        pagesCount={
                            !!pageSize && pageSize !== 0
                                ? Math.ceil(!!payerEnrollmentReportsData ? payerEnrollmentReportsData?.totalRecords / pageSize : 0)
                                : 1
                        }
                    />
                }
                loadingText="Loading resources"
                selectionType="multi"
                trackBy="id"
                header={
                    <div style={{ marginTop: "8px", marginBottom: "12px" }}>
                        <Header
                            actions={
                                <ConfigProvider
                                    theme={{
                                        token: {
                                            colorPrimary: "#2B6BE6",
                                        },
                                    }}
                                >
                                    <Space size="middle">
                                        <IncredableButton
                                            onClick={() => navigate("")}
                                            type="primary"
                                            icon={<DownloadOutlined />}
                                            size="large"
                                        >
                                            Export CSV
                                        </IncredableButton>
                                    </Space>
                                </ConfigProvider>
                            }
                        >
                            <Typography.Title level={3} style={{ marginTop: "0" }}>
                                Payer Enrollment Reports{" "}
                                <Typography.Text
                                    type="secondary"
                                    style={{ fontSize: "16px" }}
                                >{`(${payerEnrollmentReportsData?.totalRecords})`}</Typography.Text>
                            </Typography.Title>
                        </Header>
                    </div>
                }
            />
        </div>
    );
}

import { useContext, useState } from "react";
import { Flex, Space, Table, Typography } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import TableTitle from "../components/incredable-table/table-title";
import { TablePreferenceModal } from "../common/TablePreferenceModal/";
import { SpinnerContext } from "../context/spinner/spinner";
import { ToastContext } from "../context/toast/incredable-toast";
import { useDeleteFacilityMutation } from "../redux/api/facility/facility";
import DeleteAlertModal from "../common/Modal/delete-alert-modal";
import { InvitationType } from "../redux/api/manageadmin/types";
import useActiveMembersFilter from "../table-filters/activemembers-filters";
import useInvitationsFilter from "../table-filters/invitations-filters";
import { useDeleteInviteMutation } from "../redux/api/manageadmin/invitation";

export const invitationsColumns = [
    {
        title: () => <Typography.Text>Email</Typography.Text>,
        key: "email",
        ellipsis: true,
        render: (invitation: InvitationType) => (
            <Typography.Text>
                {invitation?.email}
            </Typography.Text>
        ),
    },
    {
        title: () => <Typography.Text>Role</Typography.Text>,
        dataIndex: ["role", "name"],
        key: "role",
        ellipsis: true,
        render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
    },
    {
        title: () => <Typography.Text>Status</Typography.Text>,
        dataIndex: "status",
        key: "status",
        ellipsis: true,
        render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
    },
    {
        title: () => <Typography.Text>Inviter Name</Typography.Text>,
        dataIndex: "inviterName",
        key: "inviterName",
        ellipsis: true,
        render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
    },
];

export default function InvitedList() {
    const [selectedRow, setSelectedRow] = useState<InvitationType>();
    const { TableFilters, filteredInvitations, UseQueryHookResult } =
        useInvitationsFilter();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

    const [selectedColumns, setSelectedColumns] =
        useState<string[]>(invitationsColumns.map((col) => col.key as string));
    const [visibleColumnOrder, setVisibleColumnOrder] = useState<string[]>(
        invitationsColumns.map((col) => col.key as string)
    );

    const navigate = useNavigate();
    const spinnerContext = useContext(SpinnerContext);
    const toastContext = useContext(ToastContext);

    const selectRow = (record: InvitationType) => {
        if (selectedRow?.id + "" === record?.id + "") {
            setSelectedRow(undefined);
        } else {
            setSelectedRow(record);
        }
    };

    const handleOpenModal = () => {
        setIsModalVisible(true);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
    };

    const handleOpenDeleteModal = () => {
        setDeleteModalOpen(true);
    };

    const handleSavePreferences = (
        selectedColumns: string[],
        columnOrder: string[]
    ) => {
        setSelectedColumns(selectedColumns);
        setVisibleColumnOrder(columnOrder);
        handleCloseModal();
    };
    const visibleColumns = visibleColumnOrder
        .filter((colKey) => selectedColumns.includes(colKey))
        .map((colKey) => invitationsColumns.find((col) => col.key === colKey)!);

    const [deleteInvitations] = useDeleteInviteMutation();

    return (
        <>
            <Table
                size="small"
                columns={visibleColumns}
                loading={
                    UseQueryHookResult.isLoading || UseQueryHookResult.isFetching
                }
                rowKey="id"
                dataSource={!!filteredInvitations ? [...filteredInvitations] : []}
                onRow={(record) => ({
                    onClick: () => {
                        selectRow(record);
                    },
                })}
                rowSelection={{
                    type: "radio",
                    selectedRowKeys: !!selectedRow?.id ? [selectedRow?.id] : [],
                    onChange: (selectedRowKeys, selectedRows, info) => {
                        if (!!selectedRows && !!selectedRows?.at(0))
                            setSelectedRow(selectedRows?.at(0));
                    },
                    columnWidth: 60,
                }}
                pagination={{
                    position: ["bottomRight"],
                    style: { backgroundColor: "white", padding: "10px", margin: "0" },
                    pageSizeOptions: [10, 25, 50],
                    showSizeChanger: true,
                    total: filteredInvitations?.length,
                    showTotal: (total) => `Total ${total} items`,
                }}
                title={() => (
                    <Space direction="vertical" style={{ display: "flex" }}>
                        <Flex
                            align="start"
                            justify={"space-between"}
                            style={{ margin: "16px 10px 0 10px" }}
                        >
                            <Space size="middle">
                                <Typography.Title
                                    style={{ paddingTop: "0", marginTop: "0" }}
                                    level={4}
                                >
                                    {`Invitations (${filteredInvitations?.length})`}
                                </Typography.Title>
                            </Space>
                        </Flex>
                        <TableTitle
                            filter={TableFilters}
                            onReloadClick={UseQueryHookResult.refetch}
                            actionDropDownProps={
                                {
                                    disabled: !selectedRow,
                                    menu: {
                                        items: [
                                            {
                                                label: "Delete",
                                                key: "delete",
                                                icon: <DeleteOutlined />,
                                                danger: true,
                                            },
                                        ],
                                        onClick: async (e) => {
                                            if (e?.key === "delete") {
                                                handleOpenDeleteModal();
                                            }
                                        },
                                    },
                                }}
                            onSettingsClick={handleOpenModal}
                        />
                    </Space>
                )}
            />
            <TablePreferenceModal
                visible={isModalVisible}
                onCancel={handleCloseModal}
                onSave={handleSavePreferences}
                availableColumns={invitationsColumns.map((col) => col.key as string)}
                selectedColumns={selectedColumns}
                columnOrderState={visibleColumnOrder}
            />
            <DeleteAlertModal
                visible={isDeleteModalOpen}
                action={async () => {
                    if (selectedRow?.id) {
                        setDeleteModalOpen(false);
                        spinnerContext?.showSpinner();
                        await deleteInvitations({
                            invitedId: selectedRow?.id,
                        });
                        spinnerContext?.hidespinner();
                        toastContext?.openSuccessNotification({
                            message: `Invitation deleted successfully`,
                            placement: "topRight",
                        });
                    }
                }}
                closeModal={() => setDeleteModalOpen(false)}
                header="Delete Invitation"
                content={
                    <Typography.Text>
                        Email:{" "}
                        <Typography.Text>{selectedRow?.email}</Typography.Text>
                    </Typography.Text>
                }
                description="Are you sure you want to delete this invitation?"
            />
        </>
    );
}

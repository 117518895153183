import { SearchOutlined } from "@ant-design/icons";
import { Input, Select, Space } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useGetFacilitiesQuery } from "../redux/api/facility/facility";
import { useGetBasicAllProvidersQuery } from "../redux/api/provider/provider";
import {
  useGetAllTasksQuery,
  useGetAllTaskStatusQuery,
  useGetAllTaskTypeQuery,
} from "../redux/api/task";
import { useGetAllMembersQuery } from "../redux/api/manageadmin/manageadmin";
import { useGetAllWorkflowQuery } from "../redux/api/workflow/workflow";

export default function useTaskFilter() {
  const [selectedFilterkey, setSelectedFilterKey] = useState<string>("name");
  const [selectedFiltervalue, setSelectedFiltervalue] = useState<
    string | undefined
  >(undefined);

  const { data: providers, isLoading: providersLoading } =
    useGetBasicAllProvidersQuery();
  const { data: taskTypes, isLoading: taskTypesLoading } =
    useGetAllTaskTypeQuery();
  const { data: taskStatus, isLoading: taskStatusLoading } =
    useGetAllTaskStatusQuery();
  const { data: facilities, isLoading: facilitiesLoading } =
    useGetFacilitiesQuery();
  const { data: assignedTo, isLoading: assignedToLoading } =
    useGetAllMembersQuery();
  const { data: workflows, isLoading: workflowsLoading } =
    useGetAllWorkflowQuery();
  const {
    data: tasks,
    refetch,
    isLoading: workflowLoading,
    isFetching,
  } = useGetAllTasksQuery();

  const filteredTasks = useMemo(() => {
    if (!!selectedFiltervalue) {
      if (selectedFilterkey === "name")
        return tasks?.filter((task) =>
          task?.name?.toLowerCase().includes(selectedFiltervalue?.toLowerCase())
        );
      else if (selectedFilterkey === "taskType")
        return tasks?.filter(
          (task) => task?.taskType?.id + "" === selectedFiltervalue + ""
        );
      else if (selectedFilterkey === "status")
        return tasks?.filter(
          (task) => task?.taskStatus?.id + "" === selectedFiltervalue + ""
        );
      else if (selectedFilterkey === "provider")
        return tasks?.filter(
          (task) => task?.provider?.id + "" === selectedFiltervalue + ""
        );
      else if (selectedFilterkey === "facility")
        return tasks?.filter(
          (provider) => provider?.facility?.id + "" === selectedFiltervalue + ""
        );
      else if (selectedFilterkey === "assignedTo")
        return tasks?.filter(
          (task) => task?.admin?.id + "" === selectedFiltervalue + ""
        );
      else if (selectedFilterkey === "workflow")
        return tasks?.filter(
          (task) => task?.workflow?.id + "" === selectedFiltervalue + ""
        );
    }
    return tasks;
  }, [selectedFiltervalue, tasks]);

  useEffect(() => {
    setSelectedFiltervalue(undefined);
  }, [selectedFilterkey]);

  const TableFilters = (
    <Space size="small">
      {selectedFilterkey === "taskType" && (
        <Select
          allowClear
          showSearch
          value={selectedFiltervalue}
          placeholder="Choose task type"
          onChange={(value) => {
            setSelectedFiltervalue(value);
          }}
          style={{ width: "320px" }}
          optionFilterProp="label"
          options={taskTypes?.map((taskType) => ({
            label: taskType?.name,
            searchValue: taskType?.name,
            value: taskType?.id + "",
          }))}
        />
      )}
      {selectedFilterkey === "name" && (
        <Input
          onChange={(event) => {
            setSelectedFiltervalue(event?.target?.value);
          }}
          value={selectedFiltervalue}
          style={{
            width: "320px",
          }}
          placeholder="Find Name"
          prefix={<SearchOutlined />}
        />
      )}
      {selectedFilterkey === "status" && (
        <Select
          allowClear
          showSearch
          optionFilterProp="label"
          key="status-select"
          value={selectedFiltervalue}
          placeholder="Select status"
          onChange={(value) => {
            setSelectedFiltervalue(value);
          }}
          style={{ width: "320px" }}
          options={taskStatus?.map((taskStatu) => ({
            label: taskStatu?.name,
            searchValue: taskStatu?.name,
            value: taskStatu?.id + "",
          }))}
        />
      )}
      {selectedFilterkey === "provider" && (
        <Select
          allowClear
          showSearch
          optionFilterProp="label"
          value={selectedFiltervalue}
          placeholder="Select Provider"
          onChange={(value) => {
            setSelectedFiltervalue(value);
          }}
          style={{ width: "320px" }}
          options={providers?.map((provider) => ({
            label: `${provider?.firstName} ${provider?.lastName}`,
            searchValue: `${provider?.firstName} ${provider?.lastName}`,
            value: provider?.id + "",
          }))}
        />
      )}
      {selectedFilterkey === "assignedTo" && (
        <Select
          allowClear
          showSearch
          optionFilterProp="label"
          value={selectedFiltervalue}
          placeholder="Select Assigned To"
          onChange={(value) => {
            setSelectedFiltervalue(value);
          }}
          style={{ width: "320px" }}
          options={assignedTo?.map((admin) => ({
            label: `${admin?.firstName} ${admin?.lastName}`,
            searchValue: `${admin?.firstName} ${admin?.lastName}`,
            value: admin?.id + "",
          }))}
        />
      )}
      {selectedFilterkey === "facility" && (
        <Select
          allowClear
          showSearch
          optionFilterProp="label"
          value={selectedFiltervalue}
          placeholder="Select facility"
          onChange={(value) => {
            setSelectedFiltervalue(value);
          }}
          style={{ width: "320px" }}
          options={facilities?.map((facility) => ({
            label: facility?.name,
            searchValue: facility?.name,
            value: facility?.id + "",
          }))}
        />
      )}
      {selectedFilterkey === "workflow" && (
        <Select
          allowClear
          showSearch
          optionFilterProp="label"
          value={selectedFiltervalue}
          placeholder="Select workflow"
          onChange={(value) => {
            setSelectedFiltervalue(value);
          }}
          style={{ width: "320px" }}
          options={workflows?.map((flow) => ({
            label: flow?.name,
            searchValue: flow?.name,
            value: flow?.id + "",
          }))}
        />
      )}
      <Select
        value={selectedFilterkey}
        onChange={(value) => {
          setSelectedFilterKey(value);
        }}
        style={{ width: "140px" }}
        options={[
          { value: "name", label: "Name" },
          { value: "taskType", label: "Task Type" },
          { value: "status", label: "Status" },
          { value: "provider", label: "Provider" },
          { value: "facility", label: "Facility" },
          { value: "assignedTo", label: "Assigned To" },
          { value: "workflow", label: "Workflow" },
        ]}
      />
    </Space>
  );

  return {
    TableFilters,
    filteredTasks,
    UseQueryHookResult: {
      refetch,
      isLoading:
        workflowLoading &&
        providersLoading &&
        taskTypesLoading &&
        taskStatusLoading &&
        facilitiesLoading &&
        assignedToLoading &&
        workflowLoading,
      isFetching,
    },
  };
}

import { SearchOutlined } from "@ant-design/icons";
import { Input, Select, Space } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useGetAllPayerQuery } from "../redux/api/payers/payers";
import { Payers } from "../redux/api/payers/types";

export default function usePayerFilter(items?: Payers[]) {
    const [selectedFilterkey, setSelectedFilterKey] = useState<string>("name");
    const [selectedFiltervalue, setSelectedFiltervalue] = useState<
        string | undefined
    >(undefined);

    const {
        data: payers = [],
        refetch,
        isLoading: payersLoading,
        isFetching,
    } = useGetAllPayerQuery();
    const [filteredPayers, setFilteredPayers] = useState<Payers[]>(items ?? payers)
    useEffect(() => {
        setFilteredPayers(items ?? payers);
    }, [payers, items]);

    useEffect(() => {
        let temp_filtered_payers = !!items ? [...items] : [...payers];
        if (!!selectedFiltervalue) {
            if (selectedFilterkey === "name") {
                temp_filtered_payers = temp_filtered_payers?.filter(
                    (payer) =>
                        payer?.name
                            ?.toLowerCase()
                            .includes(selectedFiltervalue?.toLowerCase())
                );
            }
            else if (selectedFilterkey === "payerId")
                temp_filtered_payers = temp_filtered_payers?.filter(
                    (payer) =>
                        payer?.payerId
                            ?.toLowerCase()
                            .includes(selectedFiltervalue?.toLowerCase())
                );
            else if (selectedFilterkey === "state")
                temp_filtered_payers = temp_filtered_payers?.filter(
                    (payer) =>
                        payer?.payerAddress?.state
                            ?.toLowerCase()
                            .includes(selectedFiltervalue?.toLowerCase())
                );
        }
        setFilteredPayers(temp_filtered_payers);
    }, [selectedFiltervalue, payers, items]);

    useEffect(() => {
        setSelectedFiltervalue(undefined);
    }, [selectedFilterkey]);

    const TableFilters = (
        <Space size="small">
            {selectedFilterkey === "name" && (
                <Input
                    onChange={(event) => {
                        setSelectedFiltervalue(event?.target?.value);
                    }}
                    value={selectedFiltervalue}
                    style={{
                        width: "320px",
                    }}
                    placeholder="Find Name"
                    prefix={<SearchOutlined />}
                />
            )}
            {selectedFilterkey === "payerId" && (
                <Input
                    onChange={(event) => {
                        setSelectedFiltervalue(event?.target?.value);
                    }}
                    value={selectedFiltervalue}
                    style={{
                        width: "320px",
                    }}
                    placeholder="Find Payer ID"
                    prefix={<SearchOutlined />}
                />
            )}
            {selectedFilterkey === "state" && (
                <Input
                    onChange={(event) => {
                        setSelectedFiltervalue(event?.target?.value);
                    }}
                    value={selectedFiltervalue}
                    style={{
                        width: "320px",
                    }}
                    placeholder="Find State"
                    prefix={<SearchOutlined />}
                />
            )}
            <Select
                value={selectedFilterkey}
                onChange={(value) => {
                    setSelectedFilterKey(value);
                }}
                style={{ width: "140px" }}
                options={[
                    { value: "name", label: "Payer Name" },
                    { value: "payerId", label: "Payer ID" },
                    { value: "state", label: "State" },
                ]}
            />
        </Space>
    );

    return {
        TableFilters,
        filteredPayers: filteredPayers,
        UseQueryHookResult: {
            refetch,
            isLoading: payersLoading,
            isFetching,
        },
    };
}

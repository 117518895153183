import { Space, Table, theme, Typography } from "antd";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TableTitle from "../../components/incredable-table/table-title";
import {
  CalendarOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { TablePreferenceModal } from "../../common/TablePreferenceModal";
import { ProviderEducationType } from "../../redux/api/provider/types";
import {
  useDeleteProviderEducationMutation,
  useGetProviderQuery,
} from "../../redux/api/provider/provider";
import { skipToken } from "@reduxjs/toolkit/query";
import IncredableButton from "../../components/button";
import IncredableDateFormat from "../../common/incredable-date-format";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import DeleteAlertModal from "../../common/Modal/delete-alert-modal";

export const educationColumns = [
  {
    title: () => "Degree",
    dataIndex: ["degree", "name"],
    key: "degree",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => "Type",
    key: "type",
    width: "120px",
    ellipsis: true,
    render: (education: ProviderEducationType) => (
      <Typography.Link href="#">{education?.type}</Typography.Link>
    ),
    sorter: (a: ProviderEducationType, b: ProviderEducationType) =>
      a?.type?.toLowerCase()?.localeCompare(b?.type?.toLowerCase()),
  },
  {
    title: () => "Name",
    dataIndex: "name",
    key: "name",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
    sorter: (a: ProviderEducationType, b: ProviderEducationType) =>
      a?.name?.toLowerCase()?.localeCompare(b?.name?.toLowerCase()),
  },
  {
    title: () => "Start Date",
    dataIndex: "startDate",
    key: "startDate",
    ellipsis: true,
    render: (startDate: string | null) => (
      <Typography.Text>
        {startDate ? (
          <Space>
            {" "}
            <CalendarOutlined /> {IncredableDateFormat(startDate)}
          </Space>
        ) : (
          "-"
        )}
      </Typography.Text>
    ),
    sorter: (a: ProviderEducationType, b: ProviderEducationType) =>
      new Date(a?.startDate).getTime() - new Date(b?.startDate).getTime()
  },
  {
    title: () => "End Date",
    dataIndex: "endDate",
    key: "endDate",
    ellipsis: true,
    render: (endDate: string | null) => (
      <Typography.Text>
        {endDate ? (
          <Space>
            {" "}
            <CalendarOutlined /> {IncredableDateFormat(endDate)}
          </Space>
        ) : (
          "-"
        )}
      </Typography.Text>
    ),
    sorter: (a: ProviderEducationType, b: ProviderEducationType) =>
      new Date(a?.endDate).getTime() - new Date(b?.endDate).getTime()
  },
  {
    title: () => "Residency Director",
    dataIndex: "residencyDirector",
    key: "residencyDirector",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => "Fellowship Director",
    dataIndex: "fellowshipDirector",
    key: "fellowshipDirector",
    ellipsis: true,
    render: (text: string) => (
      <Typography.Text>{text || "-"}</Typography.Text>
    ),
  },
  {
    title: () => "Email",
    dataIndex: "email",
    key: "email",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => "Phone",
    dataIndex: "phone",
    key: "phone",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => "Fax",
    dataIndex: "fax",
    key: "fax",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => "Address Lane 1",
    dataIndex: "addressLane1",
    key: "addressLane1",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => "Address Lane 2",
    dataIndex: "addressLane2",
    key: "addressLane2",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => "City",
    dataIndex: "city",
    key: "city",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => "State",
    dataIndex: "state",
    key: "state",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => "Country",
    dataIndex: "country",
    key: "country",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => "Zip",
    dataIndex: "zip",
    key: "zip",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
];

export default function Education() {
  const { providerId } = useParams();
  const [selectedRow, setSelectedRow] = useState<ProviderEducationType>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    educationColumns.map((col) => col.key as string)
  );
  const [visibleColumnOrder, setVisibleColumnOrder] = useState<string[]>(
    educationColumns.map((col) => col.key as string)
  );

  const {
    data: provider,
    isLoading,
    isFetching,
  } = useGetProviderQuery(
    !!providerId
      ? {
        providerId: providerId,
      }
      : skipToken
  );
  const navigate = useNavigate();
  const selectRow = (record: ProviderEducationType) => {
    if (selectedRow?.id + "" === record?.id + "") {
      setSelectedRow(undefined);
    } else {
      setSelectedRow(record);
    }
  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const handleSavePreferences = (
    selectedColumns: string[],
    columnOrder: string[]
  ) => {
    setSelectedColumns(selectedColumns);
    setVisibleColumnOrder(columnOrder);
    handleCloseModal();
  };

  const visibleColumns = visibleColumnOrder
    .filter((colKey) => selectedColumns.includes(colKey))
    .map((colKey) => educationColumns.find((col) => col.key === colKey)!);

  const [deleteProviderEducation] = useDeleteProviderEducationMutation();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  return (
    <>
      <Table
        style={{
          boxShadow: "0px 10px 25px 0px #5911920D",
          borderRadius: "16px",
          border: "1px solid #E5E7EB",
          overflow: "hidden",
        }}
        size="small"
        columns={visibleColumns}
        loading={isLoading || isFetching}
        rowKey="id"
        dataSource={
          !!provider?.providerEducations
            ? [...provider?.providerEducations]
            : []
        }
        onRow={(record) => ({
          onClick: () => {
            selectRow(record);
          },
        })}
        rowSelection={{
          type: "radio",
          selectedRowKeys: !!selectedRow?.id ? [selectedRow?.id] : [],
          onChange: (selectedRowKeys, selectedRows, info) => {
            if (!!selectedRows && !!selectedRows?.at(0))
              setSelectedRow(selectedRows?.at(0));
          },
          columnWidth: 60,
        }}
        pagination={{
          position: ["bottomRight"],
          style: { backgroundColor: "white", padding: "10px", margin: "0" },
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          total: provider?.providerEducations?.length,
          showTotal: (total) => `Total ${total} items`,
        }}
        title={() => (
          <Space
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span style={{ fontSize: "22px", fontWeight: "700" }}>
              {`Education (${provider?.providerEducations?.length})`}
            </span>
            <Space>
              <IncredableButton
                onClick={() =>
                  navigate(`/manageprovider/${providerId}/education/add`)
                }
                type="primary"
                icon={<PlusOutlined />}
                size="middle"
                style={{
                  backgroundColor: "#598BEB",
                  border: "2px solid #598BEB",
                  boxShadow: "none",
                }}
              >
                Add Education
              </IncredableButton>
              <TableTitle
                filter={[]}
                actionDropDownProps={{
                  disabled: !selectedRow,
                  menu: {
                    items: [
                      {
                        label: "Edit",
                        key: "edit",
                        icon: <EditOutlined />,
                      },
                      {
                        label: "Delete",
                        key: "delete",
                        icon: <DeleteOutlined />,
                        danger: true,
                      },
                    ],
                    onClick: async (e) => {
                      if (e?.key === "edit") {
                        navigate(`${selectedRow?.id}/edit`);
                      }
                      if (e?.key === "delete") {
                        handleOpenDeleteModal();
                      }
                    },
                  },
                }}
                onSettingsClick={handleOpenModal}
              />
            </Space>
          </Space>
        )}
        scroll={{ y: 400, x: "100vw" }}
      />
      <TablePreferenceModal
        visible={isModalVisible}
        onCancel={handleCloseModal}
        onSave={handleSavePreferences}
        availableColumns={educationColumns.map((col) => col.key as string)}
        selectedColumns={selectedColumns}
        columnOrderState={visibleColumnOrder}
      />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (!!providerId && !!selectedRow?.id) {
            setDeleteModalOpen(false);
            spinnerContext?.showSpinner();
            await deleteProviderEducation({
              providerId: providerId,
              educationId: selectedRow?.id + "",
            });
            spinnerContext?.hidespinner();
            toastContext?.openSuccessNotification({
              message: `Education deleted successfully`,
              placement: "topRight",
            });
          }
        }}
        closeModal={() => setDeleteModalOpen(false)}
        header="Delete Education"
        content={
          <Typography.Text>
            Education Name:{" "}
            <Typography.Text strong>{selectedRow?.name}</Typography.Text>
          </Typography.Text>
        }
        description="Are you sure you want to delete this education?"
      />

    </>
  );
}

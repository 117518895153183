import { useContext, useState } from "react";
import { Flex, Layout, Space, Table, Typography } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import TableTitle from "../components/incredable-table/table-title";
import { TablePreferenceModal } from "../common/TablePreferenceModal";
import IncredableButton from "../components/button";
import useCredentialPackageFilter from "../table-filters/credentialpackages-filters";
import { CredentialPackage } from "../redux/api/credentialpackages/types";
import IncredableDateFormat from "../common/incredable-date-format";
import DeleteAlertModal from "../common/Modal/delete-alert-modal";
import { useDeleteCredentialPackageMutation } from "../redux/api/credentialpackages/credentialpackages";
import { SpinnerContext } from "../context/spinner/spinner";
import { ToastContext } from "../context/toast/incredable-toast";

export const columns = [
  {
    title: () => <Typography.Text>Name</Typography.Text>,
    key: "name",
    width: "120px",
    ellipsis: true,
    render: (credentialpackage: CredentialPackage) => (
      <Typography.Link href={`credentialpackages/${credentialpackage?.id}`}>
        {credentialpackage?.name
          ? `${credentialpackage?.name}`
          : "-"}
      </Typography.Link>
    ),
    sorter: (a: CredentialPackage, b: CredentialPackage) =>
      a?.name?.toLowerCase()?.localeCompare(b?.name?.toLowerCase()),
  },
  {
    title: () => <Typography.Text>Package Type</Typography.Text>,
    dataIndex: ["packageType", "name"],
    key: "packageType",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
    sorter: (a: CredentialPackage, b: CredentialPackage) =>
      a?.packageType?.name?.toLowerCase()?.localeCompare(b?.packageType?.name?.toLowerCase()),
  },
  {
    title: () => <Typography.Text>Package Status</Typography.Text>,
    dataIndex: ["packageStatus", "name"],
    key: "packageStatus",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
    sorter: (a: CredentialPackage, b: CredentialPackage) =>
      a?.packageStatus?.name?.toLowerCase()?.localeCompare(b?.packageStatus?.name?.toLowerCase()),
  },
  {
    title: () => <Typography.Text>Provider</Typography.Text>,
    dataIndex: ["provider"],
    key: "provider",
    ellipsis: true,
    render: (provider: { firstName: string; lastName: string }) => (
      <Typography.Text>
        {provider?.firstName && provider?.lastName
          ? `${provider.firstName} ${provider.lastName}`
          : "-"}
      </Typography.Text>
    ),
    sorter: (a: CredentialPackage, b: CredentialPackage) =>
      a?.provider?.firstName?.toLowerCase()?.localeCompare(b?.provider?.lastName?.toLowerCase()),
  },
  {
    title: () => <Typography.Text>Credential Template</Typography.Text>,
    dataIndex: ["credentialTemplate", "name"],
    key: "credentialTemplate",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
    sorter: (a: CredentialPackage, b: CredentialPackage) =>
      a?.credentialTemplate?.name?.toLowerCase()?.localeCompare(b?.credentialTemplate?.name?.toLowerCase()),
  },
  {
    title: () => <Typography.Text>Submitted Date</Typography.Text>,
    dataIndex: "submittedDate",
    key: "submittedDate",
    ellipsis: true,
    render: (submittedDate: string) => (
      <Typography.Text>
        {IncredableDateFormat(submittedDate) ?? "-"}
      </Typography.Text>
    ),
    sorter: (a: CredentialPackage, b: CredentialPackage) =>
      new Date(a.submittedDate).getTime() - new Date(b.submittedDate).getTime()
  },
  {
    title: () => <Typography.Text>Re-Credentialing Date</Typography.Text>,
    dataIndex: "recredentialingDate",
    key: "recredentialingDate",
    ellipsis: true,
    render: (recredentialingDate: string) => (
      <Typography.Text>
        {IncredableDateFormat(recredentialingDate) ?? "-"}
      </Typography.Text>
    ),
    sorter: (a: CredentialPackage, b: CredentialPackage) =>
      new Date(a.recredentialingDate).getTime() - new Date(b.recredentialingDate).getTime()
  },
  {
    title: () => <Typography.Text>Follow Up Date</Typography.Text>,
    dataIndex: "alertDays",
    key: "followUpDate",
    ellipsis: true,
    render: (text: number) => <Typography.Text>{text ? `${text} days` : "-"}</Typography.Text>,
    sorter: (a: CredentialPackage, b: CredentialPackage) =>
      a?.alertDays - (b?.alertDays)
  },
];

export default function CredentialPackagesList() {
  const [selectedRow, setSelectedRow] = useState<CredentialPackage>();
  const { TableFilters, filteredCredentialPackages, UseQueryHookResult } =
    useCredentialPackageFilter();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    columns.map((col) => col.key as string)
  );
  const [visibleColumnOrder, setVisibleColumnOrder] = useState<string[]>(
    columns.map((col) => col.key as string)
  );

  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  const [deletePackage] = useDeleteCredentialPackageMutation();

  const navigate = useNavigate();

  const selectRow = (record: CredentialPackage) => {
    if (selectedRow?.id + "" === record?.id + "") {
      setSelectedRow(undefined);
    } else {
      setSelectedRow(record);
    }
  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const handleSavePreferences = (
    selectedColumns: string[],
    columnOrder: string[]
  ) => {
    setSelectedColumns(selectedColumns);
    setVisibleColumnOrder(columnOrder);
    handleCloseModal();
  };

  const visibleColumns = visibleColumnOrder
    .filter((colKey) => selectedColumns.includes(colKey))
    .map((colKey) => columns.find((col) => col.key === colKey)!);

  return (
    <Layout style={{ margin: "20px 30px 0 30px" }}>
      <Space size="large" direction="vertical">
        <Table
          style={{
            boxShadow: "0px 10px 25px 0px #5911920D",
            borderRadius: "16px",
            border: "1px solid #E5E7EB",
            overflow: "hidden",
          }}
          size="small"
          columns={visibleColumns}
          loading={
            UseQueryHookResult.isLoading || UseQueryHookResult.isFetching
          }
          rowKey="id"
          dataSource={filteredCredentialPackages}
          onRow={(record) => ({
            onClick: () => {
              selectRow(record);
            },
          })}
          rowSelection={{
            type: "radio",
            selectedRowKeys: !!selectedRow?.id ? [selectedRow?.id] : [],
            onChange: (selectedRowKeys, selectedRows, info) => {
              if (!!selectedRows && !!selectedRows?.at(0))
                setSelectedRow(selectedRows?.at(0));
            },
            columnWidth: 60,
          }}
          pagination={{
            position: ["bottomRight"],
            style: { backgroundColor: "white", padding: "10px", margin: "0" },
            pageSizeOptions: [10, 25, 50, 100, 300],
            showSizeChanger: true,
            total: filteredCredentialPackages?.length,
            showTotal: (total) => `Total ${total} items`,
          }}
          scroll={{ y: "50vh", x: "100vw" }}
          title={() => (
            <Space direction="vertical" style={{ display: "flex" }}>
              <Flex
                align="start"
                justify={"space-between"}
                style={{ margin: "16px 10px 0 10px" }}
              >
                <Space size="middle">
                  <Typography.Title
                    style={{ paddingTop: "0", marginTop: "0" }}
                    level={4}
                  >
                    {`Credential Packages (${filteredCredentialPackages?.length ?? ""})`}
                  </Typography.Title>
                </Space>
                <Space size="middle">
                  <IncredableButton
                    onClick={() => navigate("add")}
                    type="primary"
                    icon={<PlusOutlined />}
                    size="large"
                  >
                    Start Package
                  </IncredableButton>
                </Space>
              </Flex>
              <TableTitle
                filter={TableFilters}
                onReloadClick={UseQueryHookResult.refetch}
                actionDropDownProps={{
                  disabled: !selectedRow,
                  menu: {
                    items: [
                      {
                        label: "Edit",
                        key: "edit",
                        icon: <EditOutlined />,
                      },
                      {
                        label: "Delete",
                        key: "delete",
                        icon: <DeleteOutlined />,
                        danger: true,
                      },
                    ],
                    onClick: (e) => {
                      if (e?.key === "edit") {
                        navigate(`${selectedRow?.id}`);
                      }
                      if (e.key === "delete") {
                        handleOpenDeleteModal();
                      }
                    },
                  },
                }}
                onSettingsClick={handleOpenModal}
              />
            </Space>
          )}
        />
        <TablePreferenceModal
          visible={isModalVisible}
          onCancel={handleCloseModal}
          onSave={handleSavePreferences}
          availableColumns={columns.map((col) => col.key as string)}
          selectedColumns={selectedColumns}
          columnOrderState={visibleColumnOrder}
        />
        <DeleteAlertModal
          visible={isDeleteModalOpen}
          action={async () => {
            if (selectedRow?.id) {
              setDeleteModalOpen(false);
              spinnerContext?.showSpinner();
              await deletePackage({
                credentialPackageId: String(selectedRow?.id),
              });
              spinnerContext?.hidespinner();
              toastContext?.openSuccessNotification({
                message: `credential package deleted successfully`,
                placement: "topRight",
              });
            }
          }}
          closeModal={() => setDeleteModalOpen(false)}
          header="Delete Credential Package"
          content={
            <>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography.Text>Package Name</Typography.Text>
                <Typography.Text strong style={{ marginTop: "8px" }}>{selectedRow?.name}</Typography.Text>
              </div>
            </>
          }
          description="Are you sure you want to delete this package?"
        />
      </Space>
    </Layout>
  );
}

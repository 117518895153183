import { useContext, useState } from "react";
import { Flex, Layout, Space, Table, theme, Typography } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import IncredableButton from "../../components/button";
import TableTitle from "../../components/incredable-table/table-title";
import { TablePreferenceModal } from "../../common/TablePreferenceModal";
import DeleteAlertModal from "../../common/Modal/delete-alert-modal";
import { FacilityGroup } from "../../redux/api/facilitygroup/types";
import { useDeleteFacilityGroupMutation } from "../../redux/api/facilitygroup/facilityGroup";
import useFacilityGroupFilter from "../../table-filters/facilitygroup-filters";
const { useToken } = theme;

export const facilitygroupColumns = [
  {
    title: () => <Typography.Text>Name</Typography.Text>,
    key: "name",
    width: "120px",
    ellipsis: true,
    render: (facilitygroup: FacilityGroup) => (
      <Typography.Link href={`/facilitygroup/${facilitygroup?.id}/edit`}>
        {facilitygroup?.name}
      </Typography.Link>
    ),
    sorter: (a: FacilityGroup, b: FacilityGroup) =>
      a?.name?.toLowerCase()?.localeCompare(b?.name?.toLowerCase()),
  },
  {
    title: () => <Typography.Text>Description</Typography.Text>,
    dataIndex: "description",
    key: "description",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
];

export default function FacilityGroupList() {
  const [selectedRow, setSelectedRow] = useState<FacilityGroup>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    facilitygroupColumns.map((col) => col.key as string)
  );
  const [visibleColumnOrder, setVisibleColumnOrder] = useState<string[]>(
    facilitygroupColumns.map((col) => col.key as string)
  );
  const { TableFilters, filteredFacilityGroup, UseQueryHookResult } =
    useFacilityGroupFilter();

  const navigate = useNavigate();
  const { token } = useToken();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  const selectRow = (record: FacilityGroup) => {
    if (selectedRow?.id + "" === record?.id + "") {
      setSelectedRow(undefined);
    } else {
      setSelectedRow(record);
    }
  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const handleSavePreferences = (
    selectedColumns: string[],
    columnOrder: string[]
  ) => {
    setSelectedColumns(selectedColumns);
    setVisibleColumnOrder(columnOrder);
    handleCloseModal();
  };
  const visibleColumns = visibleColumnOrder
    .filter((colKey) => selectedColumns.includes(colKey))
    .map((colKey) => facilitygroupColumns.find((col) => col.key === colKey)!);

  const [deleteFacilityGroup] = useDeleteFacilityGroupMutation();

  return (
    <Layout style={{ margin: "20px 30px 0 30px" }}>
      <Space size="large" direction="vertical">
        <Table
          style={{
            boxShadow: "0px 10px 25px 0px #5911920D",
            borderRadius: "16px",
            border: "1px solid #E5E7EB",
            overflow: "hidden",
          }}
          size="small"
          columns={visibleColumns}
          loading={
            UseQueryHookResult?.isLoading || UseQueryHookResult?.isFetching
          }
          rowKey="id"
          dataSource={!!filteredFacilityGroup ? [...filteredFacilityGroup] : []}
          onRow={(record) => ({
            onClick: () => {
              selectRow(record);
            },
          })}
          rowSelection={{
            type: "radio",
            selectedRowKeys: !!selectedRow?.id ? [selectedRow?.id] : [],
            onChange: (selectedRowKeys, selectedRows, info) => {
              if (!!selectedRows && !!selectedRows?.at(0))
                setSelectedRow(selectedRows?.at(0));
            },
            columnWidth: 60,
          }}
          pagination={{
            position: ["bottomRight"],
            style: { backgroundColor: "white", padding: "10px", margin: "0" },
            pageSizeOptions: [10, 25, 50],
            showSizeChanger: true,
            total: filteredFacilityGroup?.length,
            showTotal: (total) => `Total ${total} items`,
          }}
          title={() => (
            <Space direction="vertical" style={{ display: "flex" }}>
              <Flex
                align="start"
                justify={"space-between"}
                style={{ margin: "16px 10px 0 10px" }}
              >
                <Space size="middle">
                  <Typography.Title
                    style={{ paddingTop: "0", marginTop: "0" }}
                    level={4}
                  >
                    {`Facility Group (${filteredFacilityGroup?.length})`}
                  </Typography.Title>
                </Space>
                <Space size="middle">
                  <IncredableButton
                    onClick={() => navigate("add")}
                    type="primary"
                    icon={<PlusOutlined />}
                    size="large"
                  >
                    Add Facility Group
                  </IncredableButton>
                </Space>
              </Flex>
              <TableTitle
                filter={TableFilters}
                onReloadClick={UseQueryHookResult.refetch}
                actionDropDownProps={{
                  disabled: !selectedRow,
                  menu: {
                    items: [
                      {
                        label: "Edit",
                        key: "edit",
                        icon: <EditOutlined />,
                      },
                      {
                        label: "Delete",
                        key: "delete",
                        icon: <DeleteOutlined />,
                        danger: true,
                      },
                    ],
                    onClick: async (e) => {
                      if (e?.key === "edit") {
                        navigate(`${selectedRow?.id}/edit`);
                      }
                      if (e?.key === "delete") {
                        handleOpenDeleteModal();
                      }
                    },
                  },
                }}
                onSettingsClick={handleOpenModal}
              />
            </Space>
          )}
        />
        <TablePreferenceModal
          visible={isModalVisible}
          onCancel={handleCloseModal}
          onSave={handleSavePreferences}
          availableColumns={facilitygroupColumns.map(
            (col) => col.key as string
          )}
          selectedColumns={selectedColumns}
          columnOrderState={visibleColumnOrder}
        />
        <DeleteAlertModal
          visible={isDeleteModalOpen}
          action={async () => {
            if (selectedRow?.id) {
              setDeleteModalOpen(false);
              spinnerContext?.showSpinner();
              await deleteFacilityGroup({
                id: selectedRow?.id,
              });
              spinnerContext?.hidespinner();
              toastContext?.openSuccessNotification({
                message: `Facility Group deleted successfully`,
                placement: "topRight",
              });
            }
          }}
          closeModal={() => setDeleteModalOpen(false)}
          header="Delete Facility Group"
          content={
            <Typography.Text>
              Facility Group Name:{" "}
              <Typography.Text>{selectedRow?.name}</Typography.Text>
            </Typography.Text>
          }
          description="Are you sure you want to delete this facility group?"
        />
      </Space>
    </Layout>
  );
}

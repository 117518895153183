import { Col, Divider, Flex, Form, Row, Space, Typography } from "antd";
import { useContext, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import IncredableButton from "../../../components/button";
import IncredableContent from "../../../components/incredable-content";
import RHFTextField from "../../../components/RHF/RHFTextField";
import { SpinnerContext } from "../../../context/spinner/spinner";
import { ToastContext } from "../../../context/toast/incredable-toast";
import { TaskTypeRequest } from "../../../redux/api/task/types";
import {
  useEditTaskTypeMutation,
  useGetAllTaskTypeQuery,
} from "../../../redux/api/task";

type FormInputType = TaskTypeRequest;

export default function EditTaskType() {
  const navigate = useNavigate();
  const { typeId } = useParams();
  const methods = useForm<FormInputType>();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const { data: taskTypes, isLoading } = useGetAllTaskTypeQuery();
  const taskType = taskTypes?.find((type) => type?.id == typeId);
  const location = useLocation();

  const [editTaskType] = useEditTaskTypeMutation();

  useEffect(() => {
    if (taskType) {
      methods.setValue("name", taskType?.name ?? "");
    }
  }, [taskType]);

  return (
    <IncredableContent
      spinning={isLoading}
      breadcrumbProps={{
        items: [
          {
            title: (
              <a
                href={
                  location.pathname.includes("taskstype")
                    ? "/taskstype"
                    : "/templatetype"
                }
              >
                {location.pathname.includes("taskstype")
                  ? "Task Type"
                  : "Template Type"}
              </a>
            ),
          },
          {
            title: "Edit",
          },
        ],
      }}
    >
      <FormProvider {...methods}>
        <Form
          name="addtasktype"
          layout="vertical"
          onFinish={methods.handleSubmit(async (data: FormInputType) => {
            spinnerContext?.showSpinner();
            await editTaskType({
              id: String(typeId),
              ...data,
            });
            toastContext?.openInfoNotification({
              message: `Task Type Edited successfully`,
              placement: "topRight",
            });
            spinnerContext?.hidespinner();
            navigate(-1);
          })}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                color: "#1D1A1F",
                lineHeight: "25px",
                fontWeight: "700",
              }}
            >
              Task Type
            </Typography.Text>
            <Space>
              <IncredableButton
                secondary
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" htmlType="submit">
                Edit Task Type
              </IncredableButton>
            </Space>
          </Flex>
          <Divider />
          <Form.List name="tasktype" initialValue={[{}]}>
            {(fields, { add }) => {
              return (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div key={key}>
                      {index > 0 && <Divider />}
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={12}>
                          <RHFTextField
                            formItemProps={{
                              label: "Type",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `name`,
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}
                </>
              );
            }}
          </Form.List>
        </Form>
      </FormProvider>
    </IncredableContent>
  );
}

import { Col, Divider, Flex, Form, Row, theme, Typography, Space } from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import { ProviderThirdPartyLoginType } from "../../redux/api/provider/types";
import {
  useAddProviderThirdPartyLoginMutation,
  useGetProviderQuery,
} from "../../redux/api/provider/provider";
import IncredableButton from "../../components/button";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { useContext, useEffect } from "react";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import IncredableContent from "../../components/incredable-content";

const { useToken } = theme;

export default function EditProviderThirdPartyLogin() {
  const { providerId, thirdpartyloginId } = useParams();
  const methods = useForm<ProviderThirdPartyLoginType>();

  const {
    data: provider,
    isLoading,
    isFetching,
  } = useGetProviderQuery(!!providerId ? { providerId } : skipToken);
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();
  const { token } = useToken();

  useEffect(() => {
    if (provider && provider.providerThirdPartyLogins) {
      const thirdpartylogin = provider?.providerThirdPartyLogins.find(
        (login) => login?.id + "" === thirdpartyloginId + ""
      );
      if (thirdpartylogin) {
        methods.setValue("id", thirdpartylogin.id);
        methods.setValue("name", thirdpartylogin.name);
        methods.setValue("accountNo", thirdpartylogin.accountNo);
        methods.setValue("username", thirdpartylogin.username);
        methods.setValue("password", thirdpartylogin.password);
      }
    }
  }, [provider, thirdpartyloginId]);

  const [updateProviderThirdPartyLogin] =
    useAddProviderThirdPartyLoginMutation();

  return (
    <IncredableContent spinning={isLoading || isFetching}>
      <FormProvider {...methods}>
        <Form
          name="editproviderthirdpartylogin"
          layout="vertical"
          onFinish={methods.handleSubmit(
            async (data: ProviderThirdPartyLoginType) => {
              if (!!providerId) {
                spinnerContext?.showSpinner();
                await updateProviderThirdPartyLogin({
                  ...data,
                  providerId: providerId,
                });
                spinnerContext?.hidespinner();
                toastContext?.openSuccessNotification({
                  message: `Third Party Login saved successfully`,
                  placement: "topRight",
                });
                navigate(-1);
              }
            }
          )}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                color: "#1D1A1F",
                lineHeight: "25px",
                fontWeight: "700",
              }}
            >
              Third Party Login
            </Typography.Text>
            <Space>
              <IncredableButton
                secondary
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" htmlType="submit">
                Save
              </IncredableButton>
            </Space>
          </Flex>
          <Divider />
          <Form.List name="dea" initialValue={[{}]}>
            {(fields, { add }) => {
              return (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div key={key}>
                      {index > 0 && <Divider />}
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Name",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "name",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Account No",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "accountNo",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Website URL",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "websiteUrl",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Username",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `username`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Password",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `password`,
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}
                </>
              );
            }}
          </Form.List>
        </Form>
      </FormProvider>
    </IncredableContent>
  );
}

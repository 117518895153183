import { useContext, useEffect, useMemo, useState } from "react";
import { Modal, Table, Typography, Space } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  useGetAllCredentialPackageDocumentsQuery,
  useGetCredentialPackageQuery,
  useUpdateCredentialPackageDocumentsMutation,
} from "../../redux/api/credentialpackages/credentialpackages";
import { useGetAllProviderSafeDocumentsQuery } from "../../redux/api/provider/provider";
import IncredableButton from "../../components/button";
import { TablePreferenceModal } from "../../common/TablePreferenceModal";
import { AllDocumentResponse } from "../../redux/api/document/types";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";

type CredentialPackageAddDocumentsModalProps = {
  closeModal: VoidFunction;
  visible: boolean;
};

const columns = [
  {
    title: () => <Typography.Text>Document Name</Typography.Text>,
    key: "name",
    width: "320px",
    ellipsis: true,
    render: (document: AllDocumentResponse) => (
      <Typography.Link href={`manageprovider/${document?.id}`}>
        {document?.name ? `${document.name}` : "-"}
      </Typography.Link>
    ),
    sorter: (a: AllDocumentResponse, b: AllDocumentResponse) =>
      a?.name?.toLowerCase()?.localeCompare(b?.name?.toLowerCase()),
  },
  {
    title: () => <Typography.Text>Category</Typography.Text>,
    dataIndex: ["category", "name"],
    key: "category",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
];

export default function CredentialPackageAddDocumentsModal({
  visible,
  closeModal,
}: CredentialPackageAddDocumentsModalProps) {
  const navigate = useNavigate();
  const [selectedDocuments, setSelectedDocuments] = useState<
    AllDocumentResponse[]
  >([]);
  const [selectedRow, setSelectedRow] = useState<AllDocumentResponse>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const { id: credentialPackageId } = useParams();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    columns.map((col) => col.key as string)
  );
  const [visibleColumnOrder, setVisibleColumnOrder] = useState<string[]>(
    columns.map((col) => col.key as string)
  );

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const [updateCredentialPackageDocuments] =
    useUpdateCredentialPackageDocumentsMutation();

  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  const { data: credentialPackage } = useGetCredentialPackageQuery(
    credentialPackageId ?? skipToken
  );
  const { data: packageDocuments = [] } =
    useGetAllCredentialPackageDocumentsQuery(
      credentialPackageId ? { credentialPackageId } : skipToken
    );

  const { data: documents, isLoading } = useGetAllProviderSafeDocumentsQuery(
    !!credentialPackage?.provider?.id
      ? {
          providerId: credentialPackage?.provider.id,
        }
      : skipToken
  );

  const packageDocumentIdList = useMemo(() => {
    return packageDocuments.map((doc) => doc.id + "");
  }, [packageDocuments]);

  useEffect(() => {
    setSelectedDocuments([]);
  }, [closeModal]);

  const handleSave = async () => {
    if (
      !selectedRowKeys ||
      !credentialPackageId ||
      selectedRowKeys.length === 0
    ) {
      closeModal();
      return;
    }

    try {
      spinnerContext?.showSpinner();

      await updateCredentialPackageDocuments({
        id: credentialPackageId,
        documents: selectedRowKeys.map(String),
      });

      spinnerContext?.hidespinner();

      toastContext?.openSuccessNotification({
        message: `documents updated successfully`,
        placement: "topRight",
      });
      closeModal();
    } catch (error) {
      spinnerContext?.hidespinner();
      toastContext?.openErrorNotification({
        message: `Error updating documents:`,
        placement: "topRight",
      });
    }
  };

  const visibleColumns = visibleColumnOrder
    .filter((colKey) => selectedColumns.includes(colKey))
    .map((colKey) => columns.find((col) => col.key === colKey)!);

  const handleSavePreferences = (
    selectedColumns: string[],
    columnOrder: string[]
  ) => {
    setSelectedColumns(selectedColumns);
    setVisibleColumnOrder(columnOrder);
    handleCloseModal();
  };
  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const onRowClick = ({
    detail,
  }: {
    detail: { item: AllDocumentResponse };
  }) => {
    const temp = selectedDocuments;
    if (!detail.item || packageDocumentIdList.includes(detail.item.id + ""))
      return;
    if (temp.includes(detail.item)) {
      setSelectedDocuments(temp.filter((item) => item !== detail.item));
    } else {
      setSelectedDocuments([detail.item, ...temp]);
    }
  };

  return (
    <Modal
      title="Documents"
      visible={visible}
      onCancel={closeModal}
      footer={[
        <Space>
          <IncredableButton
            secondary
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </IncredableButton>
          <IncredableButton type="primary" onClick={handleSave}>
            Save
          </IncredableButton>
        </Space>,
      ]}
      width={800}
      style={{ maxHeight: "90vh" }}
      styles={{
        body: { overflowY: "auto", maxHeight: "80vh" },
      }}
    >
      <Table
        size="small"
        columns={visibleColumns}
        loading={isLoading}
        rowKey="id"
        dataSource={documents?.documents}
        onRow={(record) => ({
          onClick: () => onRowClick({ detail: { item: record } }),
        })}
        // rowSelection={{
        //   type: "checkbox",
        //   selectedRowKeys: !!selectedRow?.id ? [selectedRow?.id] : [],
        //   onChange: (selectedRowKeys, selectedRows, info) => {
        //     if (!!selectedRows && !!selectedRows?.at(0))
        //       setSelectedRow(selectedRows?.at(0));
        //   },
        //   columnWidth: 60,
        // }}
        rowSelection={{ ...rowSelection, columnWidth: 60 }}
        pagination={{
          position: ["bottomRight"],
          style: { backgroundColor: "white", padding: "10px", margin: "0" },
          pageSizeOptions: [10, 25, 50, 100, 300],
          showSizeChanger: true,
          total: documents?.documents?.length,
          showTotal: (total) => `Total ${total} items`,
        }}
        scroll={{ y: "50vh" }}
      />
      {hasSelected ? `Selected ${selectedRowKeys.length} items` : null}
      <TablePreferenceModal
        visible={isModalVisible}
        onCancel={handleCloseModal}
        onSave={handleSavePreferences}
        availableColumns={columns.map((col) => col.key as string)}
        selectedColumns={selectedColumns}
        columnOrderState={visibleColumnOrder}
      />
    </Modal>
  );
}

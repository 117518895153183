import { useContext, useState } from "react";
import { Flex, Layout, Space, Table, Typography } from "antd";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import TableTitle from "../components/incredable-table/table-title";
import { TablePreferenceModal } from "../common/TablePreferenceModal/";
import IncredableButton from "../components/button";
import { DigitalForm, DigitalFormType } from "../redux/api/digitalform/types";
import useDigitalFormFilter from "../table-filters/digitalform-filterts";
import IncredableDateFormat from "../common/incredable-date-format";
import { SpinnerContext } from "../context/spinner/spinner";
import { ToastContext } from "../context/toast/incredable-toast";
import { useDeleteDigitalFormMutation } from "../redux/api/digitalform/digitalforms";
import { Category } from "../redux/api/category/types";
import DeleteAlertModal from "../common/Modal/delete-alert-modal";

export const columns = [
  {
    title: () => <Typography.Text>Name</Typography.Text>,
    key: "name",
    width: "120px",
    ellipsis: true,
    render: (digitalform: DigitalForm) => (
      <Typography.Link href={`readyforms/${digitalform?.id}`}>
        {digitalform?.name ? `${digitalform.name}` : "-"}
      </Typography.Link>
    ),
    sorter: (a: DigitalForm, b: DigitalForm) =>
      a?.name?.toLowerCase()?.localeCompare(b?.name?.toLowerCase()),
  },
  {
    title: () => <Typography.Text>Type</Typography.Text>,
    dataIndex: "digitalFormType",
    key: "type",
    ellipsis: true,
    render: (type: DigitalFormType) => (
      <Typography.Text>{type?.type || "-"}</Typography.Text>
    ),
    sorter: (a: DigitalForm, b: DigitalForm) =>
      a?.digitalFormType?.type?.toLowerCase()?.localeCompare(b?.digitalFormType?.type?.toLowerCase()),
  },
  {
    title: () => <Typography.Text>Category</Typography.Text>,
    dataIndex: "category",
    key: "category",
    ellipsis: true,
    render: (category: Category) => (
      <Typography.Text>{category?.name || "-"}</Typography.Text>
    ),
  },
  {
    title: () => <Typography.Text>File Name</Typography.Text>,
    key: "attachmentFileName",
    ellipsis: true,
    render: (digitalform: DigitalForm) => (
      <Typography.Text>{digitalform?.template?.name || "-"}</Typography.Text>
    ),
  },
  {
    title: () => <Typography.Text>Date Created</Typography.Text>,
    dataIndex: "createdDate",
    key: "createdDate",
    ellipsis: true,
    render: (createdDate: string) => (
      <Typography.Text>
        {IncredableDateFormat(createdDate) || "-"}
      </Typography.Text>
    ),
    sorter: (a: DigitalForm, b: DigitalForm) =>
      new Date(a?.createdDate).getTime() - new Date(b?.createdDate).getTime()
  },
  {
    title: () => <Typography.Text>Notes</Typography.Text>,
    dataIndex: "description",
    key: "description",
    ellipsis: true,
    render: (notes: string) => (
      <Typography.Text>{notes || "-"}</Typography.Text>
    ),
  },
];

export default function ReadyFormsList() {
  const [selectedRow, setSelectedRow] = useState<DigitalForm>();
  const { TableFilters, filteredReadyForms, UseQueryHookResult } =
    useDigitalFormFilter();
  const [deleteDigitalform] = useDeleteDigitalFormMutation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    columns.map((col) => col.key as string)
  );
  const [visibleColumnOrder, setVisibleColumnOrder] = useState<string[]>(
    columns.map((col) => col.key as string)
  );

  const navigate = useNavigate();

  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  const selectRow = (record: DigitalForm) => {
    if (selectedRow?.id + "" === record?.id + "") {
      setSelectedRow(undefined);
    } else {
      setSelectedRow(record);
    }
  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const handleSavePreferences = (
    selectedColumns: string[],
    columnOrder: string[]
  ) => {
    setSelectedColumns(selectedColumns);
    setVisibleColumnOrder(columnOrder);
    handleCloseModal();
  };

  const visibleColumns = visibleColumnOrder
    .filter((colKey) => selectedColumns.includes(colKey))
    .map((colKey) => columns.find((col) => col.key === colKey)!);

  return (
    <Layout style={{ margin: "20px 30px 0 30px" }}>
      <Space size="large" direction="vertical">
        <Table
          style={{
            boxShadow: "0px 10px 25px 0px #5911920D",
            borderRadius: "16px",
            border: "1px solid #E5E7EB",
            overflow: "hidden",
          }}
          size="small"
          columns={visibleColumns}
          loading={
            UseQueryHookResult.isLoading || UseQueryHookResult.isFetching
          }
          rowKey="id"
          dataSource={filteredReadyForms}
          onRow={(record) => ({
            onClick: () => {
              selectRow(record);
            },
          })}
          rowSelection={{
            type: "radio",
            selectedRowKeys: !!selectedRow?.id ? [selectedRow?.id] : [],
            onChange: (selectedRowKeys, selectedRows, info) => {
              if (!!selectedRows && !!selectedRows?.at(0))
                setSelectedRow(selectedRows?.at(0));
            },
            columnWidth: 60,
          }}
          pagination={{
            position: ["bottomRight"],
            style: { backgroundColor: "white", padding: "10px", margin: "0" },
            pageSizeOptions: [10, 25, 50, 100, 300],
            showSizeChanger: true,
            total: filteredReadyForms?.length,
            showTotal: (total) => `Total ${total} items`,
          }}
          scroll={{ y: "50vh", x: "100vw" }}
          title={() => (
            <Space direction="vertical" style={{ display: "flex" }}>
              <Flex
                align="start"
                justify={"space-between"}
                style={{ margin: "16px 10px 0 10px" }}
              >
                <Space size="middle">
                  <Typography.Title
                    style={{ paddingTop: "0", marginTop: "0" }}
                    level={4}
                  >
                    {`Ready Forms (${filteredReadyForms?.length ?? ""})`}
                  </Typography.Title>
                </Space>
                <Space size="middle">
                  <IncredableButton
                    disabled={!selectedRow?.id}
                    onClick={() => {
                      !!selectedRow?.id &&
                        navigate(`${selectedRow?.id}/editor`);
                    }}
                    size="large"
                    type="primary"
                    style={{
                      backgroundColor: "#598BEB",
                      border: "2px solid #598BEB",
                      boxShadow: "none",
                    }}
                    iconPosition={"end"}
                  >
                    Template Editor
                  </IncredableButton>
                  <IncredableButton
                    disabled={!selectedRow?.id}
                    onClick={() => {
                      !!selectedRow?.id &&
                        navigate(`${selectedRow?.id}/requestsignature`);
                    }}
                    size="large"
                    type="primary"
                    style={{
                      backgroundColor: "#598BEB",
                      border: "2px solid #598BEB",
                      boxShadow: "none",
                    }}
                    iconPosition={"end"}
                  >
                    Request Signature
                  </IncredableButton>
                  <IncredableButton
                    onClick={() => navigate("add")}
                    type="primary"
                    icon={<PlusOutlined />}
                    size="large"
                  >
                    Create Ready Form
                  </IncredableButton>
                </Space>
              </Flex>
              <TableTitle
                filter={TableFilters}
                onReloadClick={UseQueryHookResult.refetch}
                actionDropDownProps={{
                  disabled: !selectedRow,
                  menu: {
                    items: [
                      {
                        label: "Edit",
                        key: "edit",
                        icon: <EditOutlined />,
                      },
                      {
                        label: "Download",
                        key: "download",
                        icon: <DownloadOutlined />,
                      },
                      {
                        label: "Delete",
                        key: "delete",
                        icon: <DeleteOutlined />,
                        danger: true,
                      },
                    ],
                    onClick: async (e) => {
                      if (e?.key === "edit") {
                        !!selectedRow?.id && navigate(`${selectedRow?.id}`);
                      }
                      if (e?.key === "delete") {
                        handleOpenDeleteModal();
                      }
                    },
                  },
                }}
                onSettingsClick={handleOpenModal}
              />
            </Space>
          )}
        />
        <TablePreferenceModal
          visible={isModalVisible}
          onCancel={handleCloseModal}
          onSave={handleSavePreferences}
          availableColumns={columns.map((col) => col.key as string)}
          selectedColumns={selectedColumns}
          columnOrderState={visibleColumnOrder}
        />
        <DeleteAlertModal
          visible={isDeleteModalOpen}
          action={async () => {
            if (selectedRow?.id) {
              setDeleteModalOpen(false);
              spinnerContext?.showSpinner();
              await deleteDigitalform({
                id: selectedRow?.id,
              });
              spinnerContext?.hidespinner();
              toastContext?.openSuccessNotification({
                message: `Ready Form deleted successfully`,
                placement: "topRight",
              });
            }
          }}
          closeModal={() => setDeleteModalOpen(false)}
          header="Delete Ready Form"
          content={
            <Typography.Text>
              Ready Form Name:{" "}
              <Typography.Text strong>{selectedRow?.name}</Typography.Text>
            </Typography.Text>
          }
          description="Are you sure you want to delete this ready form?"
        />
      </Space>
    </Layout>
  );
}

import { Col, Divider, Flex, Form, Row, Typography, Space } from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import RHFSelect from "../../components/RHF/RHFSelect";
import {
  useGetAllFacilityTypesQuery,
  useGetFacilityQuery,
  useUpdateFacilityMutation,
} from "../../redux/api/facility/facility";
import IncredableButton from "../../components/button";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { useContext, useEffect } from "react";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import IncredableContent from "../../components/incredable-content";
import { FacilityRequest } from "../../redux/api/facility/types";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import { Countries, USStatesList } from "../../common/constants";

type FormInputType = FacilityRequest;

export default function EditFacility() {
  const methods = useForm<FormInputType>();
  const { data: facilityType } = useGetAllFacilityTypesQuery();
  const { facilityId } = useParams();
  const {
    data: facility,
    isLoading,
    isFetching,
  } = useGetFacilityQuery(
    !!facilityId
      ? {
          id: facilityId,
        }
      : skipToken
  );
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (facility) {
      methods.setValue("name", facility?.name ?? "");
      methods.setValue("npiNumber", facility?.npiNumber ?? "");
      methods.setValue("notes", facility?.notes ?? "");
      methods.setValue("facilityTypeId", facility?.facilityType?.id ?? "");
      methods.setValue("taxId", facility?.taxId ?? "");
      methods.setValue("cliaId", facility?.cliaId ?? "");
      methods.setValue("immunizationId", facility?.immunizationId ?? "");
      methods.setValue("taxonomyCode", facility?.taxonomyCode ?? "");
      methods.setValue("phoneNumber", facility?.phoneNumber ?? "");
      methods.setValue("email", facility?.email ?? "");
      methods.setValue(
        "facilityAddress.addressLane1",
        facility?.facilityAddress?.addressLane1
      );
      methods.setValue(
        "facilityAddress.addressLane2",
        facility?.facilityAddress?.addressLane2
      );
      methods.setValue(
        "facilityAddress.state",
        facility?.facilityAddress?.state
      );
      methods.setValue("facilityAddress.city", facility?.facilityAddress?.city);
      methods.setValue(
        "facilityAddress.zipcode",
        facility?.facilityAddress?.zipcode
      );
      methods.setValue(
        "facilityAddress.country",
        facility?.facilityAddress?.country
      );
    }
  }, [methods, facility]);

  const [updateFacility] = useUpdateFacilityMutation();

  return (
    <IncredableContent
      spinning={isLoading || isFetching}
      breadcrumbProps={{
        items: [
          {
            title: <a href="/managefacilities">Facility</a>,
          },
          {
            title: "Edit Facility",
          },
        ],
      }}
    >
      <FormProvider {...methods}>
        <Form
          name="addprovider"
          layout="vertical"
          onFinish={methods.handleSubmit(async (data: FormInputType) => {
            if (!!facilityId) {
              spinnerContext?.showSpinner();
              await updateFacility({
                id: facilityId,
                name: data?.name,
                npiNumber: data?.npiNumber,
                description: data?.description,
                facilityTypeId: data?.facilityTypeId,
                taxId: data?.taxId,
                cliaId: data?.cliaId,
                phoneNumber: data?.phoneNumber,
                email: data?.email,
                notes: data?.notes,
                immunizationId: data?.immunizationId,
                taxonomyCode: data?.taxonomyCode,
                facilityAddress: {
                  addressLane1: data?.facilityAddress?.addressLane1,
                  addressLane2: data?.facilityAddress?.addressLane2,
                  city: data?.facilityAddress?.city,
                  state: data?.facilityAddress?.state,
                  zipcode: data?.facilityAddress?.zipcode,
                  country: data?.facilityAddress?.country,
                },
              });
              spinnerContext?.hidespinner();
              toastContext?.openInfoNotification({
                message: `Facility saved successfully`,
                placement: "topRight",
              });
              navigate(-1);
            }
          })}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                color: "#1D1A1F",
                lineHeight: "25px",
                fontWeight: "700",
              }}
            >
              Edit Facility
            </Typography.Text>
            <Space>
              <IncredableButton
                secondary
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" htmlType="submit">
                Save
              </IncredableButton>
            </Space>
          </Flex>
          <Divider />
          <Form.List name="facilities" initialValue={[{}]}>
            {(fields, { add }) => {
              return (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div key={key}>
                      {index > 0 && <Divider />}
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Name",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "name",
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "NPI Number",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `npiNumber`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Facility Type" }}
                            controllerProps={{
                              name: `facilityTypeId`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Search to Select",
                              options: facilityType?.map((type) => {
                                return {
                                  label: type?.name,
                                  value: type?.id,
                                };
                              }),
                              onModifyClick: () => {
                                navigate("/facilitytype");
                              },
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Tax ID",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `taxId`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "CLIA ID",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `cliaId`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Immunization ID",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `immunizationId`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Taxonomy Code",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `taxonomyCode`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Phone Number",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `phoneNumber`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Email",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `email`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextArea
                            formItemProps={{
                              label: "Notes",
                            }}
                            controllerProps={{
                              name: `notes`,
                            }}
                          />
                        </Col>
                      </Row>
                      <Divider>Contact Info</Divider>
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Address Lane 1",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `facilityAddress.addressLane1`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Address Lane 2",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `facilityAddress.addressLane2`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "City",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `facilityAddress.city`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "State" }}
                            controllerProps={{
                              name: `facilityAddress.state`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select state",
                              options: USStatesList?.map((state) => {
                                return {
                                  value: state?.value,
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Country" }}
                            controllerProps={{
                              name: `facilityAddress.country`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select country",
                              options: Countries?.map((country) => {
                                return {
                                  value: country?.value,
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Zip",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `facilityAddress.zipcode`,
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}
                </>
              );
            }}
          </Form.List>
        </Form>
      </FormProvider>
    </IncredableContent>
  );
}

import { Form, Space, Row, Col, Card, ConfigProvider, Breadcrumb } from "antd";
import IncredableButton from "../../components/button";
import RHFTextField from "../../components/RHF/RHFTextField";
import RHFSelect from "../../components/RHF/RHFSelect";
import { useGetAllCategoryQuery } from "../../redux/api/category/category";
import { FormProvider, useForm } from "react-hook-form";
import { DocumentRequest } from "../../redux/api/document/types";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import { useContext, useEffect, useState } from "react";
import AttachmentViewer from "../../components/attachment-viewer";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import { useSaveDocumentMutation } from "../../redux/api/document/document";
import { fileAxios } from "../../context/axios";
import { getFileUrl } from "../../config";
import { captureException } from "@sentry/react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { useGetBasicAllProvidersQuery } from "../../redux/api/provider/provider";
import { alertReminderOptions } from "../../common/constants";

type FormInputType = { providerId: string } & DocumentRequest;
export default function AddDocument() {
  const methods = useForm<FormInputType>();
  const [error, setError] = useState<string>();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  let [searchParams, setSearchParams] = useSearchParams({ providerId: "all" });

  const { data: categories } = useGetAllCategoryQuery();
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [saveDocument] = useSaveDocumentMutation();
  const navigate = useNavigate();
  const { data: providers } = useGetBasicAllProvidersQuery();

  useEffect(() => {
    const providerId = searchParams.get("providerId");
    if (!!providerId) methods.setValue("providerId", providerId);
  }, [searchParams]);

  const breadcrumbProps = [
    {
      title: <NavLink to="/safe">Safe</NavLink>,
    },
    {
      title: "Add",
    },
  ];

  return (
    <div
      style={{
        padding: "12px 24px 12px 24px",
      }}
    >
      <div
        style={{
          padding: "0 0 16px 0",
        }}
      >
        <Breadcrumb items={breadcrumbProps} />
      </div>
      <Row gutter={8}>
        <Col flex="360px">
          <Card title="Add Document">
            <FormProvider {...methods}>
              <ConfigProvider
                theme={{
                  components: {
                    Form: {
                      verticalLabelMargin: -4,
                      itemMarginBottom: 16,
                    },
                  },
                }}
              >
                <Form
                  name="addprovider"
                  layout="vertical"
                  size="middle"
                  onFinish={methods.handleSubmit(
                    async (data: FormInputType) => {
                      console.log({ data });
                      if (!data.providerId) return;
                      if (!uploadedFile) {
                        setError("This field is required");
                      }
                      spinnerContext?.showSpinner();
                      await saveDocument({
                        providerId: data.providerId,
                        document: {
                          name: data.name,
                          expirationDate: data.expirationDate,
                          alertDays: data.alertDays,
                          notes: data.notes,
                          file: uploadedFile,
                          categoryId: data.categoryId,
                        },
                      })
                        .unwrap()
                        .then(async (documentResponse) => {
                          console.log({ uploadedFile, documentResponse });
                          if (
                            !!uploadedFile &&
                            documentResponse?.attachment?.key
                          ) {
                            try {
                              await fileAxios.put(
                                getFileUrl(documentResponse?.attachment?.key),
                                uploadedFile
                              );
                              toastContext?.openSuccessNotification({
                                message: `Document saved successfully`,
                                placement: "topRight",
                              });
                            } catch (error) {
                              captureException(error);
                              toastContext?.openErrorNotification({
                                message: `Failed to save Document`,
                                placement: "topRight",
                              });
                            }
                          } else
                            toastContext?.openSuccessNotification({
                              message: `Document saved successfully`,
                              placement: "topRight",
                            });
                        })
                        .catch((error) => {
                          captureException(error);
                          if (error.status < 500 && error.status >= 400) {
                            toastContext?.openErrorNotification({
                              message: `Failed to save Document`,
                              placement: "topRight",
                            });
                          } else
                            toastContext?.openErrorNotification({
                              message: `Failed to save Document`,
                              placement: "topRight",
                            });
                        });
                      spinnerContext?.hidespinner();
                      navigate(-1);
                    }
                  )}
                  autoComplete="off"
                >
                  <RHFSelect
                    formItemProps={{ label: "Category" }}
                    controllerProps={{
                      name: `categoryId`,
                    }}
                    selectProps={{
                      allowClear: true,
                      showSearch: true,
                      style: { width: "100%" },
                      placeholder: "Search to Category",
                      options: categories?.map((category) => {
                        return {
                          label: category?.name,
                          value: category?.id,
                        };
                      }),
                    }}
                  />

                  <RHFSelect
                    formItemProps={{ label: "Provider" }}
                    controllerProps={{
                      name: `providerId`,
                    }}
                    selectProps={{
                      allowClear: true,
                      showSearch: true,
                      style: { width: "100%" },
                      placeholder: "Select provider",
                      options: providers?.map((provider) => {
                        return {
                          label: provider?.firstName + " " + provider?.lastName,
                          value: provider?.id,
                        };
                      }),
                    }}
                    rules={{ required: "This field is required" }}
                  />

                  <RHFTextField
                    formItemProps={{
                      label: "Document Name",
                    }}
                    inputProps={{}}
                    controllerProps={{
                      name: "name",
                    }}
                    rules={{ required: "This field is required" }}
                  />

                  <RHFDatePicker
                    formItemProps={{ label: "Expiration date" }}
                    controllerProps={{ name: "expirationDate" }}
                    datePickerProps={{}}
                  />

                  <RHFSelect
                    formItemProps={{ label: "Expiration Alert Reminder" }}
                    controllerProps={{
                      name: `alertDays`,
                    }}
                    selectProps={{
                      allowClear: true,
                      showSearch: true,
                      style: { width: "100%" },
                      placeholder: "Search to Expiration Alert Reminder",
                      options: alertReminderOptions?.map((alertdays) => {
                        return {
                          label: alertdays?.label,
                          value: alertdays?.value,
                        };
                      }),
                    }}
                  />

                  <RHFTextArea
                    formItemProps={{ label: "Notes" }}
                    controllerProps={{ name: "notes" }}
                  />

                  <Form.Item>
                    <Space style={{ width: "100%", justifyContent: "end" }}>
                      <IncredableButton
                        secondary
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        Cancel
                      </IncredableButton>
                      <IncredableButton type="primary" htmlType="submit">
                        Save
                      </IncredableButton>
                    </Space>
                  </Form.Item>
                </Form>
              </ConfigProvider>
            </FormProvider>
          </Card>
        </Col>

        <Col flex="auto">
          <AttachmentViewer
            onFileChange={(file) => {
              setUploadedFile(file);
            }}
            file={uploadedFile}
          />
        </Col>
      </Row>
    </div>
  );
}

import {
  Col,
  Divider,
  Flex,
  Form,
  Layout,
  Row,
  Space,
  theme,
  Typography,
} from "antd";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { PayerRequest } from "../../../redux/api/payers/types";
import {
  useGetPayerQuery,
  useUpdatePayerMutation,
} from "../../../redux/api/payers/payers";
import { SpinnerContext } from "../../../context/spinner/spinner";
import { ToastContext } from "../../../context/toast/incredable-toast";
import IncredableButton from "../../../components/button";
import RHFTextField from "../../../components/RHF/RHFTextField";
import RHFPhoneNumber from "../../../components/RHF/RHFPhonenumber";
import { skipToken } from "@reduxjs/toolkit/query";
import RHFSelect from "../../../components/RHF/RHFSelect";
import { Countries, USStatesList } from "../../../common/constants";
import IncredableContent from "../../../components/incredable-content";
import { PayerGroup } from "../../../redux/api/payergroup/types";
import AssoicatedNetworksList from "./associated-network-list";

const { useToken } = theme;
type FormInputType = PayerRequest;

export default function EditPayer() {
  const navigate = useNavigate();
  const methods = useForm<FormInputType>();
  const { payerId } = useParams();
  const { token } = useToken();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const { data: payer } = useGetPayerQuery(
    !!payerId ? { payerId: payerId } : skipToken
  );
  const [associatedNetworks, setAssociatedNetworks] = useState<PayerGroup[]>([]);

  useEffect(() => {
    if (payer) {
      methods.setValue("name", payer?.name ?? "");
      methods.setValue("payerId", payer?.payerId ?? "");
      methods.setValue("website", payer?.website ?? "");
      methods.setValue(
        "payerAddress.contactName",
        payer?.payerAddress?.contactName ?? ""
      );
      methods.setValue(
        "payerAddress.contactEmail",
        payer?.payerAddress?.contactEmail ?? ""
      );
      methods.setValue(
        "payerAddress.contactPhone",
        payer?.payerAddress?.contactPhone ?? ""
      );
      methods.setValue(
        "payerAddress.contactFax",
        payer?.payerAddress?.contactFax ?? ""
      );
      methods.setValue(
        "payerAddress.addressLane1",
        payer?.payerAddress?.addressLane1
      );
      methods.setValue(
        "payerAddress.addressLane2",
        payer?.payerAddress?.addressLane2
      );
      methods.setValue("payerAddress.state", payer?.payerAddress?.state);
      methods.setValue("payerAddress.city", payer?.payerAddress?.city);
      methods.setValue("payerAddress.zipcode", payer?.payerAddress?.zipcode);
      methods.setValue("payerAddress.country", payer?.payerAddress?.country);
      if (!!payer?.payerGroup) setAssociatedNetworks(payer?.payerGroup);
    }
  }, [methods, payer]);

  const [updatePayer] = useUpdatePayerMutation();

  return (
    <>
      <IncredableContent
        breadcrumbProps={{
          items: [
            {
              title: <a href="/payers">Payers</a>,
            },
            {
              title: "Edit",
            },
          ],
        }}
      >
        <FormProvider {...methods}>
          <Form
            name="addfacility"
            layout="vertical"
            onFinish={methods.handleSubmit(async (data: FormInputType) => {
              if (!!payerId)
                await updatePayer({
                  id: payerId,
                  name: data?.name,
                  payerId: data?.payerId,
                  website: data?.website,
                  payerAddress: {
                    addressLane1: data?.payerAddress?.addressLane1,
                    addressLane2: data?.payerAddress?.addressLane2,
                    city: data?.payerAddress?.city,
                    state: data?.payerAddress?.state,
                    country: data?.payerAddress?.country,
                    zipcode: data?.payerAddress?.zipcode,
                    contactEmail: data?.payerAddress?.contactEmail,
                    contactName: data?.payerAddress?.contactName,
                    contactPhone: data?.payerAddress?.contactPhone,
                    contactFax: data?.payerAddress?.contactFax,
                  },
                });
              spinnerContext?.hidespinner();
              toastContext?.openSuccessNotification({
                message: `Payer saved successfully`,
                placement: "topRight",
              });
              navigate(-1);
            })}
            autoComplete="off"
          >
            <Flex gap="small" wrap justify="space-between">
              <Typography.Text
                strong
                style={{
                  fontSize: "22px",
                  color: "#1D1A1F",
                  lineHeight: "25px",
                  fontWeight: "700",
                }}
              >
                Payer
              </Typography.Text>
              <Space>
                <IncredableButton
                  secondary
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Cancel
                </IncredableButton>
                <IncredableButton type="primary" htmlType="submit">
                  Save
                </IncredableButton>
              </Space>
            </Flex>
            <Divider />
            <Form.List name="payers" initialValue={[{}]}>
              {(fields, { add }) => {
                return (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <div key={key}>
                        {index > 0 && <Divider />}
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col span={8}>
                            <RHFTextField
                              formItemProps={{
                                label: "Name",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: `name`,
                              }}
                              rules={{ required: "This field is required" }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFTextField
                              formItemProps={{
                                label: "Payer ID",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: `payerId`,
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFTextField
                              formItemProps={{
                                label: "Website",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: `website`,
                              }}
                            />
                          </Col>
                        </Row>
                        <Divider orientation="center">Contact Info</Divider>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col span={8}>
                            <RHFTextField
                              formItemProps={{
                                label: "Contact Name",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: `payerAddress.contactName`,
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFPhoneNumber
                              formItemProps={{
                                label: "Contact Phone",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: `payerAddress.contactPhone`,
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFTextField
                              formItemProps={{
                                label: "Contact Email",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: `payerAddress.contactEmail`,
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFPhoneNumber
                              formItemProps={{
                                label: "Contact Fax",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: `payerAddress.contactFax`,
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFTextField
                              formItemProps={{
                                label: "Address Lane 1",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: `payerAddress.addressLane1`,
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFTextField
                              formItemProps={{
                                label: "Address Lane 2",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: `payerAddress.addressLane2`,
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFTextField
                              formItemProps={{
                                label: "City",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: `payerAddress.city`,
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFSelect
                              formItemProps={{ label: "State" }}
                              controllerProps={{
                                name: `payerAddress.state`,
                              }}
                              selectProps={{
                                allowClear: true,
                                showSearch: true,
                                style: { width: "100%" },
                                placeholder: "Search to Select",
                                options: USStatesList?.map((state) => {
                                  return {
                                    value: state?.value,
                                  };
                                }),
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFSelect
                              formItemProps={{ label: "Country" }}
                              controllerProps={{
                                name: `payerAddress.country`,
                              }}
                              selectProps={{
                                allowClear: true,
                                showSearch: true,
                                style: { width: "100%" },
                                placeholder: "Search to Select",
                                options: Countries?.map((country) => {
                                  return {
                                    value: country?.value,
                                  };
                                }),
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFTextField
                              formItemProps={{
                                label: "Zip",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: `payerAddress.zipcode`,
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </>
                );
              }}
            </Form.List>
          </Form>
        </FormProvider>
      </IncredableContent>
      <IncredableContent style={{ margin: "20px 30px 0" }}>
        <AssoicatedNetworksList
          associatedNetworks={associatedNetworks}
          setAssociatedNetworks={setAssociatedNetworks}
        />
      </IncredableContent>
    </>
  );
}

import { Tabs } from "antd";
import { useState } from "react";
import Overview from "./providerDetails/overview";
import PersonalInfo from "./providerDetails/personalInfo";
import ProfessionalInfo from "./providerDetails/professionalInfo";
import Affiliations from "./providerDetails/affiliations";
import DEA from "./providerDetails/dea";
import Education from "./providerDetails/education";
import LiabilityInsurance from "./providerDetails/liabilityInsurance";
import License from "./providerDetails/license";
import PeerReference from "./providerDetails/peerreference";
import SpecialityInfo from "./providerDetails/specialityInfo";
import ThirdPartyLogin from "./providerDetails/thirdPartyLogin";
import WorkHistory from "./providerDetails/workHistory";
import EnrolledPayer from "./providerDetails/enrolledPayer";
import IncredableContent from "../components/incredable-content";
import { skipToken } from "@reduxjs/toolkit/query";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useGetProviderQuery } from "../redux/api/provider/provider";

const tabItems = [
  { key: "profile", label: "Overview", component: <Overview /> },
  { key: "personalinfo", label: "Personal Info", component: <PersonalInfo /> },
  {
    key: "professionalinfo",
    label: "Professional Info",
    component: <ProfessionalInfo />,
  },
  { key: "affiliation", label: "Affiliations", component: <Affiliations /> },
  { key: "dea", label: "DEA", component: <DEA /> },
  { key: "education", label: "Education", component: <Education /> },
  {
    key: "liabilityinsurance",
    label: "Liability Insurance",
    component: <LiabilityInsurance />,
  },
  { key: "license", label: "License", component: <License /> },
  {
    key: "peerreference",
    label: "Peer Reference",
    component: <PeerReference />,
  },
  {
    key: "specialtyinfo",
    label: "Specialty Info",
    component: <SpecialityInfo />,
  },
  {
    key: "thirdpartylogin",
    label: "Third Party Login",
    component: <ThirdPartyLogin />,
  },
  { key: "workhistory", label: "Work History", component: <WorkHistory /> },
  {
    key: "enrolledpayer",
    label: "Enrolled Payers",
    component: <EnrolledPayer />,
  },
];

export default function ProviderDetails({ active }: { active: string }) {
  const [activeTabKey, setActiveTabKey] = useState(active);
  const { providerId } = useParams();
  const navigate = useNavigate();

  const { data: selectedProvider } = useGetProviderQuery(
    !!providerId ? { providerId } : skipToken
  );

  const handleTabChange = (key: string) => {
    setActiveTabKey(key);
    navigate(`/manageprovider/${providerId}/${key}`);
  };

  return (
    <IncredableContent
      breadcrumbProps={{
        items: [
          { title: <NavLink to="/manageprovider">Provider</NavLink> },
          {
            title: `${selectedProvider?.firstName || ""} ${
              selectedProvider?.lastName || ""
            }`,
          },
        ],
      }}
    >
      <Tabs
        activeKey={activeTabKey}
        onChange={handleTabChange}
        tabPosition="left"
        type="card"
        tabBarStyle={{ width: "220px" }}
      >
        {tabItems.map(({ key, label, component }) => (
          <Tabs.TabPane
            tab={
              <span style={{ letterSpacing: "0.25px", fontWeight: "600" }}>
                {label}
              </span>
            }
            key={key}
          >
            {component}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </IncredableContent>
  );
}

import { Space, Table, theme, Typography } from "antd";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TableTitle from "../../components/incredable-table/table-title";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { TablePreferenceModal } from "../../common/TablePreferenceModal";
import { ProviderPeerReferenceType } from "../../redux/api/provider/types";
import {
  useDeleteProviderPeerReferenceMutation,
  useGetProviderQuery,
} from "../../redux/api/provider/provider";
import { skipToken } from "@reduxjs/toolkit/query";
import IncredableButton from "../../components/button";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import DeleteAlertModal from "../../common/Modal/delete-alert-modal";

const { useToken } = theme;

export const peerReferenceColumns = [
  {
    title: () => <Typography.Text>Name</Typography.Text>,
    key: "name",
    ellipsis: true,
    render: (peerReference: ProviderPeerReferenceType) => (
      <Typography.Link href="#">{peerReference?.name}</Typography.Link>
    ),
    sorter: (a: ProviderPeerReferenceType, b: ProviderPeerReferenceType) =>
      a?.name?.toLowerCase()?.localeCompare(b?.name?.toLowerCase()),
  },
  {
    title: () => "Degree",
    dataIndex: ["degree", "name"],
    key: "degree",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => "Speciality",
    dataIndex: ["speciality", "name"],
    key: "speciality",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => "Time With Reference Value",
    dataIndex: "timeWithReferenceValue",
    key: "timeWithReferenceValue",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => "Time With Reference Unit",
    dataIndex: "timeWithReferenceUnit",
    key: "timeWithReferenceUnit",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => "Email",
    dataIndex: "email",
    key: "email",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => "Phone",
    dataIndex: "phone",
    key: "phone",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => "Fax",
    dataIndex: "fax",
    key: "fax",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => "Address Lane 1",
    dataIndex: "addressLane1",
    key: "addressLane1",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => "Address Lane 2",
    dataIndex: "addressLane2",
    key: "addressLane2",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => "City",
    dataIndex: "city",
    key: "city",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => "State",
    dataIndex: "state",
    key: "state",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => "Country",
    dataIndex: "country",
    key: "country",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => "Zip",
    dataIndex: "zip",
    key: "zip",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
];

export default function PeerReference() {
  const { providerId } = useParams();
  const [selectedRow, setSelectedRow] = useState<ProviderPeerReferenceType>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    peerReferenceColumns.map((col) => col.key as string)
  );
  const [visibleColumnOrder, setVisibleColumnOrder] = useState<string[]>(
    peerReferenceColumns.map((col) => col.key as string)
  );

  const {
    data: provider,
    isLoading,
    isFetching,
  } = useGetProviderQuery(
    !!providerId
      ? {
        providerId: providerId,
      }
      : skipToken
  );
  const navigate = useNavigate();
  const { token } = useToken();
  const selectRow = (record: ProviderPeerReferenceType) => {
    if (selectedRow?.id + "" === record?.id + "") {
      setSelectedRow(undefined);
    } else {
      setSelectedRow(record);
    }
  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const handleSavePreferences = (
    selectedColumns: string[],
    columnOrder: string[]
  ) => {
    setSelectedColumns(selectedColumns);
    setVisibleColumnOrder(columnOrder);
    handleCloseModal();
  };

  const visibleColumns = visibleColumnOrder
    .filter((colKey) => selectedColumns.includes(colKey))
    .map((colKey) => peerReferenceColumns.find((col) => col.key === colKey)!);

  const [deleteProviderPeerreference] =
    useDeleteProviderPeerReferenceMutation();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  return (
    <>
      <Table
        style={{
          boxShadow: "0px 10px 25px 0px #5911920D",
          borderRadius: "16px",
          border: "1px solid #E5E7EB",
          overflow: "hidden",
        }}
        size="small"
        columns={visibleColumns}
        loading={isLoading || isFetching}
        rowKey="id"
        dataSource={
          !!provider?.providerPeerReferences
            ? [...provider?.providerPeerReferences]
            : []
        }
        onRow={(record) => ({
          onClick: () => {
            selectRow(record);
          },
        })}
        rowSelection={{
          type: "radio",
          selectedRowKeys: !!selectedRow?.id ? [selectedRow?.id] : [],
          onChange: (selectedRowKeys, selectedRows, info) => {
            if (!!selectedRows && !!selectedRows?.at(0))
              setSelectedRow(selectedRows?.at(0));
          },
          columnWidth: 60,
        }}
        pagination={{
          position: ["bottomRight"],
          style: { backgroundColor: "white", padding: "10px", margin: "0" },
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          total: provider?.providerPeerReferences?.length,
          showTotal: (total) => `Total ${total} items`,
        }}
        title={() => (
          <Space
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span style={{ fontSize: "22px", fontWeight: "700" }}>
              {`Peer Reference (${provider?.providerPeerReferences?.length})`}
            </span>
            <Space>
              <IncredableButton
                onClick={() =>
                  navigate(`/manageprovider/${providerId}/peerreference/add`)
                }
                type="primary"
                icon={<PlusOutlined />}
                size="middle"
                style={{
                  backgroundColor: "#598BEB",
                  border: "2px solid #598BEB",
                  boxShadow: "none",
                }}
              >
                Add Peer Reference
              </IncredableButton>
              <TableTitle
                filter={[]}
                actionDropDownProps={{
                  disabled: !selectedRow,
                  menu: {
                    items: [
                      {
                        label: "Edit",
                        key: "edit",
                        icon: <EditOutlined />,
                      },
                      {
                        label: "Delete",
                        key: "delete",
                        icon: <DeleteOutlined />,
                        danger: true,
                      },
                    ],
                    onClick: async (e) => {
                      if (e?.key === "edit") {
                        navigate(`${selectedRow?.id}/edit`);
                      }
                      if (e?.key === "delete") {
                        handleOpenDeleteModal();
                      }
                    },
                  },
                }}
                onSettingsClick={handleOpenModal}
              />
            </Space>
          </Space>
        )}
        scroll={{ y: 400, x: "100vw" }}
      />
      <TablePreferenceModal
        visible={isModalVisible}
        onCancel={handleCloseModal}
        onSave={handleSavePreferences}
        availableColumns={peerReferenceColumns.map((col) => col.key as string)}
        selectedColumns={selectedColumns}
        columnOrderState={visibleColumnOrder}
      />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (!!providerId && !!selectedRow?.id) {
            setDeleteModalOpen(false);
            spinnerContext?.showSpinner();
            await deleteProviderPeerreference({
              providerId: providerId,
              peerReferenceId: selectedRow?.id + "",
            });
            spinnerContext?.hidespinner();
            toastContext?.openSuccessNotification({
              message: `Peer Reference deleted successfully`,
              placement: "topRight",
            });
          }
        }}
        closeModal={() => setDeleteModalOpen(false)}
        header="Delete Peer Reference"
        content={
          <Typography.Text>
            Peer Reference Name:{" "}
            <Typography.Text strong>{selectedRow?.name}</Typography.Text>
          </Typography.Text>
        }
        description="Are you sure you want to delete this peer reference?"
      />
    </>
  );
}

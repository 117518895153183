import { useParams } from "react-router-dom";
import { DigitalForm } from "../../../redux/api/digitalform/types";
import { useDeleteCredentialTemplateCategoriesMutation, useDeleteCredentialTemplateFormsMutation, useGetAllCredentialTemplateCategoriesQuery, useGetAllCredentialTemplateFormsQuery } from "../../../redux/api/credentialpackages/credentialpackages";
import { skipToken } from "@reduxjs/toolkit/query";
import { useState } from "react";
import { Flex, Space, Table, Typography } from "antd";
import IncredableButton from "../../../components/button";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { TableRowSelection } from "antd/es/table/interface";
import AddCategoryModal from "../modals/addcategory-modal";
import { Category } from "../../../redux/api/category/types";
export const categoryColumns = [
    {
        title: () => <Typography.Text>Name</Typography.Text>,
        dataIndex: "name",
        key: "name",
        ellipsis: true,
        render: (text: string) => (
            <Typography.Text strong>{text || "-"}</Typography.Text>
        ),
    },
];

export default function CategoriesList() {
    const { templateId } = useParams();
    const {
        data: categories = [],
        refetch,
        isFetching,
        isLoading,
    } = useGetAllCredentialTemplateCategoriesQuery(
        templateId ? { credentialTemplateId: templateId } : skipToken
    );

    const [addCategoryModalVisible, setAddCategoryModalVisible] =
        useState<boolean>(false);
    const [deleteCredentialTemplateCategory] =
        useDeleteCredentialTemplateCategoriesMutation();

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection: TableRowSelection<Category> = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const selectRow = (record: Category) => {
        const element = selectedRowKeys.find(form => form == record?.id + "")
        if (!element) {
            setSelectedRowKeys([...selectedRowKeys, record?.id])
        }
    };

    return (
        <>
            <Table
                style={{
                    boxShadow: "0px 10px 25px 0px #5911920D",
                    borderRadius: "16px",
                    border: "1px solid #E5E7EB",
                    overflow: "hidden",
                }}
                size="small"
                columns={categoryColumns}
                loading={
                    isLoading || isFetching
                }
                rowKey="id"
                dataSource={!!categories ? [...categories] : []}
                onRow={(record) => ({
                    onClick: () => {
                        selectRow(record)
                    },
                })}
                rowSelection={rowSelection}
                title={() => (
                    <Space direction="vertical" style={{ display: "flex" }}>
                        <Flex
                            align="start"
                            justify={"space-between"}
                            style={{ margin: "16px 10px 0 10px" }}
                        >
                            <Space size="middle">
                                <Typography.Title
                                    style={{ paddingTop: "0", marginTop: "0" }}
                                    level={5}
                                >
                                    {`Categories (${categories?.length})`}
                                </Typography.Title>
                            </Space>
                            <Space size="middle">
                                <IncredableButton
                                    disabled={selectedRowKeys.length === 0}
                                    onClick={() => {
                                        if (!selectedRowKeys || !templateId) return;
                                        const categoriesId = selectedRowKeys.map((form) => form + "");
                                        deleteCredentialTemplateCategory({
                                            credentialTemplateId: templateId,
                                            categories: categoriesId,
                                        });
                                        setSelectedRowKeys([]);
                                    }}
                                    secondary
                                    icon={<MinusOutlined />}
                                    size="middle"
                                >
                                    Remove
                                </IncredableButton>
                                <IncredableButton
                                    onClick={() => { setAddCategoryModalVisible(true); }}
                                    type="primary"
                                    icon={<PlusOutlined />}
                                    size="middle"
                                >
                                    Add Category
                                </IncredableButton>
                            </Space>
                        </Flex>
                    </Space>
                )}
            />
            <AddCategoryModal
                closeModal={() => {
                    setAddCategoryModalVisible(false);
                }}
                visible={addCategoryModalVisible}
            />
        </>
    );
}

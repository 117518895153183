import { SearchOutlined } from "@ant-design/icons";
import { Input, Select, Space } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useGetAllInvitedQuery } from "../redux/api/manageadmin/invitation";

export default function useInvitationsFilter() {
    const [selectedFilterkey, setSelectedFilterKey] = useState<string>("email");
    const [selectedFiltervalue, setSelectedFiltervalue] = useState<
        string | undefined
    >(undefined);

    const {
        data: invitations = [],
        refetch,
        isLoading: invitationsLoading,
        isFetching,
    } = useGetAllInvitedQuery();

    const filteredInvitations = useMemo(() => {
        if (!!selectedFiltervalue) {
            if (selectedFilterkey === "email")
                return invitations?.filter(
                    (invited) =>
                        invited?.email
                            ?.toLowerCase()
                            .includes(selectedFiltervalue?.toLowerCase())
                );
        }
        return invitations;
    }, [selectedFiltervalue, invitations]);

    useEffect(() => {
        setSelectedFiltervalue(undefined);
    }, [selectedFilterkey]);

    const TableFilters = (
        <Space size="small">
            {selectedFilterkey === "email" && (
                <Input
                    onChange={(event) => {
                        setSelectedFiltervalue(event?.target?.value);
                    }}
                    value={selectedFiltervalue}
                    style={{
                        width: "320px",
                    }}
                    placeholder="Find Email"
                    prefix={<SearchOutlined />}
                />
            )}

            <Select
                value={selectedFilterkey}
                onChange={(value) => {
                    setSelectedFilterKey(value);
                }}
                style={{ width: "140px" }}
                options={[
                    { value: "email", label: "Email" }
                ]}
            />
        </Space>
    );

    return {
        TableFilters,
        filteredInvitations,
        UseQueryHookResult: {
            refetch,
            isLoading: invitationsLoading,
            isFetching,
        },
    };
}

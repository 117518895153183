import { useContext, useState } from "react";
import { Flex, Space, Table, Typography } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import TableTitle from "../components/incredable-table/table-title";
import { TablePreferenceModal } from "../common/TablePreferenceModal/";
import { SpinnerContext } from "../context/spinner/spinner";
import { ToastContext } from "../context/toast/incredable-toast";
import DeleteAlertModal from "../common/Modal/delete-alert-modal";
import { MemberType } from "../redux/api/manageadmin/types";
import useActiveMembersFilter from "../table-filters/activemembers-filters";
import { useDeleteAdminMutation } from "../redux/api/manageadmin/manageadmin";

export const activeMembersColumns = [
    {
        title: () => <Typography.Text>First Name</Typography.Text>,
        key: "firstName",
        ellipsis: true,
        render: (member: MemberType) => (
            <Typography.Link href={`manageadmin/${member.id}/edit`}>
                {member?.firstName}
            </Typography.Link>
        ),
        sorter: (a: MemberType, b: MemberType) =>
            a?.firstName?.toLowerCase()?.localeCompare(b?.firstName?.toLowerCase()),
    },
    {
        title: () => <Typography.Text>Last Name</Typography.Text>,
        dataIndex: "lastName",
        key: "lastName",
        ellipsis: true,
        render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
    },
    {
        title: () => <Typography.Text>Email</Typography.Text>,
        dataIndex: "email",
        key: "email",
        ellipsis: true,
        render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
    },
    {
        title: () => <Typography.Text>Role</Typography.Text>,
        key: "role",
        ellipsis: true,
        render: (members: MemberType) => <Typography.Link href={`/settings/roles/${members?.role?.id}`}>{members?.role?.name || "-"}</Typography.Link>,
    },
    {
        title: () => <Typography.Text>Last Login</Typography.Text>,
        dataIndex: "lastLogin",
        key: "lastLogin",
        ellipsis: true,
        render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
    },
];

export default function ActiveList() {
    const [selectedRow, setSelectedRow] = useState<MemberType>();
    const { TableFilters, filteredMembers, UseQueryHookResult } =
        useActiveMembersFilter();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

    const [selectedColumns, setSelectedColumns] =
        useState<string[]>(activeMembersColumns.map((col) => col.key as string));
    const [visibleColumnOrder, setVisibleColumnOrder] = useState<string[]>(
        activeMembersColumns.map((col) => col.key as string)
    );

    const navigate = useNavigate();
    const spinnerContext = useContext(SpinnerContext);
    const toastContext = useContext(ToastContext);

    const selectRow = (record: MemberType) => {
        if (selectedRow?.id + "" === record?.id + "") {
            setSelectedRow(undefined);
        } else {
            setSelectedRow(record);
        }
    };

    const handleOpenModal = () => {
        setIsModalVisible(true);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
    };

    const handleOpenDeleteModal = () => {
        setDeleteModalOpen(true);
    };

    const handleSavePreferences = (
        selectedColumns: string[],
        columnOrder: string[]
    ) => {
        setSelectedColumns(selectedColumns);
        setVisibleColumnOrder(columnOrder);
        handleCloseModal();
    };
    const visibleColumns = visibleColumnOrder
        .filter((colKey) => selectedColumns.includes(colKey))
        .map((colKey) => activeMembersColumns.find((col) => col.key === colKey)!);

    const [deleteMember] = useDeleteAdminMutation();

    return (
        <>
            <Table
                size="small"
                columns={visibleColumns}
                loading={
                    UseQueryHookResult.isLoading || UseQueryHookResult.isFetching
                }
                rowKey="id"
                dataSource={!!filteredMembers ? [...filteredMembers] : []}
                onRow={(record) => ({
                    onClick: () => {
                        selectRow(record);
                    },
                })}
                rowSelection={{
                    type: "radio",
                    selectedRowKeys: !!selectedRow?.id ? [selectedRow?.id] : [],
                    onChange: (selectedRowKeys, selectedRows, info) => {
                        if (!!selectedRows && !!selectedRows?.at(0))
                            setSelectedRow(selectedRows?.at(0));
                    },
                    columnWidth: 60,
                }}
                pagination={{
                    position: ["bottomRight"],
                    style: { backgroundColor: "white", padding: "10px", margin: "0" },
                    pageSizeOptions: [10, 25, 50],
                    showSizeChanger: true,
                    total: filteredMembers?.length,
                    showTotal: (total) => `Total ${total} items`,
                }}
                title={() => (
                    <Space direction="vertical" style={{ display: "flex" }}>
                        <Flex
                            align="start"
                            justify={"space-between"}
                            style={{ margin: "16px 10px 0 10px" }}
                        >
                            <Space size="middle">
                                <Typography.Title
                                    style={{ paddingTop: "0", marginTop: "0" }}
                                    level={4}
                                >
                                    {`Members (${filteredMembers?.length})`}
                                </Typography.Title>
                            </Space>
                            <Space size="middle">

                            </Space>
                        </Flex>
                        <TableTitle
                            filter={TableFilters}
                            onReloadClick={UseQueryHookResult.refetch}
                            actionDropDownProps={{
                                disabled: !(selectedRow && !selectedRow?.role.default),
                                menu: {
                                    items: [
                                        {
                                            label: "Edit",
                                            key: "edit",
                                            icon: <EditOutlined />,
                                        },
                                        {
                                            label: "Delete",
                                            key: "delete",
                                            icon: <DeleteOutlined />,
                                            danger: true,
                                        },
                                    ],
                                    onClick: async (e) => {
                                        if (e?.key === "edit") {
                                            navigate(`${selectedRow?.id}/edit`);
                                        }
                                        if (e?.key === "delete") {
                                            handleOpenDeleteModal();
                                        }
                                    },
                                },
                            }}
                            onSettingsClick={handleOpenModal}
                        />
                    </Space>
                )}
            />
            <TablePreferenceModal
                visible={isModalVisible}
                onCancel={handleCloseModal}
                onSave={handleSavePreferences}
                availableColumns={activeMembersColumns.map((col) => col.key as string)}
                selectedColumns={selectedColumns}
                columnOrderState={visibleColumnOrder}
            />
            <DeleteAlertModal
                visible={isDeleteModalOpen}
                action={async () => {
                    if (selectedRow?.id) {
                        setDeleteModalOpen(false);
                        spinnerContext?.showSpinner();
                        await deleteMember({
                            adminId: selectedRow?.id,
                        });
                        spinnerContext?.hidespinner();
                        toastContext?.openSuccessNotification({
                            message: `Member deleted successfully`,
                            placement: "topRight",
                        });
                    }
                }}
                closeModal={() => setDeleteModalOpen(false)}
                header="Delete Member"
                content={
                    <Typography.Text>
                        Name:{" "}
                        <Typography.Text>{selectedRow?.firstName}</Typography.Text>
                    </Typography.Text>
                }
                description="Are you sure you want to delete this member?"
            />
        </>
    );
}

import { SearchOutlined } from "@ant-design/icons";
import { Input, Select, Space, Tag } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetAllCategoryQuery } from "../redux/api/category/category";
import {
  useDocumentstatsQuery,
  useGetAllDocumentStatsQuery,
  useGetBasicAllProvidersQuery,
} from "../redux/api/provider/provider";
import { useLazyGetAllDocumentQuery } from "../redux/api/document/document";
import { debounce, isNull } from "lodash";
import { skipToken } from "@reduxjs/toolkit/query";
import { useNavigate } from "react-router-dom";

const StatusOptions = [
  { value: "active", label: "Active" },
  { value: "expiring soon", label: "Expiring Soon" },
  { value: "expired", label: "Expired" },
];

export default function useSafeFilter({
  selectedProviderId,
}: {
  selectedProviderId: string | null;
}) {
  const [selectedFilterKey, setSelectedFilterKey] =
    useState<string>("provider");

  const [providerNameFiltervalue, setProviderNameFiltervalue] = useState<
    string | null
  >(selectedProviderId);

  const [documentNameFilterValue, setDocumentNameFilterValue] = useState<
    string | undefined
  >(undefined);

  const [categoryNameFilterValue, setCategoryNameFilterValue] = useState<
    string | undefined
  >(undefined);

  const [statusFilterValue, setStatusFilterValue] = useState<
    string | undefined
  >(undefined);

  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [sortingDetails, setSortingDetails] = useState<{
    sortingField?: string;
    isDescending?: boolean;
  }>({ sortingField: "id", isDescending: false });

  const { data: providers, isLoading: isProviderLoading } =
    useGetBasicAllProvidersQuery();
  const { data: categories, isLoading: categoriesLoading } =
    useGetAllCategoryQuery();

  const { data: allDocumentsQuery } = useGetAllDocumentStatsQuery();
  const { data: providerDocumentsStats } = useDocumentstatsQuery(
    providerNameFiltervalue
      ? { providerID: providerNameFiltervalue }
      : skipToken
  );

  const documentStats = useMemo(() => {
    return providerNameFiltervalue ? providerDocumentsStats : allDocumentsQuery;
  }, [providerNameFiltervalue, providerDocumentsStats, allDocumentsQuery]);

  const [triggerEnrollment, { data: documents, isFetching }] =
    useLazyGetAllDocumentQuery();

  const fullName = useMemo(() => {
    const provider = providers?.find(
      (provider) => provider.id === providerNameFiltervalue
    );
    return `${provider?.firstName || ""} ${provider?.lastName || ""}`;
  }, [providers, providerNameFiltervalue]);

  useEffect(() => {
    setPageNumber(0);
  }, [
    providerNameFiltervalue,
    categoryNameFilterValue,
    documentNameFilterValue,
    statusFilterValue,
  ]);

  const debouncedTriggerEnrollment = useCallback(
    debounce((filters) => {
      triggerEnrollment(filters);
    }, 1200),
    [triggerEnrollment]
  );

  useEffect(() => {
    return () => {
      debouncedTriggerEnrollment.cancel();
    };
  }, [debouncedTriggerEnrollment]);

  const reload = () => {
    debouncedTriggerEnrollment({
      pageNumber,
      pageSize,
      providerId: providerNameFiltervalue,
      categoryId: categoryNameFilterValue,
      name: documentNameFilterValue,
      status: statusFilterValue,
      sortBy: sortingDetails?.sortingField,
      direction: sortingDetails?.isDescending ? "DSC" : "ASC",
    });
  };

  useEffect(() => {
    debouncedTriggerEnrollment({
      pageNumber,
      pageSize,
      providerId: providerNameFiltervalue,
      categoryId: categoryNameFilterValue,
      name: documentNameFilterValue,
      status: statusFilterValue,
      sortBy: sortingDetails?.sortingField,
      direction: sortingDetails?.isDescending ? "DSC" : "ASC",
    });
  }, [
    pageNumber,
    pageSize,
    providerNameFiltervalue,
    categoryNameFilterValue,
    documentNameFilterValue,
    statusFilterValue,
    sortingDetails,
    debouncedTriggerEnrollment,
  ]);

  useEffect(() => {
    if (providerNameFiltervalue) {
      navigate(`?providerId=${providerNameFiltervalue}`, { replace: true });
    }
  }, [providerNameFiltervalue, navigate]);

  const TableFilters = (
    <Space direction="vertical">
      <Space size="small">
        <Select
          value={selectedFilterKey}
          onChange={(value) => setSelectedFilterKey(value)}
          style={{ width: "160px" }}
          options={[
            { value: "provider", label: "Provider" },
            { value: "documentName", label: "Document Name" },
            { value: "category", label: "Category" },
            { value: "status", label: "Status" },
          ]}
        />
        {selectedFilterKey === "documentName" && (
          <Input
            onChange={(event) => {
              setDocumentNameFilterValue(event?.target?.value);
            }}
            value={documentNameFilterValue}
            style={{ width: "320px" }}
            placeholder="Find Document Name"
            prefix={<SearchOutlined />}
          />
        )}

        {selectedFilterKey === "provider" && (
          <Select
            key="provider-select"
            placeholder="Select Provider"
            allowClear
            showSearch={true}
            optionFilterProp="label"
            onClear={() => {
              navigate(``, {
                replace: true,
              });
            }}
            value={
              isNull(selectedProviderId)
                ? providerNameFiltervalue
                : `${fullName}`
            }
            onChange={(value) => {
              setProviderNameFiltervalue(value);
            }}
            style={{ width: "320px" }}
            options={providers?.map((provider) => ({
              label: `${provider.firstName} ${provider.lastName}`,
              searchValue: provider.firstName,
              value: provider.id,
            }))}
          />
        )}
        {selectedFilterKey === "category" && (
          <Select
            allowClear
            showSearch
            optionFilterProp="label"
            value={categoryNameFilterValue}
            placeholder="Search by Category"
            onChange={(value) => setCategoryNameFilterValue(value)}
            style={{ width: "320px" }}
            options={categories?.map((category) => ({
              label: category?.name,
              searchValue: category?.name,
              value: category?.id + "",
            }))}
          />
        )}
        {selectedFilterKey === "status" && (
          <Select
            allowClear
            showSearch
            optionFilterProp="label"
            value={statusFilterValue}
            placeholder="Search By Status"
            onChange={(value) => setStatusFilterValue(value)}
            style={{ width: "320px" }}
            options={StatusOptions}
          />
        )}
      </Space>
      <div>
        {!!providerNameFiltervalue && (
          <Tag
            closeIcon
            onClose={() => {
              setProviderNameFiltervalue(null);
              navigate(``, {
                replace: true,
              });
            }}
          >
            <span>
              Provider ={" "}
              {
                providers?.find(
                  (stautsOption) =>
                    stautsOption?.id + "" === providerNameFiltervalue + ""
                )?.firstName
              }
            </span>
          </Tag>
        )}
        {!!categoryNameFilterValue && (
          <Tag
            closeIcon
            onClose={() => {
              setCategoryNameFilterValue(undefined);
            }}
          >
            <span>
              Category ={" "}
              {
                categories?.find(
                  (category) =>
                    category?.id + "" === categoryNameFilterValue + ""
                )?.name
              }
            </span>
          </Tag>
        )}
        {!!statusFilterValue && (
          <Tag
            closeIcon
            onClose={() => {
              setStatusFilterValue(undefined);
            }}
          >
            <span>
              Status ={" "}
              {
                StatusOptions?.find(
                  (status) => status?.value === statusFilterValue
                )?.label
              }
            </span>
          </Tag>
        )}
      </div>
    </Space>
  );

  return {
    TableFilters,
    filteredDocuments: documents?.documents || [],
    documentsTotalRecords: documents?.totalRecords || 0,
    documentStats,
    UseQueryHookResult: {
      reload,
      isLoading: categoriesLoading,
      isFetching,
      pageNumber,
      setPageNumber,
      setPageSize,
      pageSize,
      sortingDetails,
      setSortingDetails,
    },
  };
}

import { useContext, useEffect, useState } from "react";
import { Button, ConfigProvider, Dropdown, Space, Tag, Typography } from "antd";
import { Provider } from "../redux/api/provider/types";
import { Header, Pagination, Table } from "@cloudscape-design/components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";
import { useLocalStorage } from "../common/localStorage";
import {
  PAYERENROLLMENT_CONTENT_DISPLAY_OPTIONS,
  PAYERENROLLMENT_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "./table-config";
import { PayerEnrollment } from "../redux/api/payerenrollment/types";
import usePayerEnrollmentFilter from "../table-filters/payerenrollment-filters";
import { useDeletePayerEnrollmentMutation } from "../redux/api/payerenrollment/payerenrollment";
import IncredableDateFormat from "../common/incredable-date-format";
import DeleteAlertModal from "../common/Modal/delete-alert-modal";
import { SpinnerContext } from "../context/spinner/spinner";
import { ToastContext } from "../context/toast/incredable-toast";

export const columns = [
  {
    title: () => <Typography.Text>Profile</Typography.Text>,
    key: "profile",
    width: "200px",
    ellipsis: true,
    render: (provider: Provider) => (
      <Typography.Link href={`manageprovider/${provider?.id}`}>
        {provider?.firstName
          ? `${provider.firstName} ${provider.lastName}`
          : "-"}
      </Typography.Link>
    ),
    sorter: (a: Provider, b: Provider) =>
      a?.firstName?.toLowerCase()?.localeCompare(b?.firstName?.toLowerCase()),
  },
  {
    title: () => <Typography.Text>Last Name</Typography.Text>,
    dataIndex: "lastName",
    key: "lastName",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>First Name</Typography.Text>,
    dataIndex: "firstName",
    key: "firstName",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>NPI Number</Typography.Text>,
    dataIndex: "npiNumber",
    key: "npiNumber",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Email Address</Typography.Text>,
    dataIndex: "email",
    key: "emailAddress",
    width: "250px",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>License Type</Typography.Text>,
    dataIndex: ["licenseType", "name"],
    key: "licenseType",
    width: "230px",
    ellipsis: true,
    render: (text: string) => (
      <Tag>
        <Typography.Text>{text || "-"}</Typography.Text>
      </Tag>
    ),
  },
  {
    title: () => <Typography.Text>Facility Name</Typography.Text>,
    dataIndex: ["facility", "name"],
    key: "facilityName",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
];

export default function PayerEnrollmentListV2() {
  const [selectedRows, setSelectedRows] = useState<PayerEnrollment[]>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const {
    TableFilters,
    filteredPayerEnrollments = [],
    enrollmentsTotalRecords,
    UseQueryHookResult: {
      reload,
      setPageNumber,
      setPageSize,
      pageNumber,
      pageSize,
      isFetching,
      sortingDetails,
      setSortingDetails,
    },
  } = usePayerEnrollmentFilter();

  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-PayerEnrollment-Table-Preferences",
    PAYERENROLLMENT_TABLE_DEFAULT_PREFERENCES
  );

  const navigate = useNavigate();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  const [deletePayerEnrollment] = useDeletePayerEnrollmentMutation();

  useEffect(() => {
    if (tablePreferences?.pageSize) setPageSize(tablePreferences.pageSize);
  }, [tablePreferences, setPageSize]);

  const { items, collectionProps, paginationProps } = useCollection(
    filteredPayerEnrollments,
    {
      pagination: { pageSize },
    }
  );

  return (
    <div style={{ padding: "32px 32px 0 32px" }}>
      <Table
        loading={isFetching}
        onSelectionChange={({ detail }) => {
          setSelectedRows([...detail.selectedItems]);
        }}
        onRowClick={(onRowClickDetails) => {
          const selectedItem = onRowClickDetails?.detail?.item;
          if (selectedItem) {
            const temp = selectedRows ? [...selectedRows] : [];
            const selectedItemIndex = temp.findIndex(
              (record) => record?.id + "" === "" + selectedItem?.id
            );
            if (selectedItemIndex !== -1) {
              temp?.splice(selectedItemIndex, 1);
              setSelectedRows(temp);
            } else {
              setSelectedRows([...temp, selectedItem]);
            }
          }
        }}
        selectedItems={selectedRows || []}
        sortingColumn={{ sortingField: sortingDetails?.sortingField }}
        sortingDescending={sortingDetails?.isDescending}
        onSortingChange={({ detail }) => {
          setSortingDetails({
            sortingField: detail?.sortingColumn?.sortingField,
            isDescending: detail?.isDescending,
          });
        }}
        columnDefinitions={[
          {
            id: "id",
            header: "ID",
            cell: (item) => item?.id,
            sortingField: "id",
          },
          {
            id: "network",
            header: "Network",
            cell: (item) => item?.payerGroup?.name,
            sortingField: "payerGroup.name",
            sortingComparator: (a, b) =>
              (a?.payerGroup?.name ?? "").localeCompare(
                b?.payerGroup?.name ?? ""
              ),
          },
          {
            id: "payer",
            header: "Payer Name",
            cell: (item) => (
              <NavLink
                to={`/payer/${item?.payer?.id
                  }/payerenrollment/${item?.id
                  }/edit `}
              >{item?.payer?.name}</NavLink>
            ),
            sortingField: "payer.name",
            isRowHeader: true,
          },
          {
            id: "status",
            header: "Status",
            cell: (item) => <span>{item?.status?.name ?? "-"}</span>,
            sortingField: "status.name",
            sortingComparator: (a, b) =>
              (a?.status?.name ?? "").localeCompare(b?.status?.name ?? ""),
          },
          {
            id: "payerProviderId",
            header: "Payer Provider ID",
            cell: (item) => <div>{item?.payerProviderId}</div>,
            sortingField: "payerProviderId",
            sortingComparator: (a, b) =>
              (a?.payerProviderId ?? "").localeCompare(
                b?.payerProviderId ?? ""
              ),
          },
          {
            id: "provider",
            header: "Provider",
            cell: (item) => (
              <span>
                {(item?.provider &&
                  item?.provider?.firstName + " " + item?.provider?.lastName) ??
                  "-"}
              </span>
            ),
            sortingField: "provider.firstName",
            sortingComparator: (a, b) =>
              (a?.provider?.firstName ?? "").localeCompare(
                b?.provider?.firstName ?? ""
              ),
          },
          {
            id: "facility",
            header: "Facility",
            cell: (item) => <span>{item?.facility?.name ?? "-"}</span>,
            sortingField: "facility.name",
            sortingComparator: (a, b) =>
              (a?.facility?.name ?? "").localeCompare(b?.facility?.name ?? ""),
          },
          {
            id: "facilityGroup",
            header: "Facility Group",
            cell: (item) => <span>{item?.facilityGroup?.name ?? "-"}</span>,
            sortingField: "facilityGroup.name",
            sortingComparator: (a, b) =>
              (a?.facilityGroup?.name ?? "").localeCompare(b?.facilityGroup?.name ?? ""),
          },
          {
            id: "submittedDate",
            header: "Submitted Date",
            cell: (item) => (
              <span>{IncredableDateFormat(item?.submittedDate) ?? "-"}</span>
            ),
          },
          {
            id: "approvedDate",
            header: "Approved Date",
            cell: (item) => (
              <span>{IncredableDateFormat(item?.approvedDate) ?? "-"}</span>
            ),
          },
          {
            id: "effectiveDate",
            header: "Effective Date",
            cell: (item) => (
              <span>{IncredableDateFormat(item?.effectiveDate) ?? "-"}</span>
            ),
          },
          {
            id: "recredentialingDate",
            header: "Recredentialing Date",
            cell: (item) => (
              <span>
                {IncredableDateFormat(item?.recredentialingDate) ?? "-"}
              </span>
            ),
          },
          {
            id: "followupDate",
            header: "Followup Date",
            cell: (item) => (
              <span>
                {item?.alertDays > 0 ? `${item?.alertDays} days` : "-"}
              </span>
            ),
          },
          {
            id: "description",
            header: "Description",
            cell: (item) => <span>{item?.description ?? "-"}</span>,
          },
          {
            id: "workflow",
            header: "Workflow",
            cell: (item) => <span>{item?.workflow?.name ?? "-"}</span>,
          },
        ]}
        items={items}
        pagination={
          <Pagination
            {...paginationProps}
            currentPageIndex={pageNumber + 1}
            onChange={({ detail }) => {
              setPageNumber(detail.currentPageIndex - 1);
            }}
            pagesCount={
              !!tablePreferences?.pageSize && tablePreferences?.pageSize !== 0
                ? Math.ceil(
                  enrollmentsTotalRecords / tablePreferences?.pageSize
                )
                : 1
            }
          />
        }
        preferences={
          <TablePreferences
            preferences={tablePreferences}
            setPreferences={(preferences) => {
              if (preferences.pageSize !== tablePreferences.pageSize) {
                preferences.pageSize && setPageSize(preferences.pageSize);
              }
              setTablePreferences(preferences);
            }}
            contentDisplayOptions={PAYERENROLLMENT_CONTENT_DISPLAY_OPTIONS}
          />
        }
        loadingText="Loading resources"
        selectionType="multi"
        trackBy="id"
        filter={TableFilters}
        header={
          <div style={{ marginTop: "8px", marginBottom: "12px" }}>
            <Header
              actions={
                <Space size="middle">
                  <Button
                    onClick={() => {
                      !!reload && reload();
                    }}
                  >
                    <ReloadOutlined />
                  </Button>
                  <Dropdown
                    menu={{
                      items: [
                        {
                          label: "Edit",
                          key: "edit",
                          disabled: selectedRows?.length !== 1,
                          icon: <EditOutlined />,
                          onClick: () => {
                            navigate(
                              `/payer/${selectedRows?.at(0)?.payer.id
                              }/payerenrollment/${selectedRows?.at(0)?.id
                              }/edit  `
                            );
                          },
                        },
                        {
                          label: "Delete",
                          key: "delete",
                          onClick: () => setIsDeleteModalOpen(true),
                          disabled: selectedRows?.length !== 1,
                          icon: <DeleteOutlined />,
                          danger: true,
                        },
                      ],
                    }}
                  >
                    <Button>
                      Actions
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: "#2B6BE6",
                      },
                    }}
                  >
                    <Button
                      onClick={() => navigate(`add`)}
                      type="primary"
                      icon={<PlusOutlined />}
                    >
                      Add Payer Enrollment
                    </Button>
                  </ConfigProvider>
                </Space>
              }
            >
              <Typography.Title level={3} style={{ marginTop: "0" }}>
                Enrollments{" "}
                <Typography.Text
                  type="secondary"
                  style={{ fontSize: "16px" }}
                >{`(${enrollmentsTotalRecords})`}</Typography.Text>
              </Typography.Title>
            </Header>
          </div>
        }
        {...collectionProps}
        columnDisplay={tablePreferences?.contentDisplay}
        wrapLines={tablePreferences?.wrapLines}
        stripedRows={tablePreferences?.stripedRows}
        contentDensity={tablePreferences?.contentDensity}
        stickyColumns={tablePreferences?.stickyColumns}
      />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedRows?.at(0)?.id) {
            setIsDeleteModalOpen(false);
            spinnerContext?.showSpinner();
            await deletePayerEnrollment({
              payerEnrollmentId: String(selectedRows?.at(0)?.id),
            });
            spinnerContext?.hidespinner();
            toastContext?.openSuccessNotification({
              message: `Payer Enrollment deleted successfully`,
              placement: "topRight",
            });
          }
        }}
        closeModal={() => setIsDeleteModalOpen(false)}
        header="Delete Enrollment"
        content={
          <>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography.Text>Payer Name</Typography.Text>
              <Typography.Text strong style={{ marginTop: "11px" }}>
                {selectedRows?.at(0)?.payer?.name}
              </Typography.Text>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography.Text>Provider Name</Typography.Text>
              <Typography.Text strong style={{ marginTop: "11px" }}>
                {selectedRows?.at(0)?.provider?.firstName +
                  " " +
                  selectedRows?.at(0)?.provider?.lastName}
              </Typography.Text>
            </div>
          </>
        }
        description="Are you sure you want to delete?"
      />
    </div>
  );
}

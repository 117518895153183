import { Col, Divider, Flex, Form, Row, Typography, Space } from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import { AffiliationStatus, ProviderAffiliationTypeRequest } from "../../redux/api/provider/types";
import RHFSelect from "../../components/RHF/RHFSelect";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";
import {
  useAddProviderAffiliationMutation,
  useGetAllAffiliationStatusQuery,
  useGetProviderQuery,
} from "../../redux/api/provider/provider";
import IncredableButton from "../../components/button";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import { useGetAllAffiliationTypeQuery } from "../../redux/api/affiliationType/affiliation";
import IncredableContent from "../../components/incredable-content";
import RHFPhoneNumber from "../../components/RHF/RHFPhonenumber";
import EditAffiliationTypeModal from "./modals/edit-affiliation-type-modal";
import { PATH_DASHBOARD } from "../../routes/path";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import { alertReminderOptions } from "../../common/constants";

export default function EditProviderAffiliation() {
  const { providerId, affiliationId } = useParams();
  const methods = useForm<ProviderAffiliationTypeRequest>();
  const { data: facilities } = useGetFacilitiesQuery();
  const { data: affiliationStatus } = useGetAllAffiliationStatusQuery();
  const { data: affiliationType } = useGetAllAffiliationTypeQuery();
  const [isEditAffiliationTypeModalOpen, setisEditAffiliationTypeModalOpen] =
    useState<boolean>(false);

  const {
    data: provider,
    isFetching,
    isLoading,
  } = useGetProviderQuery(!!providerId ? { providerId } : skipToken);
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (provider && provider.providerAffiliations) {
      const affiliation = provider.providerAffiliations.find(
        (affiliation) => affiliation?.id + "" === affiliationId + ""
      );
      if (affiliation) {
        methods.setValue("id", affiliation.id);
        methods.setValue("name", affiliation?.name);
        methods.setValue("startDate", affiliation?.startDate);
        methods.setValue("endDate", affiliation?.endDate);
        methods.setValue("facilityId", affiliation?.facility?.id);
        methods.setValue("isCurrent", affiliation?.isCurrent);
        methods.setValue("affiliationTypeId", affiliation?.affiliationType?.id);
        methods.setValue(
          "affiliationStatusId",
          affiliation?.affiliationStatus?.id
        );
        methods.setValue("email", affiliation?.email);
        methods.setValue("phone", affiliation?.phone);
        methods.setValue("fax", affiliation?.fax);
      }
    }
  }, [provider, affiliationId]);

  const [updateProviderAffiliation] = useAddProviderAffiliationMutation();

  return (
    <IncredableContent spinning={isLoading || isFetching}>
      <FormProvider {...methods}>
        <Form
          name="editprovideraffiliation"
          layout="vertical"
          onFinish={methods.handleSubmit(
            async (data: ProviderAffiliationTypeRequest) => {
              if (!!providerId) {
                spinnerContext?.showSpinner();
                await updateProviderAffiliation({
                  ...data,
                  providerId: providerId,
                });
                spinnerContext?.hidespinner();
                toastContext?.openSuccessNotification({
                  message: `Affiliation saved successfully`,
                  placement: "topRight",
                });
                navigate(-1);
              }
            }
          )}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                color: "#1D1A1F",
                lineHeight: "25px",
                fontWeight: "700",
              }}
            >
              Affiliation
            </Typography.Text>
            <Space>
              <IncredableButton secondary onClick={() => navigate(-1)}>
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" htmlType="submit">
                Save
              </IncredableButton>
            </Space>
          </Flex>
          <Divider />
          <Form.List name="affiliations" initialValue={[{}]}>
            {(fields, { add }) => {
              return (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div key={key}>
                      {index > 0 && <Divider />}
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Affiliation Type" }}
                            controllerProps={{ name: "affiliationTypeId" }}
                            selectProps={{
                              style: { width: "100%" },
                              placeholder: "Select Affiliation Type",
                              options: affiliationType?.map((type) => ({
                                label: type.name,
                                value: type.id,
                              })),
                              onModifyClick: () => {
                                navigate("/affiliationtype");
                              },
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Affiliation Status" }}
                            controllerProps={{ name: "affiliationStatusId" }}
                            selectProps={{
                              style: { width: "100%" },
                              placeholder: "Select Affiliation Status",
                              options: affiliationStatus?.map(
                                (status: AffiliationStatus) => ({
                                  label: status.name,
                                  value: status.id,
                                })
                              ),
                              onModifyClick: () => {
                                navigate("/affiliationstatus");
                              },
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Facility" }}
                            controllerProps={{ name: "facilityId" }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Search to Select",
                              options: facilities?.map((facility) => ({
                                label: facility.name,
                                value: facility.id,
                              })),
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{ label: "Start Date" }}
                            controllerProps={{ name: "startDate" }}
                            datePickerProps={{
                              style: { width: "100%" },
                              placeholder: "Select Start Date",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{ label: "Due Date" }}
                            controllerProps={{ name: "endDate" }}
                            datePickerProps={{
                              style: { width: "100%" },
                              placeholder: "Select Due Date",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Expiration Reminder" }}
                            controllerProps={{ name: "alertDays" }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Search to Select",
                              options: alertReminderOptions,
                            }}
                          />
                        </Col>
                      </Row>

                      <Divider orientation="left">Contact Info</Divider>

                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={12}>
                          <RHFTextField
                            formItemProps={{ label: "Name" }}
                            controllerProps={{ name: "name" }}
                            inputProps={{}}
                          />
                        </Col>
                        <Col span={12}>
                          <RHFTextField
                            formItemProps={{ label: "Email" }}
                            controllerProps={{ name: "email" }}
                            inputProps={{}}
                          />
                        </Col>
                        <Col span={12}>
                          <RHFTextField
                            formItemProps={{ label: "Phone" }}
                            controllerProps={{ name: "phone" }}
                            inputProps={{}}
                          />
                        </Col>
                        <Col span={12}>
                          <RHFTextField
                            formItemProps={{ label: "Fax" }}
                            controllerProps={{ name: "fax" }}
                            inputProps={{}}
                          />
                        </Col>
                        <Col span={24}>
                          <RHFTextArea
                            formItemProps={{ label: "Notes" }}
                            controllerProps={{ name: "notes" }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}
                </>
              );
            }}
          </Form.List>
        </Form>
      </FormProvider>
    </IncredableContent>
  );
}

import {
  Col,
  Divider,
  Flex,
  Form,
  Row,
  theme,
  Typography,
  Space,
  Checkbox,
} from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import RHFSelect from "../../components/RHF/RHFSelect";
import {
  useAddProviderPersonalInfoMutation,
  useGetAllDegreeQuery,
  useGetProviderQuery,
} from "../../redux/api/provider/provider";
import IncredableButton from "../../components/button";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import IncredableContent from "../../components/incredable-content";
import { PersonalInfoRequestType } from "../../redux/api/provider/types";
import RHFPhoneNumber from "../../components/RHF/RHFPhonenumber";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import RHFCheckBox from "../../components/RHF/RHFCheckbox";
import type { CheckboxProps } from "antd";

const { useToken } = theme;

export default function EditProviderPersonalInfo() {
  const { providerId } = useParams();
  const methods = useForm<PersonalInfoRequestType>();
  const { data: degree } = useGetAllDegreeQuery();
  const [hasOtherName, setHasOtherName] = useState<boolean>(false);
  useEffect(() => {
    if (hasOtherName === false) methods.resetField("otherName");
  }, [hasOtherName]);
  const {
    data: provider,
    isLoading,
    isFetching,
  } = useGetProviderQuery(!!providerId ? { providerId } : skipToken);
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();
  const { token } = useToken();
  const gender = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Others", value: "Others" },
  ];
  const onChangeHasOtherName: CheckboxProps["onChange"] = (e) => {
    setHasOtherName(e.target.checked);
  };

  useEffect(() => {
    if (provider && provider?.personalInfo) {
      const providerPersonalInfo = provider?.personalInfo;
      if (providerPersonalInfo) {
        setHasOtherName(providerPersonalInfo?.hasOtherName);
        methods.setValue("id", providerPersonalInfo?.id);
        methods.setValue("middleName", providerPersonalInfo?.middleName);
        methods.setValue("degreeId", providerPersonalInfo?.degree?.id);
        methods.setValue("hasOtherName", providerPersonalInfo?.hasOtherName);
        methods.setValue("otherName", providerPersonalInfo?.otherName);
        methods.setValue("gender", providerPersonalInfo?.gender);
        methods.setValue("dob", providerPersonalInfo?.dob);
        methods.setValue("placeOfBirth", providerPersonalInfo?.placeOfBirth);
        methods.setValue("ssn", providerPersonalInfo?.ssn);
        methods.setValue("citizenship", providerPersonalInfo?.citizenship);
        methods.setValue("passportNo", providerPersonalInfo?.passportNo);
        methods.setValue("email", providerPersonalInfo?.email);
        methods.setValue("mobile", providerPersonalInfo?.mobile);
        methods.setValue("homePhone", providerPersonalInfo?.homePhone);
        methods.setValue("fax", providerPersonalInfo?.fax);
        methods.setValue(
          "hasWorkedInMilitary",
          providerPersonalInfo?.hasWorkedInMilitary
        );
        methods.setValue(
          "branchOfMilitary",
          providerPersonalInfo?.branchOfMilitary
        );
        methods.setValue(
          "militaryDateOfService",
          providerPersonalInfo?.militaryDateOfService
        );
        methods.setValue("addressLane1", providerPersonalInfo?.addressLane1);
        methods.setValue("addressLane2", providerPersonalInfo?.addressLane2);
        methods.setValue("city", providerPersonalInfo?.city);
        methods.setValue("state", providerPersonalInfo?.state);
        methods.setValue("zipcode", providerPersonalInfo?.zipcode);
        methods.setValue("county", providerPersonalInfo?.county);
        methods.setValue("country", providerPersonalInfo?.country);
      }
    }
  }, [provider]);

  const [updateProviderPersonalInfo] = useAddProviderPersonalInfoMutation();

  return (
    <IncredableContent spinning={isLoading || isFetching}>
      <FormProvider {...methods}>
        <Form
          name="editproviderpersonalinfo"
          layout="vertical"
          onFinish={methods.handleSubmit(
            async (data: PersonalInfoRequestType) => {
              if (!!providerId) {
                spinnerContext?.showSpinner();
                await updateProviderPersonalInfo({
                  ...data,
                  providerId: providerId,
                  hasOtherName: hasOtherName,
                });
                spinnerContext?.hidespinner();
                toastContext?.openSuccessNotification({
                  message: `Personal Info saved successfully`,
                  placement: "topRight",
                });
              }
              navigate(-1);
            }
          )}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                color: "#1D1A1F",
                lineHeight: "25px",
                fontWeight: "700",
              }}
            >
              Personal Info
            </Typography.Text>
            <Space>
              <IncredableButton secondary onClick={() => navigate(-1)}>
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" htmlType="submit">
                Save
              </IncredableButton>
            </Space>
          </Flex>
          <Divider />
          <Form.List name="personalInfo" initialValue={[{}]}>
            {(fields, { add }) => {
              return (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div key={key}>
                      {index > 0 && <Divider />}
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Middle Name",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "middleName",
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>

                        <Col span={8}>
                          <Checkbox
                            onChange={onChangeHasOtherName}
                            checked={hasOtherName}
                          >
                            Has Other Name
                          </Checkbox>
                        </Col>
                        {hasOtherName === true ? (
                          <Col span={8}>
                            <RHFTextField
                              formItemProps={{
                                label: "Other Name",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: "otherName",
                              }}
                            />
                          </Col>
                        ) : (
                          <></>
                        )}
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Degree" }}
                            controllerProps={{
                              name: "degreeId",
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Search to Select",
                              options: degree?.map((degree) => {
                                return {
                                  label: degree?.name,
                                  value: degree?.id,
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "SSN",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "ssn",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Gender" }}
                            controllerProps={{
                              name: "gender",
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Search to Select",
                              options: gender?.map((gender) => {
                                return {
                                  label: gender?.label,
                                  value: gender?.value,
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{
                              label: "DOB",
                            }}
                            controllerProps={{
                              name: "dob",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Place Of Birth",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "placeOfBirth",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Citizenship",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "citizenship",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFCheckBox
                            formItemProps={{ label: "" }}
                            controllerProps={{ name: "hasWorkedInMilitary" }}
                            checkboxProps={{ label: "Has Worked In Military?" }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Branch Of Military",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "branchOfMilitary",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{
                              label: "MilitaryDateOfService",
                            }}
                            controllerProps={{
                              name: "militaryDateOfService",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Passport No",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "passportNo",
                            }}
                          />
                        </Col>
                      </Row>
                      <Divider />
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Email",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "email",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFPhoneNumber
                            formItemProps={{
                              label: "Phone",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "mobile",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Fax",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "fax",
                            }}
                          />
                        </Col>
                      </Row>
                      <Divider />
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Address Lane 1",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "addressLane1",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Address Lane 2",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "addressLane2",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "City",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "city",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "State",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "state",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "County",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "county",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Country",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "country",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Zip",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "zipcode",
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}
                </>
              );
            }}
          </Form.List>
        </Form>
      </FormProvider>
    </IncredableContent>
  );
}

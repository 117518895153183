import { useContext, useState } from "react";
import { Flex, Layout, Space, Table, Typography } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import TableTitle from "../../components/incredable-table/table-title";
import { TablePreferenceModal } from "../../common/TablePreferenceModal";
import IncredableButton from "../../components/button";
import { Task } from "../../redux/api/task/types";
import DeleteAlertModal from "../../common/Modal/delete-alert-modal";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import { useDeleteTaskMutation } from "../../redux/api/task";
import IncredableDateFormat from "../../common/incredable-date-format";
import AddMyTaskModal from "./add-mytask";
import EditMyTaskModal from "./edit-mytask";
import { Workflow } from "../../redux/api/workflow/types";
import useMyTasksFilter from "../../table-filters/mytasks-filters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

export const columns = [
  {
    title: () => <Typography.Text>Task ID</Typography.Text>,
    key: "task ID",
    width: "120px",
    ellipsis: true,
    render: (task: Task) => (
      <Typography.Link href={`/tasks/${task?.id}`}>
        {task?.id ? `${task.id}` : "-"}
      </Typography.Link>
    ),
    sorter: (a: Task, b: Task) =>
      a?.name?.toLowerCase()?.localeCompare(b?.name?.toLowerCase()),
  },
  {
    title: () => <Typography.Text>Task Type</Typography.Text>,
    dataIndex: ["taskType", "name"],
    key: "task Type",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Name</Typography.Text>,
    dataIndex: "name",
    key: "name",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Task Status</Typography.Text>,
    dataIndex: ["taskStatus", "name"],
    key: "task Status",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Provider</Typography.Text>,
    dataIndex: ["provider"],
    key: "provider",
    ellipsis: true,
    render: (provider: { firstName: string; lastName: string }) => (
      <Typography.Text>
        {provider?.firstName && provider?.lastName
          ? `${provider.firstName} ${provider.lastName}`
          : "-"}
      </Typography.Text>
    ),
  },
  {
    title: () => <Typography.Text>Facility</Typography.Text>,
    dataIndex: ["facility", "name"],
    key: "facility",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Workflow</Typography.Text>,
    dataIndex: ["workflow"],
    key: "workflow",
    ellipsis: true,
    render: (text: Workflow) => (
      <Typography.Link href={`workflows/${text?.id}`}>
        {text?.name ? (
          <>
            {text.name} <FontAwesomeIcon icon={faUpRightFromSquare} />
          </>
        ) : (
          "-"
        )}
      </Typography.Link>
    ),
  },
  {
    title: () => <Typography.Text>Start Date</Typography.Text>,
    dataIndex: "startDate",
    key: "startDate",
    ellipsis: true,
    render: (startDate: string) => (
      <Typography.Text>
        {IncredableDateFormat(startDate) ?? "-"}
      </Typography.Text>
    ),
  },
  {
    title: () => <Typography.Text>Description</Typography.Text>,
    dataIndex: "description",
    key: "description",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Created Date</Typography.Text>,
    dataIndex: "createdDate",
    key: "createdDate",
    ellipsis: true,
    render: (text: string) => (
      <Typography.Text>{IncredableDateFormat(text) ?? "-"}</Typography.Text>
    ),
  },
  {
    title: () => <Typography.Text>Expiration Date</Typography.Text>,
    dataIndex: "dueDate",
    key: "dueDate",
    ellipsis: true,
    render: (text: string) => (
      <Typography.Text>{IncredableDateFormat(text) ?? "-"}</Typography.Text>
    ),
  },
  {
    title: () => <Typography.Text>Alert Days</Typography.Text>,
    dataIndex: "alertDays",
    key: "alertDays",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text ?? "-"}</Typography.Text>,
  },
];

export default function MyTasksList() {
  const [selectedRow, setSelectedRow] = useState<Task>();
  const { TableFilters, filteredMyTasks, UseQueryHookResult } =
    useMyTasksFilter();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [addMyTaskModalVisible, setAddMyTaskModalVisible] = useState(false);
  const [editMyTaskModalVisible, setEditMyTaskModalVisible] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    columns.map((col) => col.key as string)
  );
  const [visibleColumnOrder, setVisibleColumnOrder] = useState<string[]>(
    columns.map((col) => col.key as string)
  );
  const [deleteTask] = useDeleteTaskMutation();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  const selectRow = (record: Task) => {
    if (selectedRow?.id + "" === record?.id + "") {
      setSelectedRow(undefined);
    } else {
      setSelectedRow(record);
    }
  };

  const handleOpenAddTaskModal = () => {
    setAddMyTaskModalVisible(true);
  };

  const handleCloseAddTaskModal = () => {
    setAddMyTaskModalVisible(false);
  };

  const handleOpenEditTaskModal = () => {
    setEditMyTaskModalVisible(true);
  };

  const handleCloseEditTaskModal = () => {
    setEditMyTaskModalVisible(false);
  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const handleSavePreferences = (
    selectedColumns: string[],
    columnOrder: string[]
  ) => {
    setSelectedColumns(selectedColumns);
    setVisibleColumnOrder(columnOrder);
    handleCloseModal();
  };

  const visibleColumns = visibleColumnOrder
    .filter((colKey) => selectedColumns.includes(colKey))
    .map((colKey) => columns.find((col) => col.key === colKey)!);

  return (
    <Layout style={{ margin: "0" }}>
      <Space size="large" direction="vertical">
        <Table
          style={{
            boxShadow: "0px 10px 25px 0px #5911920D",
            borderRadius: "16px",
            border: "1px solid #E5E7EB",
            overflow: "hidden",
          }}
          size="small"
          columns={visibleColumns}
          loading={
            UseQueryHookResult.isLoading || UseQueryHookResult.isFetching
          }
          rowKey="id"
          dataSource={filteredMyTasks}
          onRow={(record) => ({
            onClick: () => {
              selectRow(record);
            },
          })}
          rowSelection={{
            type: "radio",
            selectedRowKeys: !!selectedRow?.id ? [selectedRow?.id] : [],
            onChange: (selectedRowKeys, selectedRows, info) => {
              if (!!selectedRows && !!selectedRows?.at(0))
                setSelectedRow(selectedRows?.at(0));
            },
            columnWidth: 60,
          }}
          pagination={{
            position: ["bottomRight"],
            style: { backgroundColor: "white", padding: "10px", margin: "0" },
            pageSizeOptions: [10, 25, 50, 100, 300],
            showSizeChanger: true,
            total: filteredMyTasks?.length,
            showTotal: (total) => `Total ${total} items`,
          }}
          scroll={{ y: "50vh", x: "100vw" }}
          title={() => (
            <Space direction="vertical" style={{ display: "flex" }}>
              <Flex
                align="start"
                justify={"space-between"}
                style={{ margin: "16px 10px 0 10px" }}
              >
                <Space size="middle">
                  <Typography.Title
                    style={{ paddingTop: "0", marginTop: "0" }}
                    level={4}
                  >
                    {`Tasks (${filteredMyTasks?.length ?? ""})`}
                  </Typography.Title>
                </Space>
                <Space size="middle">
                  <IncredableButton
                    onClick={handleOpenAddTaskModal}
                    type="primary"
                    icon={<PlusOutlined />}
                    size="large"
                  >
                    Add Tasks
                  </IncredableButton>
                </Space>
              </Flex>
              <TableTitle
                filter={TableFilters}
                onReloadClick={UseQueryHookResult.refetch}
                actionDropDownProps={{
                  disabled: !selectedRow,
                  menu: {
                    items: [
                      {
                        label: "Edit",
                        key: "edit",
                        icon: <EditOutlined />,
                      },
                      {
                        label: "Delete",
                        key: "delete",
                        icon: <DeleteOutlined />,
                        danger: true,
                      },
                    ],
                    onClick: (e) => {
                      if (e?.key === "edit") {
                        handleOpenEditTaskModal();
                      }
                      if (e.key === "delete") {
                        handleOpenDeleteModal();
                      }
                    },
                  },
                }}
                onSettingsClick={handleOpenModal}
              />
            </Space>
          )}
        />
        <TablePreferenceModal
          visible={isModalVisible}
          onCancel={handleCloseModal}
          onSave={handleSavePreferences}
          availableColumns={columns.map((col) => col.key as string)}
          selectedColumns={selectedColumns}
          columnOrderState={visibleColumnOrder}
        />
        <DeleteAlertModal
          visible={isDeleteModalOpen}
          action={async () => {
            if (selectedRow?.id) {
              setDeleteModalOpen(false);
              spinnerContext?.showSpinner();
              await deleteTask({
                id: String(selectedRow?.id),
              });
              spinnerContext?.hidespinner();
              toastContext?.openSuccessNotification({
                message: `Task Deleted Successfully`,
                placement: "topRight",
              });
            }
          }}
          closeModal={() => setDeleteModalOpen(false)}
          header="Delete Task"
          content={
            <>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography.Text>Task Name</Typography.Text>
                <Typography.Text strong style={{ marginTop: "8px" }}>
                  {selectedRow?.name}
                </Typography.Text>
              </div>
            </>
          }
          description="Are you sure you want to delete this task?"
        />
      </Space>
      <AddMyTaskModal
        visible={addMyTaskModalVisible}
        handleCloseAddTaskModal={handleCloseAddTaskModal}
      />
      <EditMyTaskModal
        visible={editMyTaskModalVisible}
        handleCloseEditTaskModal={handleCloseEditTaskModal}
        taskId={selectedRow?.id}
      />
    </Layout>
  );
}

import { useContext, useState } from "react";
import { Flex, Layout, Space, Table, Typography } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import TableTitle from "../components/incredable-table/table-title";
import { TablePreferenceModal } from "../common/TablePreferenceModal/";
import useWorkflowFilter from "../table-filters/workflow-filters";
import IncredableButton from "../components/button";
import { WorkflowMetadata } from "../redux/api/workflow/types";
import IncredableDateFormat from "../common/incredable-date-format";
import { SpinnerContext } from "../context/spinner/spinner";
import { ToastContext } from "../context/toast/incredable-toast";
import { useDeleteWorkflowMutation } from "../redux/api/workflow/workflow";
import DeleteAlertModal from "../common/Modal/delete-alert-modal";

export const columns = [
  {
    title: () => <Typography.Text>Name</Typography.Text>,
    key: "name",
    width: "120px",
    ellipsis: true,
    render: (workflow: WorkflowMetadata) => (
      <Typography.Link href={`workflows/${workflow?.id}`}>
        {workflow?.name
          ? `${workflow.name}`
          : "-"}
      </Typography.Link>
    ),
    sorter: (a: WorkflowMetadata, b: WorkflowMetadata) =>
      a?.name?.toLowerCase()?.localeCompare(b?.name?.toLowerCase()),
  },
  {
    title: () => <Typography.Text>Status</Typography.Text>,
    dataIndex: ["workFlowStatus", "name"],
    key: "workflowStatus",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
    sorter: (a: WorkflowMetadata, b: WorkflowMetadata) =>
      a?.workFlowStatus?.name?.toLowerCase()?.localeCompare(b?.workFlowStatus?.name?.toLowerCase()),
  },
  {
    title: () => <Typography.Text>Workflow Template</Typography.Text>,
    dataIndex: ["workflowTemplate", "name"],
    key: "workflowTemplate",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
    sorter: (a: WorkflowMetadata, b: WorkflowMetadata) =>
      a?.workflowTemplate?.name?.toLowerCase()?.localeCompare(b?.workflowTemplate?.name?.toLowerCase()),
  },
  {
    title: () => <Typography.Text>Assigned To</Typography.Text>,
    dataIndex: ["admin"],
    key: "assiged To",
    ellipsis: true,
    render: (admin: { firstName: string; lastName: string }) => (
      <Typography.Text>
        {admin?.firstName && admin?.lastName
          ? `${admin.firstName} ${admin.lastName}`
          : "-"}
      </Typography.Text>
    ),
    sorter: (a: WorkflowMetadata, b: WorkflowMetadata) =>
      a?.admin?.firstName?.toLowerCase()?.localeCompare(b?.admin?.firstName?.toLowerCase()),
  },
  {
    title: () => <Typography.Text>Provider</Typography.Text>,
    dataIndex: ["provider"],
    key: "provider",
    ellipsis: true,
    render: (provider: { firstName: string; lastName: string }) => (
      <Typography.Text>
        {provider?.firstName && provider?.lastName
          ? `${provider.firstName} ${provider.lastName}`
          : "-"}
      </Typography.Text>
    ),
    sorter: (a: WorkflowMetadata, b: WorkflowMetadata) =>
      a?.provider?.firstName?.toLowerCase()?.localeCompare(b?.provider?.firstName?.toLowerCase()),
  },
  {
    title: () => <Typography.Text>Start Date</Typography.Text>,
    dataIndex: "startDate",
    key: "startDate",
    ellipsis: true,
    render: (startDate: string) => (
      <Typography.Text>
        {IncredableDateFormat(startDate) ?? "-"}
      </Typography.Text>
    ),
    sorter: (a: WorkflowMetadata, b: WorkflowMetadata) =>
      new Date(a?.startDate).getTime() - new Date(b?.startDate).getTime()

  },
  {
    title: () => <Typography.Text>Due Date</Typography.Text>,
    dataIndex: "dueDate",
    key: "dueDate",
    ellipsis: true,
    render: (dueDate: string) => (
      <Typography.Text>
        {IncredableDateFormat(dueDate) ?? "-"}
      </Typography.Text>
    ),
    sorter: (a: WorkflowMetadata, b: WorkflowMetadata) =>
      new Date(a?.dueDate).getTime() - new Date(b?.dueDate).getTime()
  },
  {
    title: () => <Typography.Text>Completed Date</Typography.Text>,
    dataIndex: "completedDate",
    key: "completedDate",
    ellipsis: true,
    render: (completedDate: string) => (
      <Typography.Text>
        {IncredableDateFormat(completedDate) ?? "-"}
      </Typography.Text>
    ),
    sorter: (a: WorkflowMetadata, b: WorkflowMetadata) =>
      new Date(a?.completedDate).getTime() - new Date(b?.completedDate).getTime()
  },
  {
    title: () => <Typography.Text>Created Date</Typography.Text>,
    dataIndex: "createdDate",
    key: "createdDate",
    ellipsis: true,
    render: (createdDate: string) => (
      <Typography.Text>
        {IncredableDateFormat(createdDate) ?? "-"}
      </Typography.Text>
    ),
    sorter: (a: WorkflowMetadata, b: WorkflowMetadata) =>
      new Date(a?.createdDate).getTime() - new Date(b?.createdDate).getTime()
  },
];

export default function WorkFlowList() {
  const [selectedRow, setSelectedRow] = useState<WorkflowMetadata>();
  const { TableFilters, filteredWorkflow, UseQueryHookResult } =
    useWorkflowFilter();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    columns.map((col) => col.key as string)
  );
  const [visibleColumnOrder, setVisibleColumnOrder] = useState<string[]>(
    columns.map((col) => col.key as string)
  );
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();
  const [deleteWorkflow] = useDeleteWorkflowMutation();

  const selectRow = (record: WorkflowMetadata) => {
    if (selectedRow?.id + "" === record?.id + "") {
      setSelectedRow(undefined);
    } else {
      setSelectedRow(record);
    }
  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const handleSavePreferences = (
    selectedColumns: string[],
    columnOrder: string[]
  ) => {
    setSelectedColumns(selectedColumns);
    setVisibleColumnOrder(columnOrder);
    handleCloseModal();
  };

  const visibleColumns = visibleColumnOrder
    .filter((colKey) => selectedColumns.includes(colKey))
    .map((colKey) => columns.find((col) => col.key === colKey)!);

  return (
    <Layout style={{ margin: "20px 30px 0 30px" }}>
      <Space size="large" direction="vertical">
        <Table
          style={{
            boxShadow: "0px 10px 25px 0px #5911920D",
            borderRadius: "16px",
            border: "1px solid #E5E7EB",
            overflow: "hidden",
          }}
          size="small"
          columns={visibleColumns}
          loading={
            UseQueryHookResult.isLoading || UseQueryHookResult.isFetching
          }
          rowKey="id"
          dataSource={filteredWorkflow}
          onRow={(record) => ({
            onClick: () => {
              selectRow(record);
            },
          })}
          rowSelection={{
            type: "radio",
            selectedRowKeys: !!selectedRow?.id ? [selectedRow?.id] : [],
            onChange: (selectedRowKeys, selectedRows, info) => {
              if (!!selectedRows && !!selectedRows?.at(0))
                setSelectedRow(selectedRows?.at(0));
            },
            columnWidth: 60,
          }}
          pagination={{
            position: ["bottomRight"],
            style: { backgroundColor: "white", padding: "10px", margin: "0" },
            pageSizeOptions: [10, 25, 50, 100, 300],
            showSizeChanger: true,
            total: filteredWorkflow?.length,
            showTotal: (total) => `Total ${total} items`,
          }}
          scroll={{ y: "50vh", x: "100vw" }}
          title={() => (
            <Space direction="vertical" style={{ display: "flex" }}>
              <Flex
                align="start"
                justify={"space-between"}
                style={{ margin: "16px 10px 0 10px" }}
              >
                <Space size="middle">
                  <Typography.Title
                    style={{ paddingTop: "0", marginTop: "0" }}
                    level={4}
                  >
                    {`Workflows (${filteredWorkflow?.length ?? ""})`}
                  </Typography.Title>
                </Space>
                <Space size="middle">
                  <IncredableButton
                    onClick={() => navigate("add")}
                    type="primary"
                    icon={<PlusOutlined />}
                    size="large"
                  >
                    Add Workflow
                  </IncredableButton>
                </Space>
              </Flex>
              <TableTitle
                filter={TableFilters}
                onReloadClick={UseQueryHookResult.refetch}
                actionDropDownProps={{
                  disabled: !selectedRow,
                  menu: {
                    items: [
                      {
                        label: "Edit",
                        key: "edit",
                        icon: <EditOutlined />,
                      },
                      {
                        label: "Delete",
                        key: "delete",
                        icon: <DeleteOutlined />,
                        danger: true,
                      },
                    ],
                    onClick: (e) => {
                      if (e?.key === "edit") {
                        navigate(`${selectedRow?.id}`);
                      }
                      if (e?.key === "delete") {
                        handleOpenDeleteModal();
                      }
                    },
                  },
                }}
                onSettingsClick={handleOpenModal}
              />
            </Space>
          )}
        />
        <TablePreferenceModal
          visible={isModalVisible}
          onCancel={handleCloseModal}
          onSave={handleSavePreferences}
          availableColumns={columns.map((col) => col.key as string)}
          selectedColumns={selectedColumns}
          columnOrderState={visibleColumnOrder}
        />
        <DeleteAlertModal
          visible={isDeleteModalOpen}
          action={async () => {
            if (selectedRow?.id) {
              setDeleteModalOpen(false);
              spinnerContext?.showSpinner();
              await deleteWorkflow({
                id: String(selectedRow?.id),
              });
              spinnerContext?.hidespinner();
              toastContext?.openSuccessNotification({
                message: `workflow deleted successfully`,
                placement: "topRight",
              });
            }
          }}
          closeModal={() => setDeleteModalOpen(false)}
          header="Delete Workflow "
          content={
            <>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography.Text>Workflow Name</Typography.Text>
                <Typography.Text strong style={{ marginTop: "8px" }}>
                  {selectedRow?.name}
                </Typography.Text>
              </div>
            </>
          }
          description="Are you sure you want to delete this workflow?"
        />
      </Space>
    </Layout>
  );
}

import { SearchOutlined } from "@ant-design/icons";
import { Input, Select, Space } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useGetBasicAllProvidersQuery } from "../redux/api/provider/provider";
import {
  useGetAllCredentialPackagesQuery,
  useGetAllCredentialTemplatesQuery,
  useGetAllPackageStatusQuery,
  useGetAllPackageTypesQuery,
} from "../redux/api/credentialpackages/credentialpackages";

export default function useCredentialPackageFilter() {
  const [selectedFilterkey, setSelectedFilterKey] = useState<string>("name");
  const [selectedFiltervalue, setSelectedFiltervalue] = useState<
    string | undefined
  >(undefined);

  const { data: providers, isLoading: providersLoading } =
    useGetBasicAllProvidersQuery();
  const { data: packageTypes, isLoading: packageTypeLoading } =
    useGetAllPackageTypesQuery();
  const { data: packageStatus, isLoading: packageStatusLoading } =
    useGetAllPackageStatusQuery();
  const { data: credentialtemplates, isLoading: credentialtemplateLoding } =
    useGetAllCredentialTemplatesQuery();
  const {
    data: credentialpackages,
    refetch,
    isLoading: workflowLoading,
    isFetching,
  } = useGetAllCredentialPackagesQuery();

  const filteredCredentialPackages = useMemo(() => {
    if (!!selectedFiltervalue) {
      if (selectedFilterkey === "name")
        return credentialpackages?.filter((credentialpackage) =>
          credentialpackage?.name
            ?.toLowerCase()
            .includes(selectedFiltervalue?.toLowerCase())
        );
      else if (selectedFilterkey === "packageType")
        return credentialpackages?.filter(
          (credentialpackage) =>
            credentialpackage?.packageType?.id + "" === selectedFiltervalue + ""
        );
      else if (selectedFilterkey === "packageStatus")
        return credentialpackages?.filter(
          (credentialpackage) =>
            credentialpackage?.packageStatus?.id + "" ===
            selectedFiltervalue + ""
        );
      else if (selectedFilterkey === "credentialTemplate")
        return credentialpackages?.filter(
          (credentialpackage) =>
            credentialpackage?.credentialTemplate?.id + "" ===
            selectedFiltervalue + ""
        );
      else if (selectedFilterkey === "provider")
        return credentialpackages?.filter(
          (credentialpackage) =>
            credentialpackage?.provider?.id + "" === selectedFiltervalue + ""
        );
    }
    return credentialpackages;
  }, [selectedFiltervalue, credentialpackages]);

  useEffect(() => {
    setSelectedFiltervalue(undefined);
  }, [selectedFilterkey]);

  const TableFilters = (
    <Space size="small">
      {selectedFilterkey === "packageType" && (
        <Select
          allowClear={true}
          showSearch={true}
          optionFilterProp="label"
          value={selectedFiltervalue}
          placeholder="Choose task type"
          onChange={(value) => {
            setSelectedFiltervalue(value);
          }}
          style={{ width: "320px" }}
          options={packageTypes?.map((packageType) => ({
            label: packageType?.name,
            searchValue: packageType?.name,
            value: packageType?.id + "",
          }))}
        />
      )}
      {selectedFilterkey === "name" && (
        <Input
          onChange={(event) => {
            setSelectedFiltervalue(event?.target?.value);
          }}
          value={selectedFiltervalue}
          style={{
            width: "320px",
          }}
          placeholder="Find Name"
          prefix={<SearchOutlined />}
        />
      )}
      {selectedFilterkey === "packageStatus" && (
        <Select
          showSearch
          allowClear
          key="status-select"
          optionFilterProp="label"
          value={selectedFiltervalue}
          placeholder="Select Package Status"
          onChange={(value) => {
            setSelectedFiltervalue(value);
          }}
          style={{ width: "320px" }}
          options={packageStatus?.map((packageStatu) => ({
            label: packageStatu?.name,
            searchValue: packageStatu?.name,
            value: packageStatu?.id + "",
          }))}
        />
      )}
      {selectedFilterkey === "credentialTemplate" && (
        <Select
          showSearch
          allowClear
          key="status-select"
          optionFilterProp="label"
          value={selectedFiltervalue}
          placeholder="Select Credential Template"
          onChange={(value) => {
            setSelectedFiltervalue(value);
          }}
          style={{ width: "320px" }}
          options={credentialtemplates?.map((template) => ({
            label: template?.name,
            searchValue: template?.name,
            value: template?.id + "",
          }))}
        />
      )}
      {selectedFilterkey === "provider" && (
        <Select
          allowClear
          showSearch
          optionFilterProp="label"
          value={selectedFiltervalue}
          placeholder="Select Provider"
          onChange={(value) => {
            setSelectedFiltervalue(value);
          }}
          style={{ width: "320px" }}
          options={providers?.map((provider) => ({
            label: `${provider?.firstName} ${provider?.lastName}`,
            searchValue: `${provider?.firstName} ${provider?.lastName}`,
            value: provider?.id + "",
          }))}
        />
      )}
      <Select
        value={selectedFilterkey}
        onChange={(value) => {
          setSelectedFilterKey(value);
        }}
        style={{ width: "140px" }}
        options={[
          { value: "name", label: "Name" },
          { value: "packageType", label: "Package Type" },
          { value: "packageStatus", label: "Package Status" },
          { value: "credentialTemplate", label: " Credential Template" },
          { value: "provider", label: "Provider" },
        ]}
      />
    </Space>
  );

  return {
    TableFilters,
    filteredCredentialPackages,
    UseQueryHookResult: {
      refetch,
      isLoading:
        workflowLoading &&
        packageTypeLoading &&
        providersLoading &&
        packageStatusLoading,
      isFetching,
    },
  };
}

import {
  Col,
  Divider,
  Flex,
  Form,
  Row,
  Space,
  Typography,
  UploadProps,
} from "antd";
import { useNavigate } from "react-router-dom";
import { DigitalFormRequest } from "../redux/api/digitalform/types";
import { FormProvider, useForm } from "react-hook-form";
import {
  useCreateDigitalFormMutation,
  useGetAllDigitalFormTypesQuery,
} from "../redux/api/digitalform/digitalforms";
import { SpinnerContext } from "../context/spinner/spinner";
import { useContext, useState } from "react";
import { ToastContext } from "../context/toast/incredable-toast";
import IncredableButton from "../components/button";
import RHFTextField from "../components/RHF/RHFTextField";
import RHFSelect from "../components/RHF/RHFSelect";
import { useGetAllCategoryQuery } from "../redux/api/category/category";
import IncredableContent from "../components/incredable-content";
import Dragger from "antd/es/upload/Dragger";
import { DeleteOutlined, InboxOutlined } from "@ant-design/icons";
import RHFTextArea from "../components/RHF/RHFTextArea";
import { fileAxios } from "../context/axios";
import { getFileUrl } from "../config";
import { captureException } from "@sentry/react";

type FormInputType = DigitalFormRequest;

export default function AddDigitalform() {
  const navigate = useNavigate();
  const methods = useForm<FormInputType>();
  const { data: digitalformtypes, isLoading: formTypesLoading } =
    useGetAllDigitalFormTypesQuery();
  const { data: categories, isLoading: categoriesLoading } =
    useGetAllCategoryQuery();
  const [createDigitalForm] = useCreateDigitalFormMutation();
  const [uploadedFile, setUplaodedFile] = useState<File>();
  const [error, setError] = useState<string>();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const handleChange: UploadProps["onChange"] = (info) => {
    setUplaodedFile(info.file?.originFileObj as File);
  };

  return (
    <IncredableContent
      spinning={formTypesLoading || categoriesLoading}
      breadcrumbProps={{
        items: [
          {
            title: <a href="/readyforms">Ready Forms</a>,
          },
          {
            title: "Add",
          },
        ],
      }}
    >
      <FormProvider {...methods}>
        <Form
          name="adddigitalform"
          layout="vertical"
          onFinish={methods.handleSubmit(async (data: FormInputType) => {
            if (!uploadedFile) {
              setError("This field is required");
            }
            spinnerContext?.showSpinner();
            await createDigitalForm({
              name: data.name,
              description: data.description,
              digitalFormTypeId: data.digitalFormTypeId,
              categoryId: data.categoryId,
              disabled: data.disabled,
              file: uploadedFile,
            })
              .unwrap()
              .then(async (digitalFormResponse) => {
                if (!!uploadedFile && digitalFormResponse?.template?.key) {
                  try {
                    await fileAxios.put(
                      getFileUrl(digitalFormResponse?.template?.key),
                      uploadedFile
                    );
                    toastContext?.openSuccessNotification({
                      message: `Ready Form saved successfully`,
                      placement: "topRight",
                    });
                  } catch (error) {
                    captureException(error);
                    toastContext?.openErrorNotification({
                      message: `Failed to save Ready Form`,
                      placement: "topRight",
                    });
                  }
                } else
                  toastContext?.openSuccessNotification({
                    message: `Ready Form saved successfully`,
                    placement: "topRight",
                  });
              })
              .catch((error) => {
                captureException(error);
                if (error.status < 500 && error.status >= 400) {
                  toastContext?.openErrorNotification({
                    message: `Failed to save Ready Form`,
                    placement: "topRight",
                  });
                } else
                  toastContext?.openErrorNotification({
                    message: `Failed to save Ready Form`,
                    placement: "topRight",
                  });
              });
            spinnerContext?.hidespinner();
            navigate(-1);
          })}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                color: "#1D1A1F",
                lineHeight: "25px",
                fontWeight: "700",
              }}
            >
              Add New Ready Form
            </Typography.Text>
            <Space>
              <IncredableButton
                secondary
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" htmlType="submit">
                Add Ready Form
              </IncredableButton>
            </Space>
          </Flex>
          <Divider />
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={8}>
              <RHFTextField
                formItemProps={{
                  label: "Name",
                }}
                inputProps={{}}
                controllerProps={{
                  name: "name",
                }}
                rules={{ required: "This field is required" }}
              />
            </Col>

            <Col span={8}>
              <RHFSelect
                formItemProps={{ label: "Category" }}
                controllerProps={{
                  name: "categoryId",
                }}
                selectProps={{
                  allowClear: true,
                  showSearch: true,
                  style: { width: "100%" },
                  placeholder: "Search to category",
                  options: categories?.map((category) => {
                    return {
                      label: category?.name,
                      searchValue: category?.name,
                      value: category?.id,
                    };
                  }),
                }}
                rules={{ required: "This field is required" }}
              />
            </Col>
            <Col span={8}>
              <RHFSelect
                formItemProps={{ label: "Ready Form Type" }}
                controllerProps={{
                  name: "digitalFormTypeId",
                }}
                selectProps={{
                  allowClear: true,
                  showSearch: true,
                  style: { width: "100%" },
                  placeholder: "Search to Select",
                  options: digitalformtypes?.map((digitalformtype) => {
                    return {
                      label: digitalformtype?.type,
                      value: digitalformtype?.id,
                    };
                  }),
                  onModifyClick: () => {
                    navigate("/readyformtype");
                  },
                }}
              />
            </Col>
          </Row>
          <Form.Item
            label="File"
            help={error}
            extra={"Only PDF files are accepted"}
            validateStatus={!!error ? "error" : undefined}
            required
          >
            {!uploadedFile && (
              <Dragger
                multiple={false}
                onChange={handleChange}
                showUploadList={false}
                style={{
                  border: "1px dashed #598BEB",
                  background: "#F6FAFF",
                  height: "200px",
                }}
              >
                <Space size="small">
                  <p>
                    <InboxOutlined
                      style={{ fontSize: "32px", color: "#7C4095" }}
                    />
                  </p>
                  <p style={{ fontWeight: "600" }}>
                    Drag and Drop your file(s) to start uploading
                  </p>
                </Space>
                <p className="ant-upload-hint">OR</p>
                <IncredableButton
                  style={{
                    background: "#598BEB",
                    color: "#FFF",
                    borderColor: "#598BEB",
                  }}
                >
                  Select a File
                </IncredableButton>
              </Dragger>
            )}
            {!!uploadedFile && (
              <IncredableButton
                icon={<DeleteOutlined />}
                onClick={() => {
                  setUplaodedFile(undefined);
                }}
                secondary
                iconPosition="end"
              >
                {uploadedFile?.name}
              </IncredableButton>
            )}
          </Form.Item>
          <div style={{ paddingTop: "16px" }}>
            <RHFTextArea
              formItemProps={{
                label: "Notes",
              }}
              textAreaProps={{ placeholder: "Notes" }}
              controllerProps={{
                name: "description",
              }}
            />
          </div>
          <Divider />
        </Form>
      </FormProvider>
    </IncredableContent>
  );
}

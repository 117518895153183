import { ConfigProvider, Space, Typography } from "antd";
import { CalendarOutlined, DownloadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import IncredableButton from "../components/button";
import { useLazyGetAllDocumentsQuery } from "../redux/api/reporting/reporting";
import IncredableDateFormat from "../common/incredable-date-format";
import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { AllDocumentResponse } from "../redux/api/document/types";
import { Header, Pagination, Table } from "@cloudscape-design/components";

export const columns = [
    {
        title: () => <Typography.Text>Name</Typography.Text>,
        dataIndex: "name",
        key: "name",
        ellipsis: true,
        render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
    },

    {
        title: () => <Typography.Text>Provider Last Name</Typography.Text>,
        dataIndex: ["provider", "lastName"],
        key: "providerLastName",
        ellipsis: true,
        render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
    },
    {
        title: () => <Typography.Text>Provider First Name</Typography.Text>,
        dataIndex: ["provider", "firstName"],
        key: "providerFirstName",
        ellipsis: true,
        render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
    },
    {
        title: () => <Typography.Text>Category</Typography.Text>,
        dataIndex: ["category", "name"],
        key: "category",
        ellipsis: true,
        render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
    },
    {
        title: () => <Typography.Text>Created Date</Typography.Text>,
        dataIndex: "createdDate",
        key: "createdDate",
        ellipsis: true,
        render: (createdDate: string) => (
            <Typography.Text>
                {createdDate ? <Space> <CalendarOutlined />  {IncredableDateFormat(createdDate)}</Space> : "-"}
            </Typography.Text>
        ),
    },
    {
        title: () => <Typography.Text>Expiration Date</Typography.Text>,
        dataIndex: "expirationDate",
        key: "expirationDate",
        ellipsis: true,
        render: (expirationDate: string) => (
            <Typography.Text>
                {expirationDate ? <Space> <CalendarOutlined />  {IncredableDateFormat(expirationDate)}</Space> : "-"}
            </Typography.Text>
        ),
    },
];

export default function AllDocumentsReport() {
    const [selectedRows, setSelectedRows] = useState<AllDocumentResponse[]>();
    const [pageSize, setPageSize] = useState<number>(10);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [sortingDetails, setSortingDetails] = useState<{
        sortingField?: string;
        isDescending?: boolean;
    }>({ sortingField: "id", isDescending: false });

    const navigate = useNavigate();
    const [
        trigger,
        { data: documentsReportsData, isLoading, isFetching },
    ] = useLazyGetAllDocumentsQuery({});

    // Debounced trigger function
    const debouncedTrigger = useCallback(
        debounce((filters) => {
            trigger(filters);
        }, 1200),
        [trigger]
    );

    // Clean up debounce on unmount
    useEffect(() => {
        return () => {
            debouncedTrigger.cancel();
        };
    }, [debouncedTrigger]);

    useEffect(() => {
        debouncedTrigger({
            pageNumber,
            pageSize,
            sortBy: sortingDetails?.sortingField,
            direction: !!sortingDetails?.isDescending ? "DSC" : "ASC",
        });
    }, [
        pageNumber,
        pageSize,
        sortingDetails,
        debouncedTrigger,
    ]);
    return (
        <div style={{ padding: "32px 32px 0 32px" }}>
            <Table
                loading={isFetching || isLoading}
                onSelectionChange={({ detail }) => {
                    setSelectedRows([...detail.selectedItems]);
                }}
                onRowClick={(onRowClickDetails) => {
                    const selectedItem = onRowClickDetails?.detail?.item;
                    if (selectedItem) {
                        const temp = selectedRows ? [...selectedRows] : [];
                        const selectedItemIndex = temp.findIndex(
                            (record) => record?.id + "" === "" + selectedItem?.id
                        );
                        if (selectedItemIndex !== -1) {
                            temp?.splice(selectedItemIndex, 1);
                            setSelectedRows(temp);
                        } else {
                            setSelectedRows([...temp, selectedItem]);
                        }
                    }
                }}
                selectedItems={selectedRows || []}
                sortingColumn={{ sortingField: sortingDetails?.sortingField }}
                sortingDescending={sortingDetails?.isDescending}
                onSortingChange={({ detail }) => {
                    setSortingDetails({
                        sortingField: detail?.sortingColumn?.sortingField,
                        isDescending: detail?.isDescending,
                    });
                }}
                columnDefinitions={[
                    {
                        id: "name",
                        header: "Name",
                        cell: (item) => (<span>{item?.name}</span>
                        ),
                        sortingField: "name",
                        isRowHeader: true,
                    },
                    {
                        id: "providerLastName",
                        header: "Provider Last Name",
                        cell: (item) => item?.provider?.lastName,
                        sortingField: "provider?.lastName",
                    },
                    {
                        id: "providerFirstName",
                        header: "Provider First Name",
                        cell: (item) => item?.provider?.firstName,
                        sortingField: "provider?.firstName",
                    },
                    {
                        id: "category",
                        header: "Category",
                        cell: (item) => item?.category?.name,
                        sortingField: "category",
                    },
                    {
                        id: "createdDate",
                        header: "Created Date",
                        cell: (item) => IncredableDateFormat(item?.createdDate),
                        sortingField: "email",
                    },
                    {
                        id: "expirationDate",
                        header: "Expiration Date",
                        cell: (item) => IncredableDateFormat(item?.expirationDate),
                        sortingField: "expirationDate",
                    },
                ]}
                items={!!documentsReportsData ? documentsReportsData?.documents : []}
                pagination={
                    <Pagination
                        currentPageIndex={pageNumber + 1}
                        onChange={({ detail }) => {
                            setPageNumber(detail.currentPageIndex - 1);
                        }}
                        pagesCount={
                            !!pageSize && pageSize !== 0
                                ? Math.ceil(!!documentsReportsData ? documentsReportsData?.totalRecords / pageSize : 0)
                                : 1
                        }
                    />
                }
                loadingText="Loading resources"
                selectionType="multi"
                trackBy="id"
                header={
                    <div style={{ marginTop: "8px", marginBottom: "12px" }}>
                        <Header
                            actions={
                                <ConfigProvider
                                    theme={{
                                        token: {
                                            colorPrimary: "#2B6BE6",
                                        },
                                    }}
                                >
                                    <Space size="middle">
                                        <IncredableButton
                                            onClick={() => navigate("")}
                                            type="primary"
                                            icon={<DownloadOutlined />}
                                            size="large"
                                        >
                                            Export CSV
                                        </IncredableButton>
                                    </Space>
                                </ConfigProvider>
                            }
                        >
                            <Typography.Title level={3} style={{ marginTop: "0" }}>
                                All Documents Reports{" "}
                                <Typography.Text
                                    type="secondary"
                                    style={{ fontSize: "16px" }}
                                >{`(${documentsReportsData?.totalRecords})`}</Typography.Text>
                            </Typography.Title>
                        </Header>
                    </div>
                }
            />
        </div>
    );
}

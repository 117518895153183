import { message, Space, Table, theme, Typography } from "antd";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TableTitle from "../../components/incredable-table/table-title";
import { CalendarOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { TablePreferenceModal } from "../../common/TablePreferenceModal";
import { ProviderSpecialtyInfoType } from "../../redux/api/provider/types";
import { useDeleteProviderSpecialtyInfoMutation, useGetProviderQuery } from "../../redux/api/provider/provider";
import { skipToken } from "@reduxjs/toolkit/query";
import IncredableButton from "../../components/button";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import DeleteAlertModal from "../../common/Modal/delete-alert-modal";

const { useToken } = theme;

export const specialityInfoColumns = [
    {
        title: () => <Typography.Text>Board Name</Typography.Text>,
        key: "boardName",
        width: "120px",
        ellipsis: true,
        render: (specialityInfo: ProviderSpecialtyInfoType) => (
            <Typography.Link href="#">
                {specialityInfo?.boardName?.name}
            </Typography.Link>
        ),
        sorter: (a: ProviderSpecialtyInfoType, b: ProviderSpecialtyInfoType) =>
            a?.boardName?.name?.toLowerCase()?.localeCompare(b?.boardName?.name?.toLowerCase()),
    },
    {
        title: () => <Typography.Text>Initial Certification Date</Typography.Text>,
        dataIndex: "initialCertificationDate",
        key: "initialCertificationDate",
        ellipsis: true,
        render: (text: string | null) =>
            <Typography.Text>
                {text ? <Space> <CalendarOutlined /> {text}</Space> : "-"}
            </Typography.Text>,
        sorter: (a: ProviderSpecialtyInfoType, b: ProviderSpecialtyInfoType) =>
            new Date(a?.initialCertificationDate).getTime() - new Date(b?.initialCertificationDate).getTime()
    },
    {
        title: () => <Typography.Text>Expiration Date</Typography.Text>,
        dataIndex: "expirationDate",
        key: "expirationDate",
        ellipsis: true,
        render: (text: string | null) =>
            <Typography.Text>
                {text ? <Space> <CalendarOutlined /> {text}</Space> : "-"}
            </Typography.Text>,
        sorter: (a: ProviderSpecialtyInfoType, b: ProviderSpecialtyInfoType) =>
            new Date(a?.expirationDate).getTime() - new Date(b?.expirationDate).getTime()
    },
    {
        title: () => <Typography.Text>ReCertification Date</Typography.Text>,
        dataIndex: "reCertificationDate",
        key: "reCertificationDate",
        ellipsis: true,
        render: (text: string | null) =>
            <Typography.Text>
                {text ? <Space> <CalendarOutlined /> {text}</Space> : "-"}
            </Typography.Text>,
        sorter: (a: ProviderSpecialtyInfoType, b: ProviderSpecialtyInfoType) =>
            new Date(a?.reCertificationDate).getTime() - new Date(b?.reCertificationDate).getTime()

    },
    {
        title: () => <Typography.Text>Specialty</Typography.Text>,
        dataIndex: ["specialty", "name"],
        key: "specialty",
        ellipsis: true,
        render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
        sorter: (a: ProviderSpecialtyInfoType, b: ProviderSpecialtyInfoType) =>
            a?.specialty?.name?.toLowerCase()?.localeCompare(b?.specialty?.name?.toLowerCase()),
    },
    {
        title: () => <Typography.Text>BoardCert Number</Typography.Text>,
        dataIndex: "boardCertNumber",
        key: "boardCertNumber",
        ellipsis: true,
        render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
    },
];


export default function SpecialityInfo() {
    const { providerId } = useParams();
    const [selectedRow, setSelectedRow] = useState<ProviderSpecialtyInfoType>();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [selectedColumns, setSelectedColumns] = useState<string[]>(
        specialityInfoColumns.map((col) => col.key as string)
    );
    const [visibleColumnOrder, setVisibleColumnOrder] = useState<string[]>(
        specialityInfoColumns.map((col) => col.key as string)
    );

    const { data: provider, isLoading, isFetching } = useGetProviderQuery(
        !!providerId
            ? {
                providerId: providerId,
            }
            : skipToken
    );
    const navigate = useNavigate();
    const { token } = useToken();
    const selectRow = (record: ProviderSpecialtyInfoType) => {
        if (selectedRow?.id + "" === record?.id + "") {
            setSelectedRow(undefined);
        } else {
            setSelectedRow(record);
        }
    };

    const handleOpenModal = () => {
        setIsModalVisible(true);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
    };

    const handleOpenDeleteModal = () => {
        setDeleteModalOpen(true);
    };

    const handleSavePreferences = (selectedColumns: string[], columnOrder: string[]) => {
        setSelectedColumns(selectedColumns);
        setVisibleColumnOrder(columnOrder);
        handleCloseModal();
    };

    const visibleColumns = visibleColumnOrder
        .filter((colKey) => selectedColumns.includes(colKey))
        .map((colKey) => specialityInfoColumns.find((col) => col.key === colKey)!);

    const [deleteProviderSpecialtyInfo] = useDeleteProviderSpecialtyInfoMutation();
    const spinnerContext = useContext(SpinnerContext);
    const toastContext = useContext(ToastContext);


    return (
        <>
            <Table
                style={{
                    boxShadow: "0px 10px 25px 0px #5911920D",
                    borderRadius: "16px",
                    border: "1px solid #E5E7EB",
                    overflow: "hidden",
                }}
                size="small"
                columns={visibleColumns}
                loading={isLoading || isFetching}
                rowKey="id"
                dataSource={!!provider?.providerSpecialties ? [...provider?.providerSpecialties] : []}
                onRow={(record) => ({
                    onClick: () => {
                        selectRow(record);
                    },
                })}
                rowSelection={{
                    type: "radio",
                    selectedRowKeys: !!selectedRow?.id ? [selectedRow?.id] : [],
                    onChange: (selectedRowKeys, selectedRows, info) => {
                        if (!!selectedRows && !!selectedRows?.at(0))
                            setSelectedRow(selectedRows?.at(0));
                    },
                    columnWidth: 60
                }}
                pagination={{
                    position: ["bottomRight"],
                    style: { backgroundColor: "white", padding: "10px", margin: "0" },
                    pageSizeOptions: [10, 25, 50],
                    showSizeChanger: true,
                    total: provider?.providerSpecialties?.length,
                    showTotal: (total) => `Total ${total} items`,
                }}
                title={() => (
                    <Space
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <span style={{ fontSize: "22px", fontWeight: "700" }}>
                            {`Speciality Info (${provider?.providerSpecialties?.length})`}
                        </span>
                        <Space>
                            <IncredableButton
                                onClick={() => navigate(`/manageprovider/${providerId}/specialtyinfo/add`)}
                                type="primary"
                                icon={<PlusOutlined />}
                                size="middle"
                                style={{
                                    backgroundColor: "#598BEB",
                                    border: "2px solid #598BEB",
                                    boxShadow: "none",
                                }}
                            >
                                Add Speciality Info
                            </IncredableButton>
                            <TableTitle
                                filter={[]}
                                actionDropDownProps={{
                                    disabled: !selectedRow,
                                    menu: {
                                        items: [
                                            {
                                                label: "Edit",
                                                key: "edit",
                                                icon: <EditOutlined />,
                                            },
                                            {
                                                label: "Delete",
                                                key: "delete",
                                                icon: <DeleteOutlined />,
                                                danger: true,
                                            },
                                        ],
                                        onClick: async (e) => {
                                            if (e?.key === "edit") {
                                                navigate(`${selectedRow?.id}/edit`);
                                            }
                                            if (e?.key === "delete") {
                                                handleOpenDeleteModal();
                                            }
                                        },
                                    },
                                }}
                                onSettingsClick={handleOpenModal}
                            />
                        </Space>
                    </Space>
                )}
                scroll={{ y: 400, x: "100vw" }}
            />
            <TablePreferenceModal
                visible={isModalVisible}
                onCancel={handleCloseModal}
                onSave={handleSavePreferences}
                availableColumns={specialityInfoColumns.map((col) => col.key as string)}
                selectedColumns={selectedColumns}
                columnOrderState={visibleColumnOrder}
            />
            <DeleteAlertModal
                visible={isDeleteModalOpen}
                action={async () => {
                    if (!!providerId && !!selectedRow?.id) {
                        setDeleteModalOpen(false);
                        spinnerContext?.showSpinner();
                        await deleteProviderSpecialtyInfo({
                            providerId: providerId,
                            specialtyInfoId: selectedRow?.id + "",
                        });
                        spinnerContext?.hidespinner();
                        toastContext?.openSuccessNotification({
                            message: `Specialty deleted successfully`,
                            placement: "topRight",
                        });
                    }
                }}
                closeModal={() => setDeleteModalOpen(false)}
                header="Delete Specialty Info"
                content={
                    <Typography.Text>
                        Board Name:{" "}
                        <Typography.Text strong>{selectedRow?.boardName?.name}</Typography.Text>
                    </Typography.Text>
                }
                description="Are you sure you want to delete this specialty info?"
            />
        </>
    );
}

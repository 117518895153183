import { Select, Space, Tag } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetAllAffiliationQuery, useLazyGetAllAffiliationQuery } from "../redux/api/affiliation/affiliation";
import { useGetFacilitiesQuery } from "../redux/api/facility/facility";
import {
  useGetAllAffiliationStatusQuery, useGetBasicAllProvidersQuery
} from "../redux/api/provider/provider";
import { useGetAllAffiliationTypeQuery } from "../redux/api/affiliationType/affiliation";
import { debounce } from "lodash";

export default function useAffiliationFilter() {
  const [selectedFilterkey, setSelectedFilterKey] =
    useState<string>("provider");
  const [
    trigger,
    { data: providerAffiliation, isLoading: providerAffiliationLoading, isFetching },
  ] = useLazyGetAllAffiliationQuery({});
  const [sortingDetails, setSortingDetails] = useState<{
    sortingField?: string;
    isDescending?: boolean;
  }>({ sortingField: "providerId", isDescending: false });
  const [facilityNameQueryFilterValue, setFacilityNameQueryFilterValue] =
    useState<string | undefined>(undefined);
  const [providerNameQueryFilterValue, setProviderNameQueryFilterValue] =
    useState<string | undefined>(undefined);
  const [affiliationStatusNameQueryFilterValue, setAffiliationStatusNameQueryFilterValue] =
    useState<string | undefined>(undefined);
  const [affiliationTypeNameQueryFilterValue, setAffiliationTypeNameQueryFilterValue] =
    useState<string | undefined>(undefined);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNumber, setPageNumber] = useState<number>(0);

  const { data: facilities, isLoading: facilitiesLoading } =
    useGetFacilitiesQuery();
  const { data: providers, isLoading: providersLoading } = useGetBasicAllProvidersQuery();
  const { data: AffiliationStatus, isLoading: affiliationStatusLoading } = useGetAllAffiliationStatusQuery();
  const { data: AffiliationType, isLoading: affiliationTypeLoading } = useGetAllAffiliationTypeQuery();

  useEffect(() => {
    setPageNumber(0);
  }, [
    providerNameQueryFilterValue,
    affiliationStatusNameQueryFilterValue,
    affiliationTypeNameQueryFilterValue,
    facilityNameQueryFilterValue,
  ]);
  // Debounced trigger function
  const debouncedTrigger = useCallback(
    debounce((filters) => {
      trigger(filters);
    }, 1200),
    [trigger]
  );

  // Clean up debounce on unmount
  useEffect(() => {
    return () => {
      debouncedTrigger.cancel();
    };
  }, [debouncedTrigger]);

  const reload = () => {
    trigger({
      pageNumber,
      pageSize,
      providerId: providerNameQueryFilterValue,
      affiliationTypeId: affiliationTypeNameQueryFilterValue,
      facilityId: facilityNameQueryFilterValue,
      affiliationStatusId: affiliationStatusNameQueryFilterValue,
      sortBy: sortingDetails?.sortingField,
      direction: !!sortingDetails?.isDescending ? "DSC" : "ASC",
    });
  };
  useEffect(() => {
    debouncedTrigger({
      pageNumber,
      pageSize,
      providerId: providerNameQueryFilterValue,
      affiliationTypeId: affiliationTypeNameQueryFilterValue,
      facilityId: facilityNameQueryFilterValue,
      affiliationStatusId: affiliationStatusNameQueryFilterValue,
      sortBy: sortingDetails?.sortingField,
      direction: !!sortingDetails?.isDescending ? "DSC" : "ASC",
    });
  }, [
    pageNumber,
    pageSize,
    providerNameQueryFilterValue,
    affiliationStatusNameQueryFilterValue,
    facilityNameQueryFilterValue,
    affiliationTypeNameQueryFilterValue,
    sortingDetails,
    debouncedTrigger,
  ]);

  const TableFilters = (
    <Space direction="vertical">
      <Space size="small">
        <Select
          value={selectedFilterkey}
          onChange={(value) => {
            setSelectedFilterKey(value);
          }}
          style={{ width: "140px" }}
          options={[
            { value: "provider", label: "Provider" },
            { value: "facility", label: "Facility" },
            { value: "affiliationStatus", label: "Affiliation Status" },
            { value: "affiliationType", label: "Affiliation Type" },
          ]}
        />
        {selectedFilterkey === "provider" && (
          <Select
            allowClear={true}
            showSearch={true}
            optionFilterProp="label"
            value={providerNameQueryFilterValue}
            placeholder="Select Provider"
            onChange={(value) => setProviderNameQueryFilterValue(value)}
            style={{ width: "320px" }}
            options={providers?.map((provider) => ({
              label: `${provider?.firstName} ${provider?.lastName}`,
              value: provider?.id,
            }))}
          />
        )}
        {selectedFilterkey === "affiliationStatus" && (
          <Select
            allowClear={true}
            showSearch={true}
            optionFilterProp="label"
            value={affiliationStatusNameQueryFilterValue}
            placeholder="Select Affiliation Status"
            onChange={(value) => setAffiliationStatusNameQueryFilterValue(value)}
            style={{ width: "320px" }}
            options={AffiliationStatus?.map((status) => ({
              label: `${status?.name}`,
              value: status?.id,
            }))}
          />
        )}
        {selectedFilterkey === "facility" && (
          <Select
            allowClear={true}
            showSearch={true}
            optionFilterProp="label"
            value={facilityNameQueryFilterValue}
            placeholder="Select facility"
            onChange={(value) => {
              setFacilityNameQueryFilterValue(value);
            }}
            style={{ width: "320px" }}
            options={facilities?.map((facility) => ({
              label: facility?.name,
              searchValue: facility?.name,
              value: facility?.id + "",
            }))}
          />
        )}
        {selectedFilterkey === "affiliationType" && (
          <Select
            allowClear={true}
            showSearch={true}
            optionFilterProp="label"
            value={affiliationTypeNameQueryFilterValue}
            placeholder="Select Affiliation Type"
            onChange={(value) => setAffiliationTypeNameQueryFilterValue(value)}
            style={{ width: "320px" }}
            options={AffiliationType?.map((type) => ({
              label: `${type?.name}`,
              value: type?.id,
            }))}
          />

        )}

      </Space>
      <div>
        {!!facilityNameQueryFilterValue && (
          <Tag
            closeIcon
            onClose={() => {
              setFacilityNameQueryFilterValue(undefined);
            }}
          >
            <span>
              Facility ={" "}
              {
                facilities?.find(
                  (facility) =>
                    facility?.id + "" === facilityNameQueryFilterValue + ""
                )?.name
              }
            </span>
          </Tag>
        )}
        {!!providerNameQueryFilterValue && (
          <Tag
            closeIcon
            onClose={() => {
              setProviderNameQueryFilterValue(undefined);
            }}
          >
            <span>
              Provider ={" "}
              {
                providers?.find(
                  (provider) =>
                    provider?.id + "" === providerNameQueryFilterValue + ""
                )?.firstName
              }
            </span>
          </Tag>
        )}
        {!!affiliationStatusNameQueryFilterValue && (
          <Tag
            closeIcon
            onClose={() => {
              setAffiliationStatusNameQueryFilterValue(undefined);
            }}
          >
            <span>
              Affiliation Status ={" "}
              {
                AffiliationStatus?.find(
                  (status) =>
                    status?.id + "" === affiliationStatusNameQueryFilterValue + ""
                )?.name
              }
            </span>
          </Tag>
        )}
        {!!affiliationTypeNameQueryFilterValue && (
          <Tag
            closeIcon
            onClose={() => {
              setAffiliationTypeNameQueryFilterValue(undefined);
            }}
          >
            <span>
              Affiliation Type ={" "}
              {
                AffiliationType?.find(
                  (type) =>
                    type?.id + "" === affiliationTypeNameQueryFilterValue + ""
                )?.name
              }
            </span>
          </Tag>
        )}
      </div>
    </Space>
  );

  return {
    TableFilters,
    filteredAffiliations: providerAffiliation?.providerAffiliations || [],
    affiliationTotalRecords: providerAffiliation?.totalRecords || 0,
    UseQueryHookResult: {
      reload,
      isLoading: providerAffiliationLoading && facilitiesLoading && affiliationStatusLoading && affiliationTypeLoading && providersLoading,
      isFetching,
      pageNumber,
      setPageNumber,
      pageSize,
      setPageSize,
      sortingDetails,
      setSortingDetails,
    },
  };
}

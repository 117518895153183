import {
  Col,
  Row,
  Image,
  Layout,
  Button,
  Form,
  Typography,
  theme,
  Spin,
} from "antd";
import useAuth from "../../hooks/useAuth";
import { FormProvider, useForm } from "react-hook-form";
import RHFTextField from "../../components/RHF/RHFTextField";
import {
  loginTitle,
  loginText,
  loginButton,
  forgotPassButton,
  loginContent,
} from "./login.styles";
import { FormInputType } from "./login.interface";
import { useState } from "react";
import { IncredableSpin } from "../../context/spinner/spinner";

const { Title, Text } = Typography;
const { useToken } = theme;

export default function Login() {
  const { token } = useToken();
  const { login } = useAuth();
  const methods = useForm<FormInputType>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <>
      <Spin fullscreen spinning={isLoading}></Spin>
      <Row>
        <Col xs={0} md={12} lg={14}>
          <Image
            height={"100vh"}
            width={"100%"}
            preview={false}
            src="/assets/Login_Bg_image.png"
          />
        </Col>
        <Col xs={24} md={12} lg={10}>
          <Layout style={{ height: "100%" }}>
            <div style={loginContent}>
              <Title style={{ ...loginTitle, color: token.colorPrimary }}>
                Login
              </Title>
              <Text style={loginText}>
                Welcome back! Your gateway to seamless document management
                awaits.
              </Text>
              <FormProvider {...methods}>
                <Form
                  layout="vertical"
                  name="login"
                  style={{ marginTop: "36px" }}
                  initialValues={{ remember: true }}
                  onFinish={methods.handleSubmit(
                    async (data: FormInputType) => {
                      try {
                        setIsLoading(true);
                        await login(data.email, data.password);
                        setIsLoading(true);
                      } catch (error) {
                        console.error(error);
                      }
                    }
                  )}
                >
                  <RHFTextField
                    formItemProps={{ label: "Email Address" }}
                    controllerProps={{ name: "email" }}
                    inputProps={{ placeholder: "Enter your email" }}
                  />
                  <RHFTextField
                    formItemProps={{ label: "Password" }}
                    controllerProps={{ name: "password" }}
                    inputProps={{
                      type: "password",
                      placeholder: "Enter your password",
                    }}
                  />

                  <Form.Item>
                    <Button type="link" style={forgotPassButton}>
                      Forgot password?
                    </Button>
                    <Button
                      block
                      type="primary"
                      htmlType="submit"
                      style={loginButton}
                    >
                      Login
                    </Button>
                  </Form.Item>
                </Form>
              </FormProvider>
            </div>
          </Layout>
        </Col>
      </Row>
    </>
  );
}

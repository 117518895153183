import { SearchOutlined } from "@ant-design/icons";
import { Input, Select, Space } from "antd";
import { useEffect, useMemo, useState } from "react";
import {
  useGetAllDigitalFormsQuery,
  useGetAllDigitalFormTypesQuery,
} from "../redux/api/digitalform/digitalforms";

export default function useDigitalFormFilter() {
  const [selectedFilterkey, setSelectedFilterKey] = useState<string>("name");
  const [selectedFiltervalue, setSelectedFiltervalue] = useState<
    string | undefined
  >(undefined);

  const { data: digitalFormTypes, isLoading: isFormTypesLoading } =
    useGetAllDigitalFormTypesQuery();

  const {
    data: digitalforms,
    refetch,
    isLoading: digitalformsLoading,
    isFetching,
  } = useGetAllDigitalFormsQuery();

  const filteredReadyForms = useMemo(() => {
    if (!!selectedFiltervalue) {
      if (selectedFilterkey === "name")
        return digitalforms?.filter((digitalform) =>
          digitalform?.name
            ?.toLowerCase()
            .includes(selectedFiltervalue?.toLowerCase())
        );
      else if (selectedFilterkey === "type")
        return digitalforms?.filter((digitalform) =>
          ("" + digitalform?.digitalFormType?.id)
            ?.toLowerCase()
            .includes((selectedFiltervalue + "")?.toLowerCase())
        );
    }
    return digitalforms;
  }, [selectedFiltervalue, digitalforms]);

  useEffect(() => {
    setSelectedFiltervalue(undefined);
  }, [selectedFilterkey]);

  const TableFilters = (
    <Space size="small">
      {selectedFilterkey === "name" && (
        <Input
          onChange={(event) => {
            setSelectedFiltervalue(event?.target?.value);
          }}
          value={selectedFiltervalue}
          style={{
            width: "320px",
          }}
          placeholder="Find Ready Form by name"
          prefix={<SearchOutlined />}
        />
      )}
      {selectedFilterkey === "type" && (
        <Select
          allowClear={true}
          showSearch={true}
          optionFilterProp="label"
          value={selectedFiltervalue}
          placeholder="Select Ready Form by type"
          onChange={(value) => {
            setSelectedFiltervalue(value);
          }}
          style={{ width: "320px" }}
          options={digitalFormTypes?.map((digitalFormType) => ({
            label: digitalFormType?.type,
            searchValue: digitalFormType?.type,
            value: digitalFormType?.id + "",
          }))}
        />
      )}

      <Select
        value={selectedFilterkey}
        onChange={(value) => {
          setSelectedFilterKey(value);
        }}
        style={{ width: "140px" }}
        options={[
          { value: "name", label: "Name" },
          { value: "type", label: "Type" },
        ]}
      />
    </Space>
  );

  return {
    TableFilters,
    filteredReadyForms,
    UseQueryHookResult: {
      refetch,
      isLoading: isFormTypesLoading || digitalformsLoading,
      isFetching,
    },
  };
}

import { Col, Divider, Flex, Form, Row, theme, Typography, Space } from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import { ProviderWorkHistoryRequestType } from "../../redux/api/provider/types";
import {
  useAddProviderWorkHistoryMutation,
  useGetProviderQuery,
} from "../../redux/api/provider/provider";
import IncredableButton from "../../components/button";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { useContext, useEffect } from "react";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import IncredableContent from "../../components/incredable-content";
import RHFPhoneNumber from "../../components/RHF/RHFPhonenumber";
import { Countries, USStatesList } from "../../common/constants";
import RHFSelect from "../../components/RHF/RHFSelect";

const { useToken } = theme;

export default function EditProviderWorkHistory() {
  const { providerId, workhistoryId } = useParams();
  const methods = useForm<ProviderWorkHistoryRequestType>();
  const {
    data: provider,
    isLoading,
    isFetching,
  } = useGetProviderQuery(!!providerId ? { providerId } : skipToken);
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();
  const { token } = useToken();

  useEffect(() => {
    if (provider && provider.providerWorkHistories) {
      const workhistory = provider?.providerWorkHistories?.find(
        (workhistory) => workhistory?.id + "" === workhistoryId + ""
      );
      if (workhistory) {
        methods.setValue("id", workhistory.id);
        methods.setValue("type", workhistory.type);
        methods.setValue("name", workhistory.name);
        methods.setValue("startDate", workhistory.startDate);
        methods.setValue("endDate", workhistory.endDate);
        methods.setValue("positionHeld", workhistory.positionHeld);
        methods.setValue("gapExplanation", workhistory.gapExplanation);
        methods.setValue("referenceName", workhistory.referenceName);
        methods.setValue("referenceEmail", workhistory.referenceEmail);
        methods.setValue("referencePhone", workhistory.referencePhone);
        methods.setValue("referenceFax", workhistory.referenceFax);
        methods.setValue("addressLane1", workhistory.addressLane1);
        methods.setValue("addressLane2", workhistory.addressLane2);
        methods.setValue("city", workhistory.city);
        methods.setValue("state", workhistory.state);
        methods.setValue("zip", workhistory.zip);
        methods.setValue("country", workhistory.country);
      }
    }
  }, [provider, workhistoryId]);

  const [updateProviderWorkHistory] = useAddProviderWorkHistoryMutation();

  return (
    <IncredableContent spinning={isLoading || isFetching}>
      <FormProvider {...methods}>
        <Form
          name="editproviderworkhistory"
          layout="vertical"
          onFinish={methods.handleSubmit(
            async (data: ProviderWorkHistoryRequestType) => {
              if (!!providerId) {
                spinnerContext?.showSpinner();
                await updateProviderWorkHistory({
                  ...data,
                  providerId: providerId,
                });
                spinnerContext?.hidespinner();
                toastContext?.openSuccessNotification({
                  message: `Work History saved successfully`,
                  placement: "topRight",
                });
              }
              navigate(-1);
            }
          )}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                color: "#1D1A1F",
                lineHeight: "25px",
                fontWeight: "700",
              }}
            >
              Work History
            </Typography.Text>
            <Space>
              <IncredableButton secondary onClick={() => navigate(-1)}>
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" htmlType="submit">
                Save
              </IncredableButton>
            </Space>
          </Flex>
          <Divider />
          <Form.List name="workhistory" initialValue={[{}]}>
            {(fields, { add }) => {
              return (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div key={key}>
                      {index > 0 && <Divider />}
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Type",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "type",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Name",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "name",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{
                              label: "Start Date",
                            }}
                            controllerProps={{
                              name: `startDate`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{
                              label: "End Date",
                            }}
                            controllerProps={{
                              name: `endDate`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Position Held",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "positionHeld",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Gap Explanation",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "gapExplanation",
                            }}
                          />
                        </Col>
                      </Row>
                      <Divider />
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Reference Name",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "referenceName",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Reference Email",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "referenceEmail",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFPhoneNumber
                            formItemProps={{
                              label: "Reference Phone",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "referencePhone",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Reference Fax",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "referenceFax",
                            }}
                          />
                        </Col>
                      </Row>
                      <Divider />
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Address Lane 1",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "addressLane1",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Address Lane 2",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "addressLane2",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "City",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "city",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "State" }}
                            controllerProps={{
                              name: `state`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select state",
                              options: USStatesList?.map((state) => {
                                return {
                                  value: state?.value,
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Country" }}
                            controllerProps={{
                              name: `country`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select country",
                              options: Countries?.map((country) => {
                                return {
                                  value: country?.value,
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Zip",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "zip",
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}
                </>
              );
            }}
          </Form.List>
        </Form>
      </FormProvider>
    </IncredableContent>
  );
}

import { SearchOutlined } from "@ant-design/icons";
import { Input, Select, Space } from "antd";
import { useEffect, useMemo, useState } from "react";
import {
  useGetAllContractsQuery,
  useGetAllContractTypesQuery,
} from "../redux/api/contracts/contract";
import { useGetFacilitiesQuery } from "../redux/api/facility/facility";

export default function useContractFilter() {
  const [selectedFilterkey, setSelectedFilterKey] = useState<string>("name");
  const [selectedFiltervalue, setSelectedFiltervalue] = useState<
    string | undefined
  >(undefined);
  const { data: contractType = [], isLoading: contractTypesLoading } =
    useGetAllContractTypesQuery();
  const { data: facilities, isLoading: facilitiesLoading } =
    useGetFacilitiesQuery();
  const {
    data: contracts = [],
    refetch,
    isLoading: contractsLoading,
    isFetching,
  } = useGetAllContractsQuery();

  const filteredContracts = useMemo(() => {
    if (!!selectedFiltervalue) {
      if (selectedFilterkey === "name")
        return contracts?.filter((contract) =>
          contract?.title
            ?.toLowerCase()
            .includes(selectedFiltervalue?.toLowerCase())
        );
      else if (selectedFilterkey === "facility")
        return contracts?.filter(
          (contract) => contract?.facility.id + "" === selectedFiltervalue + ""
        );
      else if (selectedFilterkey === "contracttype")
        return contracts?.filter(
          (contract) =>
            contract?.contractType?.id + "" === selectedFiltervalue + ""
        );
    }
    return contracts;
  }, [selectedFiltervalue, contracts]);

  useEffect(() => {
    setSelectedFiltervalue(undefined);
  }, [selectedFilterkey]);

  const TableFilters = (
    <Space size="small">
      {selectedFilterkey === "name" && (
        <Input
          onChange={(event) => {
            setSelectedFiltervalue(event?.target?.value);
          }}
          value={selectedFiltervalue}
          style={{
            width: "320px",
          }}
          placeholder="Find Name"
          prefix={<SearchOutlined />}
        />
      )}
      {selectedFilterkey === "facility" && (
        <Select
          allowClear={true}
          showSearch={true}
          optionFilterProp="label"
          value={selectedFiltervalue}
          placeholder="Select facility"
          onChange={(value) => {
            setSelectedFiltervalue(value);
          }}
          style={{ width: "320px" }}
          options={facilities?.map((facility) => ({
            label: facility?.name,
            searchValue: facility?.name,
            value: facility?.id + "",
          }))}
        />
      )}
      {selectedFilterkey === "contracttype" && (
        <Select
          allowClear={true}
          showSearch={true}
          optionFilterProp="label"
          value={selectedFiltervalue}
          placeholder="Select Contract Type"
          onChange={(value) => {
            setSelectedFiltervalue(value);
          }}
          style={{ width: "320px" }}
          options={contractType?.map((type) => ({
            label: type?.type,
            searchValue: type?.type,
            value: type?.id + "",
          }))}
        />
      )}
      <Select
        value={selectedFilterkey}
        onChange={(value) => {
          setSelectedFilterKey(value);
        }}
        style={{ width: "140px" }}
        options={[
          { value: "name", label: "Name" },
          { value: "facility", label: "Facility" },
          {
            value: "contracttype",
            label: "Contract Type",
          },
        ]}
      />
    </Space>
  );

  return {
    TableFilters,
    filteredContracts,
    UseQueryHookResult: {
      refetch,
      isLoading: contractsLoading,
      isFetching,
    },
  };
}

import { Col, Divider, Flex, Form, Row, Typography, Space } from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import RHFSelect from "../../components/RHF/RHFSelect";
import { useGetBasicAllProvidersQuery } from "../../redux/api/provider/provider";
import IncredableButton from "../../components/button";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import IncredableContent from "../../components/incredable-content";
import {
  useGetAllPackageStatusQuery,
  useGetAllPackageTypesQuery,
  useGetAllCredentialTemplatesQuery,
  useGetCredentialPackageQuery,
  useUpdateCredentialPackageMutation,
} from "../../redux/api/credentialpackages/credentialpackages";
import { CredentialPackageRequest } from "../../redux/api/credentialpackages/types";
import DocumentList from "./documents-list";
import { AllDocumentResponse } from "../../redux/api/document/types";
import { alertReminderOptions } from "../../common/constants";

export default function EditCredentialPackages() {
  const methods = useForm<CredentialPackageRequest>();

  const { data: packageStatus = [] } = useGetAllPackageStatusQuery();
  const { data: packageTypes = [] } = useGetAllPackageTypesQuery();
  const { data: templates = [] } = useGetAllCredentialTemplatesQuery();
  const { data: providers = [] } = useGetBasicAllProvidersQuery();

  const [updateCredentialPackage] = useUpdateCredentialPackageMutation();

  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();

  const { id: credentialPackageId } = useParams();
  const {
    data: credentialPackage,
    isFetching,
    isLoading,
  } = useGetCredentialPackageQuery(credentialPackageId ?? skipToken);

  const [selectedDocuments, setSelectedDocuments] = useState<
    AllDocumentResponse[]
  >([]);

  useEffect(() => {
    if (credentialPackage) {
      methods.setValue("name", credentialPackage?.name);
      methods.setValue("description", credentialPackage?.description);
      !!credentialPackage?.packageType?.id &&
        methods.setValue("packageTypeId", credentialPackage?.packageType?.id);
      !!credentialPackage?.packageStatus?.id &&
        methods.setValue(
          "packageStatusId",
          credentialPackage?.packageStatus?.id
        );
      methods.setValue("providerId", credentialPackage?.provider?.id);
      methods.setValue("templateId", credentialPackage?.credentialTemplate?.id);
      methods.setValue("submittedDate", credentialPackage?.submittedDate);
      methods.setValue(
        "recredentialingDate",
        credentialPackage?.recredentialingDate
      );
      methods.setValue("alertDays", credentialPackage?.alertDays);
    }
  }, [credentialPackage, credentialPackageId]);

  return (
    <>
      <IncredableContent
        spinning={isLoading || isFetching}
        breadcrumbProps={{
          items: [
            {
              title: <a href="/credentialpackages">Credential Packages</a>,
            },
            {
              title: `${credentialPackage?.name || ""}`,
            },
          ],
        }}
      >
        <FormProvider {...methods}>
          <Form
            name="editcredentialpackage"
            layout="vertical"
            onFinish={methods.handleSubmit(
              async (data: CredentialPackageRequest) => {
                if (!!credentialPackageId) {
                  spinnerContext?.showSpinner();
                  await updateCredentialPackage({
                    ...data,
                    id: credentialPackageId,
                  });
                  spinnerContext?.hidespinner();
                  toastContext?.openSuccessNotification({
                    message: `credential packages updated successfully`,
                    placement: "topRight",
                    onClose: () => {
                      navigate(-1);
                    },
                  });
                }
              }
            )}
            autoComplete="off"
          >
            <Flex gap="small" wrap justify="space-between">
              <Typography.Text
                strong
                style={{
                  fontSize: "22px",
                  color: "#1D1A1F",
                  lineHeight: "25px",
                  fontWeight: "700",
                }}
              >
                Edit Credential Package
              </Typography.Text>
              <Space>
                <IncredableButton secondary onClick={() => navigate(-1)}>
                  Cancel
                </IncredableButton>
                <IncredableButton type="primary" htmlType="submit">
                  Save
                </IncredableButton>
              </Space>
            </Flex>
            <Divider />
            <Form.List name="credentialpackage" initialValue={[{}]}>
              {(fields, { add }) => {
                return (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <div key={key}>
                        {index > 0 && <Divider />}
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col span={8}>
                            <RHFSelect
                              formItemProps={{ label: "Provider" }}
                              controllerProps={{
                                name: `providerId`,
                              }}
                              selectProps={{
                                allowClear: true,
                                showSearch: true,
                                style: { width: "100%" },
                                placeholder: "Search to Select",
                                options: providers?.map((provider) => {
                                  return {
                                    label: `${provider?.firstName} ${provider?.lastName}`,
                                    value: provider?.id,
                                  };
                                }),
                                disabled: true,
                              }}
                              rules={{ required: "This field is required" }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFSelect
                              formItemProps={{ label: "Credential Template" }}
                              controllerProps={{
                                name: `templateId`,
                              }}
                              selectProps={{
                                allowClear: true,
                                showSearch: true,
                                style: { width: "100%" },
                                placeholder: "Search to Select",
                                options: templates?.map((template) => {
                                  return {
                                    label: template?.name,
                                    value: template?.id,
                                  };
                                }),
                                disabled: true,
                              }}
                              rules={{ required: "This field is required" }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFSelect
                              formItemProps={{
                                label: "Credential Package Type",
                              }}
                              controllerProps={{
                                name: `packageTypeId`,
                              }}
                              selectProps={{
                                allowClear: true,
                                showSearch: true,
                                style: { width: "100%" },
                                placeholder: "Search to Select",
                                options: packageTypes?.map((packageType) => {
                                  return {
                                    label: packageType?.name,
                                    value: packageType?.id,
                                  };
                                }),
                                onModifyClick: () => {
                                  navigate("/packagetype");
                                },
                              }}
                              rules={{ required: "This field is required" }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFTextField
                              formItemProps={{
                                label: "Name",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: "name",
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFSelect
                              formItemProps={{ label: "Package Status" }}
                              controllerProps={{
                                name: `packageStatusId`,
                              }}
                              selectProps={{
                                allowClear: true,
                                showSearch: true,
                                style: { width: "100%" },
                                placeholder: "Search to Select",
                                options: packageStatus?.map((packageStatu) => {
                                  return {
                                    label: packageStatu?.name,
                                    value: packageStatu?.id,
                                  };
                                }),
                                onModifyClick: () => {
                                  navigate("/packagestatus");
                                },
                              }}
                              rules={{ required: "This field is required" }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFDatePicker
                              formItemProps={{
                                label: "Submitted Date",
                              }}
                              controllerProps={{
                                name: `submittedDate`,
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFDatePicker
                              formItemProps={{
                                label: "Re-Credentialing Date",
                              }}
                              controllerProps={{
                                name: `recredentialingDate`,
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFSelect
                              formItemProps={{ label: "Follow Up Date" }}
                              controllerProps={{
                                name: `alertDays`,
                              }}
                              selectProps={{
                                allowClear: true,
                                showSearch: true,
                                style: { width: "100%" },
                                placeholder: "Search to Select",
                                options: alertReminderOptions?.map((alert) => {
                                  return {
                                    label: alert?.label,
                                    value: alert?.value,
                                  };
                                }),
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </>
                );
              }}
            </Form.List>
          </Form>
        </FormProvider>
      </IncredableContent>
      <IncredableContent>
        <DocumentList
          documents={credentialPackage?.document ?? []}
          selectedDocuments={selectedDocuments}
          setSelectDocuments={setSelectedDocuments}
        />
      </IncredableContent>
    </>
  );
}

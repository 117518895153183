import { WidgetConfig } from "../types";
import { BarChart } from "@cloudscape-design/components";

export const usageByFacility: WidgetConfig = {
  title: "Payer enrollments by status",
  description: "An overview of the payer enrollments breakdown by status.",
  header: UsageByFacilityHeader,
  content: UsageByFacilityContent,
};

function UsageByFacilityHeader() {
  return (
    <div
      style={{
        borderRadius: "4px",
        padding: "0px 10px 0 18px",
      }}
    >
      <h3>{usageByFacility.title}</h3>
      <p>{usageByFacility.description}</p>
    </div>
  );
}

function UsageByFacilityContent() {
  return (
    <div style={{ padding: "24px" }}>
      <BarChart
        series={[
          {
            title: "Payers count",
            type: "bar",
            data: [
              { x: "one", y: 34503 },
              { x: "two", y: 25832 },
              { x: "three", y: 4012 },
              { x: "four", y: 5602 },
              { x: "five", y: 17839 },
              { x: "six", y: 22034 },
              { x: "seven", y: 12783 },
              { x: "eight", y: 8764 },
              { x: "nine", y: 30452 },
              { x: "ten", y: 15034 },
              { x: "eleven", y: 9453 },
              { x: "twelve", y: 18734 },
              { x: "thirteen", y: 22789 },
              { x: "fourteen", y: 13423 },
              { x: "fifteen", y: 24895 },
              { x: "sixteen", y: 19302 },
              { x: "seventeen", y: 8304 },
              { x: "eighteen", y: 15093 },
              { x: "nineteen", y: 20547 },
              { x: "twenty", y: 17045 },
              { x: "twenty-one", y: 27345 },
              { x: "twenty-two", y: 10294 },
              { x: "twenty-three", y: 45012 },
              { x: "twenty-four", y: 30982 },
              { x: "twenty-five", y: 19283 },
              { x: "twenty-six", y: 22345 },
              { x: "twenty-seven", y: 18473 },
              { x: "twenty-eight", y: 32108 },
              { x: "twenty-nine", y: 14502 },
              { x: "thirty", y: 29345 },
              { x: "thirty-one", y: 12983 },
              { x: "thirty-two", y: 20934 },
              { x: "thirty-three", y: 31029 },
              { x: "thirty-four", y: 18923 },
              { x: "thirty-five", y: 25672 },
              { x: "thirty-six", y: 30784 },
              { x: "thirty-seven", y: 14593 },
              { x: "thirty-eight", y: 19023 },
              { x: "thirty-nine", y: 21834 },
              { x: "forty", y: 17892 },
              { x: "forty-one", y: 29845 },
              { x: "forty-two", y: 14302 },
              { x: "forty-three", y: 21034 },
              { x: "forty-four", y: 32108 },
              { x: "forty-five", y: 15403 },
              { x: "forty-six", y: 28574 },
              { x: "forty-seven", y: 19602 },
              { x: "forty-eight", y: 23094 },
              { x: "forty-nine", y: 12784 },
              { x: "fifty", y: 28093 },
            ],
            color: "#2B6BE6",
          },
        ]}
        ariaLabel="Single data series line chart"
        height={280}
        xTitle="Enrollment Status"
        yTitle="Enrollments count"
        hideFilter
        hideLegend
      />
    </div>
  );
}

import ProviderCard from "../../components/providerCard/provider-card";
import { Images } from "../../common/images";
import { useGetProviderQuery } from "../../redux/api/provider/provider";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { useMemo } from "react";

export default function PersonalInfo() {
  const { providerId } = useParams();
  const navigate = useNavigate();

  const { data: selectedProvider } = useGetProviderQuery(
    !!providerId
      ? {
        providerId: providerId,
      }
      : skipToken
  );

  const personalInfo = useMemo(() => [
    { label: "Middle Name", value: selectedProvider?.personalInfo?.middleName || "-" },
    { label: "Has Preferred Name", value: selectedProvider?.personalInfo?.hasOtherName === true ? "Yes" : "No" },
    { label: "Other Name", value: selectedProvider?.personalInfo?.otherName || "-" },
    { label: "Degree", value: selectedProvider?.personalInfo?.degree?.name || "-" },
    { label: "Gender", value: selectedProvider?.personalInfo?.gender || "-" },
    { label: "Date of Birth", value: selectedProvider?.personalInfo?.dob || "-" },
    { label: "Place of Birth", value: selectedProvider?.personalInfo?.placeOfBirth || "-" },
    { label: "SSN", value: selectedProvider?.personalInfo?.ssn || "-" },
    { label: "Passport No", value: selectedProvider?.personalInfo?.passportNo || "-" },
    { label: "Citizenship", value: selectedProvider?.personalInfo?.citizenship || "-" },
    { label: "Mobile", value: selectedProvider?.personalInfo?.mobile || "-" },
    { label: "Personal Email", value: selectedProvider?.personalInfo?.email || "-" },
    { label: "Home Phone", value: selectedProvider?.personalInfo?.homePhone || "-" },
    { label: "Have you ever served in the US Military?", value: selectedProvider?.personalInfo?.hasWorkedInMilitary === true ? "Yes" : "No" },
    { label: "Military Date of Service", value: selectedProvider?.personalInfo?.militaryDateOfService || "-" },
    { label: "Branch of Military", value: selectedProvider?.personalInfo?.branchOfMilitary || "-" },
    { label: "Fax", value: selectedProvider?.personalInfo?.fax || "-" },
    { label: "Address lane 1", value: selectedProvider?.personalInfo?.addressLane1 || "-" },
    { label: "Address lane 2", value: selectedProvider?.personalInfo?.addressLane2 || "-" },
    { label: "City", value: selectedProvider?.personalInfo?.city || "-" },
    { label: "State", value: selectedProvider?.personalInfo?.state || "-" },
    { label: "County", value: selectedProvider?.personalInfo?.county || "-" },
    { label: "Country", value: selectedProvider?.personalInfo?.country || "-" },
    { label: "Zip Code", value: selectedProvider?.personalInfo?.zipcode || "-" },
  ], [selectedProvider]);

  return (
    <ProviderCard
      title="Personal Information"
      extraButton={{
        text: "Edit",
        icon: Images.edit2,
        onClick: () => {
          if (!!providerId) navigate(`/manageprovider/${providerId}/personalinfo/edit`);
        },
      }}
      data={personalInfo}
    />
  );
}

import { useParams } from "react-router-dom";
import { useGetAllDigitalFormsQuery } from "../../../redux/api/digitalform/digitalforms";
import { useGetAllCredentialTemplateFormsQuery, useUpdateCredentialTemplateFormsMutation } from "../../../redux/api/credentialpackages/credentialpackages";
import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect, useMemo, useState } from "react";
import { DigitalForm } from "../../../redux/api/digitalform/types";
import { Col, Modal, Row, Table, Typography } from "antd";
import IncredableButton from "../../../components/button";
import { TableRowSelection } from "antd/es/table/interface";
export const addformColumns = [
    {
        title: () => <Typography.Text>Name</Typography.Text>,
        dataIndex: "name",
        key: "name",
        ellipsis: true,
        render: (text: string) => (
            <Typography.Text strong>{text || "-"}</Typography.Text>
        ),
    },
    {
        title: () => <Typography.Text>Type</Typography.Text>,
        dataIndex: ["digitalFormType", "type"],
        key: "type",
        ellipsis: true,
        render: (text: string) => (
            <Typography.Text strong>{text || "-"}</Typography.Text>
        ),
    }
]

export default function AddFormModal({
    visible,
    closeModal,
}: {
    visible: boolean,
    closeModal: VoidFunction
}) {
    const { data: digitalForms = [], isLoading, isFetching } = useGetAllDigitalFormsQuery();
    const { templateId } = useParams();
    const { data: templateforms = [] } = useGetAllCredentialTemplateFormsQuery(
        templateId ? { credentialTemplateId: templateId } : skipToken,
    );
    const [updateCredentialTemplateForms] =
        useUpdateCredentialTemplateFormsMutation();

    const templateFormIdList = useMemo(() => {
        return templateforms.map((forms) => forms.id + "");
    }, [templateforms]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    useEffect(() => {
        setSelectedRowKeys([]);
    }, [closeModal]);
    const rowSelection: TableRowSelection<DigitalForm> = {
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: (record: DigitalForm) => ({
            disabled: templateFormIdList?.includes(record?.id + ""),
        }),
    };
    const selectRow = (record: DigitalForm) => {
        const element = selectedRowKeys.find(form => form == record?.id + "")
        const disabled =templateFormIdList?.includes(record?.id + "")
        if (!element && !disabled) {
            setSelectedRowKeys([...selectedRowKeys, record?.id])
        }
    };
    return (
        <Modal
            title={"Add Forms"}
            onCancel={closeModal}
            open={visible}
            footer={
                <Row justify="end" gutter={16}>
                    <Col>
                        <IncredableButton secondary onClick={closeModal}>
                            Cancel
                        </IncredableButton>
                    </Col>
                    <Col>
                        <IncredableButton type="primary" onClick={() => {
                            if (templateId) {
                                const formIds = selectedRowKeys.map((form) => form + "");
                                updateCredentialTemplateForms({
                                    id: templateId,
                                    forms: formIds,
                                });
                            }
                            closeModal();
                        }}>
                            Save
                        </IncredableButton>
                    </Col>
                </Row>
            }
        >
            <Table
                style={{
                    boxShadow: "0px 10px 25px 0px #5911920D",
                    borderRadius: "16px",
                    border: "1px solid #E5E7EB",
                    overflow: "hidden",
                }}
                size="large"
                columns={addformColumns}
                loading={
                    isLoading || isFetching
                }
                rowKey="id"
                dataSource={!!digitalForms ? [...digitalForms] : []}
                onRow={(record) => ({
                    onClick: () => {
                        selectRow(record)
                    },
                })}
                rowSelection={rowSelection}
            />
        </Modal>
    )
}
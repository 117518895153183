import { Space, Spin, Table, theme, Typography } from "antd";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TableTitle from "../../components/incredable-table/table-title";
import {
    CalendarOutlined,
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import { TablePreferenceModal } from "../../common/TablePreferenceModal";
import IncredableButton from "../../components/button";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import IncredableContent from "../../components/incredable-content";
import { Specialty } from "../../redux/api/provider/types"
import DeleteAlertModal from "../../common/Modal/delete-alert-modal";
import { useDeleteSpecialtyMutation, useGetAllSpecialtyQuery } from "../../redux/api/specialty/specialty";

const { getDesignToken } = theme;
const globalToken = getDesignToken();

export const specialtyColumns = [
    {
        title: () => <Typography.Text>Name</Typography.Text>,
        key: "name",
        ellipsis: true,
        dataIndex: ["name"],
        render: (name: string) => <Typography.Text>{name || "-"}</Typography.Text>,
        sorter: (a: Specialty, b: Specialty) => {
            return a?.name?.toLowerCase()?.localeCompare(b?.name?.toLowerCase());
        },
    },
    {
        title: () => <Typography.Text>Description</Typography.Text>,
        key: "description",
        ellipsis: true,
        dataIndex: ["description"],
        render: (des: string) => <Typography.Text>{des || "-"}</Typography.Text>,
    },
];

export default function SpecialtyList() {
    const navigate = useNavigate();
    const [selectedRow, setSelectedRow] = useState<Specialty>();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [selectedColumns, setSelectedColumns] = useState<string[]>(
        specialtyColumns.map((col) => col.key as string)
    );
    const [visibleColumnOrder, setVisibleColumnOrder] = useState<string[]>(
        specialtyColumns.map((col) => col.key as string)
    );
    const spinnerContext = useContext(SpinnerContext);
    const toastContext = useContext(ToastContext);
    const [deleteSpecialty] = useDeleteSpecialtyMutation();

    const {
        data: specialty,
        isLoading,
        isFetching,
        error,
        refetch,
    } = useGetAllSpecialtyQuery();

    const visibleColumns = visibleColumnOrder
        .filter((colKey) => selectedColumns.includes(colKey))
        .map((colKey) => specialtyColumns.find((col) => col.key === colKey)!);

    if (isLoading) {
        return <Spin />;
    }

    if (error) {
        return <div>Error loading specialty</div>;
    }
    const selectRow = (record: Specialty) => {
        if (selectedRow?.id + "" === record?.id + "") {
            setSelectedRow(undefined);
        } else {
            setSelectedRow(record);
        }
    };

    const handleOpenModal = () => {
        setIsModalVisible(true);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
    };

    const handleOpenDeleteModal = () => {
        setDeleteModalOpen(true);
    };

    const handleSavePreferences = (
        selectedColumns: string[],
        columnOrder: string[]
    ) => {
        setSelectedColumns(selectedColumns);
        setVisibleColumnOrder(columnOrder);
        handleCloseModal();
    };

    return (
        <>
            <IncredableButton style={{
                margin: "12px 0 0 48px",
                padding: "12px",
            }}
                secondary
                onClick={() => navigate(-1)}
            >
                Go back
            </IncredableButton>
            <IncredableContent style={{ margin: "12px 0 0 48px" }} spinning={isLoading || isFetching}>
                <Table
                    size="small"
                    rowKey="id"
                    dataSource={
                        !!specialty
                            ? [...specialty]
                            : []
                    }
                    columns={visibleColumns}
                    loading={isLoading || isFetching}
                    onRow={(record) => ({
                        onClick: () => {
                            selectRow(record);
                        },
                    })}
                    rowSelection={{
                        type: "radio",
                        selectedRowKeys: !!selectedRow?.id ? [selectedRow?.id] : [],
                        onChange: (selectedRowKeys, selectedRows, info) => {
                            if (!!selectedRows && !!selectedRows?.at(0))
                                setSelectedRow(selectedRows?.at(0));
                        },
                        columnWidth: 60,
                    }}
                    pagination={{
                        position: ["bottomRight"],
                        style: { backgroundColor: "white", padding: "10px", margin: "0" },
                        pageSizeOptions: [10, 25, 50],
                        showSizeChanger: true,
                        total: specialty?.length,
                        showTotal: (total) => `Total ${total} items`,
                    }}
                    scroll={{ y: 400, x: "100vw" }}
                    title={() => (
                        <Space
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <span style={{ fontSize: "22px", fontWeight: "700" }}>
                                {`Specialty (${specialty?.length})`}
                            </span>
                            <Space>
                                <IncredableButton
                                    onClick={() =>
                                        navigate(`add`)
                                    }
                                    type="primary"
                                    icon={<PlusOutlined />}
                                    size="middle"
                                    style={{
                                        backgroundColor: "#598BEB",
                                        border: "2px solid #598BEB",
                                        boxShadow: "none",
                                    }}
                                >
                                    Add Specialty
                                </IncredableButton>
                                <TableTitle
                                    filter={[]}
                                    actionDropDownProps={{
                                        disabled: !selectedRow,
                                        menu: {
                                            items: [
                                                {
                                                    label: "Edit",
                                                    key: "edit",
                                                    icon: <EditOutlined />,
                                                },
                                                {
                                                    label: "Delete",
                                                    key: "delete",
                                                    icon: <DeleteOutlined />,
                                                    danger: true,
                                                },
                                            ],
                                            onClick: async (e) => {
                                                if (e?.key === "edit") {
                                                    navigate(`${selectedRow?.id}/edit`);
                                                } else if (e?.key === "delete") {
                                                    handleOpenDeleteModal();
                                                }
                                            },
                                        },
                                    }}
                                    onSettingsClick={handleOpenModal}
                                    onReloadClick={refetch}
                                />
                            </Space>
                            <TablePreferenceModal
                                visible={isModalVisible}
                                onCancel={handleCloseModal}
                                onSave={handleSavePreferences}
                                availableColumns={specialtyColumns.map((col) => col.key as string)}
                                selectedColumns={selectedColumns}
                                columnOrderState={visibleColumnOrder}
                            />
                            <DeleteAlertModal
                                visible={isDeleteModalOpen}
                                action={async () => {
                                    if (selectedRow?.id) {
                                        setDeleteModalOpen(false);
                                        spinnerContext?.showSpinner();
                                        await deleteSpecialty({
                                            id: selectedRow?.id,
                                        });
                                        spinnerContext?.hidespinner();
                                        toastContext?.openSuccessNotification({
                                            message: `Specialty deleted successfully`,
                                            placement: "topRight",
                                        });
                                    }
                                }}
                                closeModal={() => setDeleteModalOpen(false)}
                                header="Delete Specialty"
                                content={
                                    <Typography.Text>
                                        Specialty Name:{" "}
                                        <Typography.Text>{selectedRow?.name}</Typography.Text>
                                    </Typography.Text>
                                }
                                description="Are you sure you want to delete this specialty?"
                            />
                        </Space>
                    )}
                />
            </IncredableContent>
        </>
    );
}

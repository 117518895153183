import { SearchOutlined } from "@ant-design/icons";
import { Input, Select, Space } from "antd";
import { useEffect, useMemo, useState } from "react";
import {
  useGetAllWorkflowQuery,
  useGetAllWorkflowTemplateQuery,
} from "../redux/api/workflow/workflow";
import { useGetBasicAllProvidersQuery } from "../redux/api/provider/provider";

export default function useWorkflowFilter() {
  const [selectedFilterkey, setSelectedFilterKey] = useState<string>("name");
  const [selectedFiltervalue, setSelectedFiltervalue] = useState<
    string | undefined
  >(undefined);

  const { data: providers, isLoading: providersLoading } =
    useGetBasicAllProvidersQuery();
  const { data: workflowTemplate, isLoading: templateLoading } =
    useGetAllWorkflowTemplateQuery();
  const {
    data: workflows,
    refetch,
    isLoading: workflowLoading,
    isFetching,
  } = useGetAllWorkflowQuery();

  const filteredWorkflow = useMemo(() => {
    if (!!selectedFiltervalue) {
      if (selectedFilterkey === "name")
        return workflows?.filter((workflow) =>
          workflow?.name
            ?.toLowerCase()
            .includes(selectedFiltervalue?.toLowerCase())
        );
      else if (selectedFilterkey === "status")
        return workflows?.filter((workflow) => workflow?.workFlowStatus?.name);
      else if (selectedFilterkey === "workflowTemplate")
        return workflows?.filter(
          (workflow) =>
            workflow?.workflowTemplate?.id + "" === selectedFiltervalue + ""
        );
      else if (selectedFilterkey === "provider")
        return workflows?.filter(
          (workflow) => workflow?.provider?.id + "" === selectedFiltervalue + ""
        );
    }
    return workflows;
  }, [selectedFiltervalue, workflows]);

  useEffect(() => {
    setSelectedFiltervalue(undefined);
  }, [selectedFilterkey]);

  const TableFilters = (
    <Space size="small">
      {selectedFilterkey === "name" && (
        <Input
          onChange={(event) => {
            setSelectedFiltervalue(event?.target?.value);
          }}
          value={selectedFiltervalue}
          style={{
            width: "320px",
          }}
          placeholder="Find Workflow"
          prefix={<SearchOutlined />}
        />
      )}
      {selectedFilterkey === "status" && (
        <Select
          allowClear
          showSearch
          optionFilterProp="label"
          key="status-select"
          value={selectedFiltervalue}
          placeholder="Select status"
          onChange={(value) => {
            setSelectedFiltervalue(value);
          }}
          style={{ width: "320px" }}
          options={[
            { value: "Pending", label: "Pending" },
            { value: "Completed", label: "Completed" },
            { value: "In progress", label: "In Progress" },
          ]}
        />
      )}
      {selectedFilterkey === "workflowTemplate" && (
        <Select
          allowClear={true}
          showSearch={true}
          optionFilterProp="label"
          value={selectedFiltervalue}
          placeholder="Select Template"
          onChange={(value) => {
            setSelectedFiltervalue(value);
          }}
          style={{ width: "320px" }}
          options={workflowTemplate?.map((template) => ({
            label: template?.name,
            searchValue: template?.name,
            value: template?.id + "",
          }))}
        />
      )}
      {selectedFilterkey === "provider" && (
        <Select
          allowClear={true}
          showSearch={true}
          optionFilterProp="label"
          value={selectedFiltervalue}
          placeholder="Select Provider"
          onChange={(value) => {
            setSelectedFiltervalue(value);
          }}
          style={{ width: "320px" }}
          options={providers?.map((provider) => ({
            label: `${provider?.firstName} ${provider?.lastName}`,
            searchValue: `${provider?.firstName} ${provider?.lastName}`,
            value: provider?.id + "",
          }))}
        />
      )}
      <Select
        value={selectedFilterkey}
        onChange={(value) => {
          setSelectedFilterKey(value);
        }}
        style={{ width: "140px" }}
        options={[
          { value: "name", label: "Name" },
          { value: "status", label: "Status" },
          { value: "workflowTemplate", label: "Workflow Template" },
          { value: "provider", label: "Provider" },
        ]}
      />
    </Space>
  );

  return {
    TableFilters,
    filteredWorkflow,
    UseQueryHookResult: {
      refetch,
      isLoading: workflowLoading && providersLoading,
      isFetching,
    },
  };
}

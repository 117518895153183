import {
  BaseQueryApi,
  FetchArgs,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { HOST_API } from "../../config";
import * as Sentry from "@sentry/react";

const baseQuery = fetchBaseQuery({
  baseUrl: HOST_API + "/api/",
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    // The Authorization token is only set in the header when the application is running locally on localhost:3000.
    // Once deployed on the server, authorization is handled through cookie-based authentication.
    const accessToken = localStorage.getItem("accessToken");
    if (!!accessToken && window.location.host === "localhost:3000")
      headers.set("Authorization", `Bearer ${accessToken}`);
    return headers;
  },
});

const baseQueryWithErrorLogger = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {}
) => {
  let result = await baseQuery(args, api, extraOptions);
  if (!!result.error) {
    Sentry.captureException(result.error);
  }
  return result;
};

// initialize an empty api service that we'll inject endpoints into later as needed
export const api = createApi({
  baseQuery: baseQueryWithErrorLogger,
  tagTypes: [
    "BoardName",
    "Affiliation",
    "AffiliationType",
    "AffiliationStatus",
    "DigitalFormType",
    "FacilityType",
    "Attachment",
    "Category",
    "Comments",
    "Contract",
    "Credentialing",
    "Dashboard",
    "DigitalForm",
    "Document",
    "Facility",
    "FacilityGroup",
    "SignatureRequest",
    "LicenseType",
    "Invitation",
    "Admin",
    "PayerEnrollment",
    "Payergroup",
    "Payers",
    "Privilege",
    "UserProfile",
    "Provider",
    "Reporting",
    "Role",
    "Permission",
    "Specialty",
    "Task",
    "Workflow",
    "Organization",
    "DocumentRequest",
  ],
  endpoints: () => ({}),
});

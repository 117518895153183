import { Space, Table, theme, Typography } from "antd";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TableTitle from "../../components/incredable-table/table-title";
import {
  CalendarOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { TablePreferenceModal } from "../../common/TablePreferenceModal";
import { ProviderLiabilityInsuranceType } from "../../redux/api/provider/types";
import {
  useDeleteProviderLiabilityInsuranceMutation,
  useGetProviderQuery,
} from "../../redux/api/provider/provider";
import { skipToken } from "@reduxjs/toolkit/query";
import IncredableButton from "../../components/button";
import IncredableDateFormat from "../../common/incredable-date-format";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import DeleteAlertModal from "../../common/Modal/delete-alert-modal";

export const liabilityInsuranceColumns = [
  {
    title: () => <Typography.Text>Name</Typography.Text>,
    key: "name",
    width: "120px",
    ellipsis: true,
    render: (liabilityInsurance: ProviderLiabilityInsuranceType) => (
      <Typography.Link href="#">{liabilityInsurance?.name}</Typography.Link>
    ),
    sorter: (
      a: ProviderLiabilityInsuranceType,
      b: ProviderLiabilityInsuranceType
    ) => a?.name?.toLowerCase()?.localeCompare(b?.name?.toLowerCase()),
  },
  {
    title: () => <Typography.Text>Policy Number</Typography.Text>,
    dataIndex: "policyNumber",
    key: "policyNumber",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Self Insured</Typography.Text>,
    dataIndex: "selfInsured",
    key: "selfInsured",
    ellipsis: true,
    render: (selfInsured: boolean | null) => (
      <Typography.Text>{selfInsured === true ? "Yes" : "No"}</Typography.Text>
    ),
  },
  {
    title: () => <Typography.Text>Effective Date</Typography.Text>,
    dataIndex: "effectiveDate",
    key: "effectiveDate",
    ellipsis: true,
    render: (effectiveDate: string | null) => (
      <Typography.Text>
        {effectiveDate ? (
          <Space>
            {" "}
            <CalendarOutlined /> {IncredableDateFormat(effectiveDate)}
          </Space>
        ) : (
          "-"
        )}
      </Typography.Text>
    ),
    sorter: (a: ProviderLiabilityInsuranceType, b: ProviderLiabilityInsuranceType) =>
      new Date(a?.effectiveDate).getTime() - new Date(b?.effectiveDate).getTime()
  },
  {
    title: () => <Typography.Text>Expiration Date</Typography.Text>,
    dataIndex: "expirationDate",
    key: "expirationDate",
    ellipsis: true,
    render: (expirationDate: string | null) => (
      <Typography.Text>
        {expirationDate ? (
          <Space>
            {" "}
            <CalendarOutlined /> {IncredableDateFormat(expirationDate)}
          </Space>
        ) : (
          "-"
        )}
      </Typography.Text>
    ),
    sorter: (a: ProviderLiabilityInsuranceType, b: ProviderLiabilityInsuranceType) =>
      new Date(a?.expirationDate).getTime() - new Date(b?.expirationDate).getTime()
  },
  {
    title: () => <Typography.Text>Type Of Coverage</Typography.Text>,
    dataIndex: "typeOfCoverage",
    key: "typeOfCoverage",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => (
      <Typography.Text>Time With Current Carrier Value</Typography.Text>
    ),
    dataIndex: "timeWithCurrentCarrierValue",
    key: "timeWithCurrentCarrierValue",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => (
      <Typography.Text>Time With Current Carrier Unit</Typography.Text>
    ),
    dataIndex: "timeWithCurrentCarrierUnit",
    key: "timeWithCurrentCarrierUnit",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Coverage Per Occurance</Typography.Text>,
    dataIndex: "coveragePerOccurance",
    key: "coveragePerOccurance",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Aggregate Coverage</Typography.Text>,
    dataIndex: "aggregateCoverage",
    key: "aggregateCoverage",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Email</Typography.Text>,
    dataIndex: "email",
    key: "email",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Phone</Typography.Text>,
    dataIndex: "phone",
    key: "phone",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Fax</Typography.Text>,
    dataIndex: "fax",
    key: "fax",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Address Lane 1</Typography.Text>,
    dataIndex: "addressLane1",
    key: "addressLane1",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Address Lane 2</Typography.Text>,
    dataIndex: "addressLane2",
    key: "addressLane2",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>City</Typography.Text>,
    dataIndex: "city",
    key: "city",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>State</Typography.Text>,
    dataIndex: "state",
    key: "state",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Country</Typography.Text>,
    dataIndex: "country",
    key: "country",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Zip</Typography.Text>,
    dataIndex: "zip",
    key: "zip",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
];

export default function LiabilityInsurance() {
  const { providerId } = useParams();
  const [selectedRow, setSelectedRow] =
    useState<ProviderLiabilityInsuranceType>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const filteredColumns = [
    "name",
    "policyNumber",
    "effectiveDate",
    "expirationDate",
    "typeOfCoverage",
    "email",
    "phone",
  ];
  const [selectedColumns, setSelectedColumns] =
    useState<string[]>(filteredColumns);
  const [visibleColumnOrder, setVisibleColumnOrder] = useState<string[]>(
    liabilityInsuranceColumns.map((col) => col.key as string)
  );

  const {
    data: provider,
    isLoading,
    isFetching,
  } = useGetProviderQuery(
    !!providerId
      ? {
        providerId: providerId,
      }
      : skipToken
  );
  const navigate = useNavigate();
  const selectRow = (record: ProviderLiabilityInsuranceType) => {
    if (selectedRow?.id + "" === record?.id + "") {
      setSelectedRow(undefined);
    } else {
      setSelectedRow(record);
    }
  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const handleSavePreferences = (
    selectedColumns: string[],
    columnOrder: string[]
  ) => {
    setSelectedColumns(selectedColumns);
    setVisibleColumnOrder(columnOrder);
    handleCloseModal();
  };

  const visibleColumns = visibleColumnOrder
    .filter((colKey) => selectedColumns.includes(colKey))
    .map(
      (colKey) => liabilityInsuranceColumns.find((col) => col.key === colKey)!
    );

  const [deleteProviderLiabilityInsurance] =
    useDeleteProviderLiabilityInsuranceMutation();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  return (
    <>
      <Table
        style={{
          boxShadow: "0px 10px 25px 0px #5911920D",
          borderRadius: "16px",
          border: "1px solid #E5E7EB",
          overflow: "hidden",
        }}
        size="small"
        columns={visibleColumns}
        loading={isLoading || isFetching}
        rowKey="id"
        dataSource={
          !!provider?.providerLiabilityInsurances
            ? [...provider?.providerLiabilityInsurances]
            : []
        }
        onRow={(record) => ({
          onClick: () => {
            selectRow(record);
          },
        })}
        rowSelection={{
          type: "radio",
          selectedRowKeys: !!selectedRow?.id ? [selectedRow?.id] : [],
          onChange: (selectedRowKeys, selectedRows, info) => {
            if (!!selectedRows && !!selectedRows?.at(0))
              setSelectedRow(selectedRows?.at(0));
          },
          columnWidth: 60,
        }}
        pagination={{
          position: ["bottomRight"],
          style: { backgroundColor: "white", padding: "10px", margin: "0" },
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          total: provider?.providerLiabilityInsurances?.length,
          showTotal: (total) => `Total ${total} items`,
        }}
        title={() => (
          <Space
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span style={{ fontSize: "22px", fontWeight: "700" }}>
              {`Liability Insurance (${provider?.providerLiabilityInsurances?.length})`}
            </span>
            <Space>
              <IncredableButton
                onClick={() =>
                  navigate(
                    `/manageprovider/${providerId}/liabilityinsurance/add`
                  )
                }
                type="primary"
                icon={<PlusOutlined />}
                size="middle"
                style={{
                  backgroundColor: "#598BEB",
                  border: "2px solid #598BEB",
                  boxShadow: "none",
                }}
              >
                Add Liability Insurance
              </IncredableButton>
              <TableTitle
                filter={[]}
                actionDropDownProps={{
                  disabled: !selectedRow,
                  menu: {
                    items: [
                      {
                        label: "Edit",
                        key: "edit",
                        icon: <EditOutlined />,
                      },
                      {
                        label: "Delete",
                        key: "delete",
                        icon: <DeleteOutlined />,
                        danger: true,
                      },
                    ],
                    onClick: async (e) => {
                      if (e?.key === "edit") {
                        navigate(`${selectedRow?.id}/edit`);
                      }
                      if (e?.key === "delete") {
                        handleOpenDeleteModal();
                      }
                    },
                  },
                }}
                onSettingsClick={handleOpenModal}
              />
            </Space>
          </Space>
        )}
        scroll={{ y: 400, x: "100vw" }}
      />
      <TablePreferenceModal
        visible={isModalVisible}
        onCancel={handleCloseModal}
        onSave={handleSavePreferences}
        availableColumns={liabilityInsuranceColumns.map(
          (col) => col.key as string
        )}
        selectedColumns={selectedColumns}
        columnOrderState={visibleColumnOrder}
      />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (!!providerId && !!selectedRow?.id) {
            setDeleteModalOpen(false);
            spinnerContext?.showSpinner();
            await deleteProviderLiabilityInsurance({
              providerId: providerId,
              liabilityInsuranceId: selectedRow?.id + "",
            });
            spinnerContext?.hidespinner();
            toastContext?.openSuccessNotification({
              message: `Liability Insurance deleted successfully`,
              placement: "topRight",
            });
          }
        }}
        closeModal={() => setDeleteModalOpen(false)}
        header="Delete Liability Insurance"
        content={
          <Typography.Text>
            Name:{" "}
            <Typography.Text strong>{selectedRow?.name}</Typography.Text>
          </Typography.Text>
        }
        description="Are you sure you want to delete this liability insurance?"
      />
    </>
  );
}

import { message, Space, Table, theme, Typography } from "antd";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TableTitle from "../../components/incredable-table/table-title";
import {
  CalendarOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { TablePreferenceModal } from "../../common/TablePreferenceModal";
import {
  useGetAllProviderEnrollmentsQuery,
} from "../../redux/api/provider/provider";
import { skipToken } from "@reduxjs/toolkit/query";
import IncredableButton from "../../components/button";
import { PayerEnrollment } from "../../redux/api/payerenrollment/types";
import DeleteAlertModal from "../../common/Modal/delete-alert-modal";
import { useDeletePayerEnrollmentMutation } from "../../redux/api/payerenrollment/payerenrollment";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import IncredableDateFormat from "../../common/incredable-date-format";

const { useToken } = theme;

export const enrolledPayerColumns = [
  {
    title: () => <Typography.Text>Payer</Typography.Text>,
    key: "name",
    width: "120px",
    ellipsis: true,
    render: (enrolledPayer: PayerEnrollment) => (
      <Typography.Link href="#">{enrolledPayer?.payer.name}</Typography.Link>
    ),
    sorter: (a: PayerEnrollment, b: PayerEnrollment) =>
      a?.payer?.name
        ?.toLowerCase()
        ?.localeCompare(b?.payer?.name?.toLowerCase()),
  },
  {
    title: () => <Typography.Text>Payer Provider ID</Typography.Text>,
    dataIndex: "payerProviderId",
    key: "payerProviderId",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Provider</Typography.Text>,
    dataIndex: ["provider"],
    key: "provider",
    ellipsis: true,
    render: (provider: { firstName: string; lastName: string }) => (
      <Typography.Text>
        {provider?.firstName && provider?.lastName
          ? `${provider.firstName} ${provider.lastName}`
          : "-"}
      </Typography.Text>)
  },
  {
    title: () => <Typography.Text>Network</Typography.Text>,
    dataIndex: ["payerGroup", "name"],
    key: "network",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
    sorter: (a: PayerEnrollment, b: PayerEnrollment) =>
      a?.payerGroup?.name
        ?.toLowerCase()
        ?.localeCompare(b?.payerGroup?.name?.toLowerCase()),
  },
  {
    title: () => <Typography.Text>Workflow</Typography.Text>,
    dataIndex: ["workflow", "name"],
    key: "workflow",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Facility</Typography.Text>,
    dataIndex: ["facility", "name"],
    key: "facility",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
    sorter: (a: PayerEnrollment, b: PayerEnrollment) =>
      a?.facility?.name
        ?.toLowerCase()
        ?.localeCompare(b?.facility?.name?.toLowerCase()),
  },
  {
    title: () => <Typography.Text>Facility Group</Typography.Text>,
    dataIndex: ["facilityGroup", "name"],
    key: "facilityGroup",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Status</Typography.Text>,
    dataIndex: ["status", "name"],
    key: "status",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
    sorter: (a: PayerEnrollment, b: PayerEnrollment) =>
      a?.status?.name
        ?.toLowerCase()
        ?.localeCompare(b?.status?.name?.toLowerCase()),
  },
  {
    title: () => <Typography.Text>Submitted Date</Typography.Text>,
    dataIndex: "submittedDate",
    key: "submittedDate",
    ellipsis: true,
    render: (text: string | null) => (
      <Typography.Text>
        {text ? (
          <Space>
            {" "}
            <CalendarOutlined /> {IncredableDateFormat(text)}
          </Space>
        ) : (
          "-"
        )}
      </Typography.Text>
    ),
    sorter: (a: PayerEnrollment, b: PayerEnrollment) =>
      new Date(a?.submittedDate).getTime() - new Date(b?.submittedDate).getTime()
  },
  {
    title: () => <Typography.Text>Approved Date</Typography.Text>,
    dataIndex: "approvedDate",
    key: "approvedDate",
    ellipsis: true,
    render: (text: string | null) => (
      <Typography.Text>
        {text ? (
          <Space>
            {" "}
            <CalendarOutlined /> {IncredableDateFormat(text)}
          </Space>
        ) : (
          "-"
        )}
      </Typography.Text>
    ),
    sorter: (a: PayerEnrollment, b: PayerEnrollment) =>
      new Date(a?.approvedDate).getTime() - new Date(b?.approvedDate).getTime()
  },
  {
    title: () => <Typography.Text>Effective Date</Typography.Text>,
    dataIndex: "effectiveDate",
    key: "effectiveDate",
    ellipsis: true,
    render: (text: string | null) => (
      <Typography.Text>
        {text ? (
          <Space>
            {" "}
            <CalendarOutlined /> {IncredableDateFormat(text)}
          </Space>
        ) : (
          "-"
        )}
      </Typography.Text>
    ),
    sorter: (a: PayerEnrollment, b: PayerEnrollment) =>
      new Date(a?.effectiveDate).getTime() - new Date(b?.effectiveDate).getTime()
  },
  {
    title: () => <Typography.Text>Re-Enrollment Date</Typography.Text>,
    dataIndex: "recredentialingDate",
    key: "recredentialingDate",
    ellipsis: true,
    render: (text: string | null) => (
      <Typography.Text>
        {text ? (
          <Space>
            {" "}
            <CalendarOutlined /> {IncredableDateFormat(text)}
          </Space>
        ) : (
          "-"
        )}
      </Typography.Text>
    ),
    sorter: (a: PayerEnrollment, b: PayerEnrollment) =>
      new Date(a?.recredentialingDate).getTime() - new Date(b?.recredentialingDate).getTime()
  },
  {
    title: () => <Typography.Text>Follow Up Date</Typography.Text>,
    dataIndex: "alertDays",
    key: "followUpDate",
    ellipsis: true,
    render: (text: number) => <Typography.Text>{text ? `${text} days` : "-"}</Typography.Text>,
    sorter: (a: PayerEnrollment, b: PayerEnrollment) =>
      a?.alertDays - (b?.alertDays)
  },
];

export default function EnrolledPayer() {
  const { providerId } = useParams();
  const [selectedRow, setSelectedRow] = useState<PayerEnrollment>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    enrolledPayerColumns.map((col) => col.key as string)
  );
  const [visibleColumnOrder, setVisibleColumnOrder] = useState<string[]>(
    enrolledPayerColumns.map((col) => col.key as string)
  );

  const {
    data: providerEnrollments,
    isLoading,
    isFetching,
  } = useGetAllProviderEnrollmentsQuery(
    !!providerId
      ? {
        providerId: providerId,
      }
      : skipToken
  );
  const navigate = useNavigate();
  const { token } = useToken();
  const selectRow = (record: PayerEnrollment) => {
    if (selectedRow?.id + "" === record?.id + "") {
      setSelectedRow(undefined);
    } else {
      setSelectedRow(record);
    }
  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const handleSavePreferences = (
    selectedColumns: string[],
    columnOrder: string[]
  ) => {
    setSelectedColumns(selectedColumns);
    setVisibleColumnOrder(columnOrder);
    handleCloseModal();
  };

  const visibleColumns = visibleColumnOrder
    .filter((colKey) => selectedColumns.includes(colKey))
    .map((colKey) => enrolledPayerColumns.find((col) => col.key === colKey)!);

  const [deleteEnrolledPayer] = useDeletePayerEnrollmentMutation();

  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  return (
    <>
      <Table
        style={{
          boxShadow: "0px 10px 25px 0px #5911920D",
          borderRadius: "16px",
          border: "1px solid #E5E7EB",
          overflow: "hidden",
        }}
        size="small"
        columns={visibleColumns}
        loading={isLoading || isFetching}
        rowKey="id"
        dataSource={providerEnrollments}
        onRow={(record) => ({
          onClick: () => {
            selectRow(record);
          },
        })}
        rowSelection={{
          type: "radio",
          selectedRowKeys: !!selectedRow?.id ? [selectedRow?.id] : [],
          onChange: (selectedRowKeys, selectedRows, info) => {
            if (!!selectedRows && !!selectedRows?.at(0))
              setSelectedRow(selectedRows?.at(0));
            navigate("");
          },
          columnWidth: 60,
        }}
        pagination={{
          position: ["bottomRight"],
          style: { backgroundColor: "white", padding: "10px", margin: "0" },
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          total: providerEnrollments?.length,
          showTotal: (total) => `Total ${total} items`,
        }}
        title={() => (
          <Space
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span style={{ fontSize: "22px", fontWeight: "700" }}>
              {`Enrolled Payers (${providerEnrollments?.length})`}
            </span>
            <Space>
              <IncredableButton
                onClick={() => navigate(`/manageprovider/${providerId}/enrolledpayer/add`)}
                type="primary"
                icon={<PlusOutlined />}
                size="middle"
                style={{
                  backgroundColor: "#598BEB",
                  border: "2px solid #598BEB",
                  boxShadow: "none",
                }}
              >
                Add Payer
              </IncredableButton>
              <TableTitle
                filter={[]}
                actionDropDownProps={{
                  disabled: !selectedRow?.id,
                  menu: {
                    items: [
                      {
                        label: "Edit",
                        key: "edit",
                        icon: <EditOutlined />,
                      },
                      {
                        label: "Delete",
                        key: "delete",
                        icon: <DeleteOutlined />,
                        danger: true,
                      },
                    ],
                    onClick: async (e) => {
                      if (e?.key === "edit") {
                        navigate(`${selectedRow?.id}/edit`);
                      }
                      if (e?.key === "delete") {
                        handleOpenDeleteModal();
                      }
                    },
                  },
                }}
                onSettingsClick={handleOpenModal}
              />
            </Space>
          </Space>
        )}
        scroll={{ y: 400, x: "100vw" }}
      />
      <TablePreferenceModal
        visible={isModalVisible}
        onCancel={handleCloseModal}
        onSave={handleSavePreferences}
        availableColumns={enrolledPayerColumns.map((col) => col.key as string)}
        selectedColumns={selectedColumns}
        columnOrderState={visibleColumnOrder}
      />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (!!providerId && !!selectedRow?.id) {
            setDeleteModalOpen(false);
            spinnerContext?.showSpinner();
            await deleteEnrolledPayer({
              payerEnrollmentId: selectedRow?.id + "",
            });
            spinnerContext?.hidespinner();
            toastContext?.openSuccessNotification({
              message: `Enrolled Payer deleted successfully`,
              placement: "topRight",
            });
          }
        }}
        closeModal={() => setDeleteModalOpen(false)}
        header="Delete Enrolled Payer"
        content={
          <Typography.Text>
            Payer Name:{" "}
            <Typography.Text strong>{selectedRow?.payer?.name}</Typography.Text>
          </Typography.Text>
        }
        description="Are you sure you want to delete this enrolled payer?"
      />
    </>
  );
}

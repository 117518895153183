import { SearchOutlined } from "@ant-design/icons";
import { Input, Select, Space } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useGetAllPayerGroupQuery } from "../redux/api/payergroup/payergroup";
import { PayerGroup } from "../redux/api/payergroup/types";

export default function usePayerGroupFilter(items?: PayerGroup[]) {
    const [selectedFilterkey, setSelectedFilterKey] = useState<string>("name");
    const [selectedFiltervalue, setSelectedFiltervalue] = useState<
        string | undefined
    >(undefined);

    const {
        data: payergroup = [],
        refetch,
        isLoading: payergroupLoading,
        isFetching,
    } = useGetAllPayerGroupQuery();
    const [filteredPayerGroup, setFilteredPayerGroup] = useState<PayerGroup[]>(items ?? payergroup)
    useEffect(() => {
        setFilteredPayerGroup(items ?? payergroup);
    }, [payergroup, items]);


    useEffect(() => {
        let temp_filtered_payergroup = !!items ? [...items] : [...payergroup];
        if (!!selectedFiltervalue) {
            if (selectedFilterkey === "name") {
                temp_filtered_payergroup = temp_filtered_payergroup?.filter(
                    (group) =>
                        group?.name
                            ?.toLowerCase()
                            .includes(selectedFiltervalue?.toLowerCase())
                );
            }
            else if (selectedFilterkey === "groupCode")
                temp_filtered_payergroup = temp_filtered_payergroup?.filter(
                    (group) =>
                        group?.groupCode
                            ?.toLowerCase()
                            .includes(selectedFiltervalue?.toLowerCase())
                );
        }
        setFilteredPayerGroup(temp_filtered_payergroup)
    }, [selectedFiltervalue, payergroup, items]);

    useEffect(() => {
        setSelectedFiltervalue(undefined);
    }, [selectedFilterkey]);

    const TableFilters = (
        <Space size="small">
            {selectedFilterkey === "name" && (
                <Input
                    onChange={(event) => {
                        setSelectedFiltervalue(event?.target?.value);
                    }}
                    value={selectedFiltervalue}
                    style={{
                        width: "320px",
                    }}
                    placeholder="Find Name"
                    prefix={<SearchOutlined />}
                />
            )}
            {selectedFilterkey === "groupCode" && (
                <Input
                    onChange={(event) => {
                        setSelectedFiltervalue(event?.target?.value);
                    }}
                    value={selectedFiltervalue}
                    style={{
                        width: "320px",
                    }}
                    placeholder="Find Group Code"
                    prefix={<SearchOutlined />}
                />
            )}
            <Select
                value={selectedFilterkey}
                onChange={(value) => {
                    setSelectedFilterKey(value);
                }}
                style={{ width: "140px" }}
                options={[
                    { value: "name", label: "Name" },
                    { value: "groupCode", label: "Group Code" },
                ]}
            />
        </Space>
    );

    return {
        TableFilters,
        filteredPayerGroup,
        UseQueryHookResult: {
            refetch,
            isLoading: payergroupLoading,
            isFetching,
        },
    };
}

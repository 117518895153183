import React from "react";
import { Layout } from "antd";
import { WidgetConfig } from "../types";

const { Header, Content } = Layout;

export function BaseStaticWidget({
  config,
  style,
}: {
  config: WidgetConfig;
  style?: React.CSSProperties;
}) {
  const Wrapper = config.provider ?? React.Fragment;

  const layoutBackground =
    config.title === "Service overview" ? "none" : "#fff";
  const boxShadow =
    config.title === "Service overview"
      ? "none"
      : "0px 10px 25px 0px #5911920D";

  return (
    <div>
      <Wrapper>
        <Layout
          style={{
            borderRadius: "16px",
            marginRight: "0",
            padding: "12px",
            boxShadow: boxShadow,
            background: layoutBackground,
            ...style,
          }}
        >
          <Header
            style={{
              background: "none",
              padding: 0,
              marginBottom: "10px",
              lineHeight: "10px",
            }}
          >
            <config.header />
          </Header>
          <Content>
            <config.content />
          </Content>
        </Layout>
      </Wrapper>
    </div>
  );
}

import { useContext, useEffect, useState } from "react";
import { Breadcrumb, Col, Dropdown, Row, Space, Table, Typography } from "antd";
import { DeleteOutlined, DownOutlined, EditOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import {
  useDeleteRoleMutation,
  useGetAllRoleQuery,
  useGetRoleQuery,
} from "../../redux/api/role/role";
import IncredableContent from "../../components/incredable-content";
import IncredableButton from "../../components/button";
import EditRoleModal from "../modals/edit-role-modal";
import DeleteAlertModal from "../../common/Modal/delete-alert-modal";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import { RolePermissions } from "../../redux/api/role/types";
import PermissionsList from "../manage-permissions/permissions-list";
import { skipToken } from "@reduxjs/toolkit/query";
import { MemberType } from "../../redux/api/manageadmin/types";

export const columns = [
  {
    title: () => <Typography.Text>Roles</Typography.Text>,
    key: "name",
    width: "50%",
    ellipsis: true,
    render: (item: MemberType) => (
      <Typography.Text>{`${item?.firstName} ${item?.lastName}`}</Typography.Text>
    ),
  },
  {
    title: () => <Typography.Text>Emails</Typography.Text>,
    dataIndex: "email",
    key: "email",
    ellipsis: true,
    render: (email: string) => (
      <Typography.Text>{email || "-"}</Typography.Text>
    ),
  },
];

export default function RoleDetails() {
  const [editRoleModal, setEditRoleModal] = useState<boolean>(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [rolePermissions, setRolePermissions] = useState<RolePermissions[]>([]);

  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();

  const { id: roleId } = useParams();
  const { data: allRoles } = useGetAllRoleQuery();
  const { data: role, isLoading } = useGetRoleQuery(
    roleId ? { id: roleId } : skipToken
  );
  const [deleteRole] = useDeleteRoleMutation();

  useEffect(() => {
    if (allRoles && roleId) {
      const foundRole = allRoles.find((role) => role.id.toString() === roleId);
      if (foundRole) {
        setRolePermissions([
          {
            id: foundRole.id,
            name: foundRole.name,
            default: foundRole.default,
            permissions: foundRole.permissions.map(
              (permission) => permission.key
            ),
          },
        ]);
      }
    }
  }, [allRoles, roleId]);

  const handleOpenEditRoleModal = () => setEditRoleModal(true);
  const handleCloseEditRoleModal = () => setEditRoleModal(false);
  const handleOpenDeleteModal = () => setDeleteModalOpen(true);

  const actionDropDownProps = {
    menu: {
      items: [
        {
          label: "Edit",
          key: "edit",
          icon: <EditOutlined />,
          onClick: handleOpenEditRoleModal,
        },
        {
          label: "Delete",
          key: "delete",
          icon: <DeleteOutlined />,
          danger: true,
          onClick: handleOpenDeleteModal,
        },
      ],
    },
  };

  const handleDeleteRole = async () => {
    if (roleId) {
      setDeleteModalOpen(false);
      try {
        spinnerContext?.showSpinner();
        await deleteRole({ roleId: String(roleId) });
        toastContext?.openSuccessNotification({
          message: `Role deleted successfully`,
          placement: "topRight",
        });
        navigate(-1);
      } catch (error) {
        toastContext?.openErrorNotification({
          message: "Failed to delete role",
          placement: "topRight",
        });
      } finally {
        spinnerContext?.hidespinner();
      }
    }
  };

  return (
    <>
      <IncredableContent
        style={{ margin: "20px 30px 0" }}
        spinning={isLoading}
        breadcrumbProps={{
          items: [
            {
              title: <a href="/settings/roles">Roles</a>,
            },
            {
              title: "Testing",
            },
          ],
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Typography.Text
            strong
            style={{
              fontSize: "22px",
              color: "#1D1A1F",
              lineHeight: "25px",
              fontWeight: "700",
            }}
          >
            Details
          </Typography.Text>
          <Dropdown {...actionDropDownProps}>
            <IncredableButton secondary>
              Actions <DownOutlined />
            </IncredableButton>
          </Dropdown>
        </div>
        <Row>
          <Col span={8}>
            <Space direction="vertical">
              <Typography.Text strong>Role Name</Typography.Text>
              <Typography.Text>{role?.name}</Typography.Text>
            </Space>
          </Col>
          <Col span={8}>
            <Space direction="vertical">
              <Typography.Text strong>Description</Typography.Text>
              <Typography.Text>{role?.description}</Typography.Text>
            </Space>
          </Col>
        </Row>
        <EditRoleModal
          visible={editRoleModal}
          onClose={handleCloseEditRoleModal}
        />
        <DeleteAlertModal
          visible={isDeleteModalOpen}
          action={handleDeleteRole}
          closeModal={() => setDeleteModalOpen(false)}
          header="Delete Role"
          content={
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography.Text>Role</Typography.Text>
              <Typography.Text strong style={{ marginTop: "8px" }}>
                {role?.name}
              </Typography.Text>
            </div>
          }
          description="Are you sure you want to delete this Role?"
        />
      </IncredableContent>

      <IncredableContent
        style={{ margin: "20px 30px 0", padding: "10px 10px" }}
      >
        <PermissionsList
          roles={rolePermissions}
          setRoles={setRolePermissions}
          tabName="roleDetails"
        />
      </IncredableContent>

      <IncredableContent style={{ margin: "20px 30px 0" }}>
        <Table
          size="small"
          columns={columns}
          loading={isLoading}
          rowKey="id"
          dataSource={role?.admins}
          title={() => (
            <Typography.Title
              style={{ marginTop: "0" }}
              level={4}
            >{`Associated Admins (${role?.admins.length})`}</Typography.Title>
          )}
        />
      </IncredableContent>
    </>
  );
}

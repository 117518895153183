import { ConfigProvider, Select, Space, Typography } from "antd";
import { CalendarOutlined, DownloadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import IncredableButton from "../components/button";
import { useLazyGetAllExpiringDocumentsQuery } from "../redux/api/reporting/reporting";
import IncredableDateFormat from "../common/incredable-date-format";
import { useCallback, useEffect, useState } from "react";
import { ProviderDocuments } from "../redux/api/reporting/types";
import { debounce } from "lodash";
import { Header, Pagination, Table } from "@cloudscape-design/components";

export default function ExpiringDocumentsReport() {
  const navigate = useNavigate();
  const [days, setDays] = useState<string>("180");
  const handleChange = (days: string) => {
    setDays(days);
  };
  const [selectedRows, setSelectedRows] = useState<ProviderDocuments[]>();
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [sortingDetails, setSortingDetails] = useState<{
    sortingField?: string;
    isDescending?: boolean;
  }>({ sortingField: "id", isDescending: false });
  const [
    trigger,
    { data: expiringDocumentsReportsData, isLoading, isFetching },
  ] = useLazyGetAllExpiringDocumentsQuery({});

  // Debounced trigger function
  const debouncedTrigger = useCallback(
    debounce((filters) => {
      trigger(filters);
    }, 1200),
    [trigger]
  );

  // Clean up debounce on unmount
  useEffect(() => {
    return () => {
      debouncedTrigger.cancel();
    };
  }, [debouncedTrigger]);

  useEffect(() => {
    debouncedTrigger({
      days,
      pageNumber,
      pageSize,
      sortBy: sortingDetails?.sortingField,
      direction: !!sortingDetails?.isDescending ? "DSC" : "ASC",
    });
  }, [
    days,
    pageNumber,
    pageSize,
    sortingDetails,
    debouncedTrigger,
  ]);
  return (
    <div style={{ padding: "32px 32px 0 32px" }}>
      <Table
        loading={isFetching || isLoading}
        onSelectionChange={({ detail }) => {
          setSelectedRows([...detail.selectedItems]);
        }}
        onRowClick={(onRowClickDetails) => {
          const selectedItem = onRowClickDetails?.detail?.item;
        }}
        selectedItems={selectedRows || []}
        sortingColumn={{ sortingField: sortingDetails?.sortingField }}
        sortingDescending={sortingDetails?.isDescending}
        onSortingChange={({ detail }) => {
          setSortingDetails({
            sortingField: detail?.sortingColumn?.sortingField,
            isDescending: detail?.isDescending,
          });
        }}
        columnDefinitions={[
          {
            id: "documentName",
            header: "Document Name",
            cell: (item) => (<span>{item?.document?.name}</span>
            ),
            sortingField: "name",
            isRowHeader: true,
          },
          {
            id: "createdDate",
            header: "Document Created Date",
            cell: (item) => IncredableDateFormat(item?.document?.createdDate),
            sortingField: "email",
          },
          {
            id: "expirationDate",
            header: "Expiration Date",
            cell: (item) => IncredableDateFormat(item?.document?.expirationDate),
            sortingField: "expirationDate",
          },
          {
            id: "providerLastName",
            header: "Provider Last Name",
            cell: (item) => item?.provider?.lastName,
            sortingField: "provider?.lastName",
          },
          {
            id: "providerFirstName",
            header: "Provider First Name",
            cell: (item) => item?.provider?.firstName,
            sortingField: "provider?.firstName",
          },
        ]}
        items={!!expiringDocumentsReportsData ? expiringDocumentsReportsData?.documents : []}
        pagination={
          <Pagination
            currentPageIndex={pageNumber + 1}
            onChange={({ detail }) => {
              setPageNumber(detail.currentPageIndex - 1);
            }}
            pagesCount={
              !!pageSize && pageSize !== 0
                ? Math.ceil(!!expiringDocumentsReportsData ? expiringDocumentsReportsData?.totalRecords / pageSize : 0)
                : 1
            }
          />
        }
        loadingText="Loading resources"
        selectionType="multi"
        trackBy="id"
        header={
          <div style={{ marginTop: "8px", marginBottom: "12px" }}>
            <Header
              actions={
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#2B6BE6",
                    },
                  }}
                >
                  <Space size="middle">
                    <IncredableButton
                      onClick={() => navigate("")}
                      type="primary"
                      icon={<DownloadOutlined />}
                      size="large"
                    >
                      Export CSV
                    </IncredableButton>
                  </Space>

                </ConfigProvider>
              }
            >
              <Space direction="vertical">
                <Typography.Title level={3} style={{ marginTop: "0" }}>
                  Expiring Documents Reports{" "}
                  <Typography.Text
                    type="secondary"
                    style={{ fontSize: "16px" }}
                  >{`(${expiringDocumentsReportsData?.totalRecords})`}</Typography.Text>
                </Typography.Title>
                <Select
                  value={days}
                  placeholder="Select Days"
                  showSearch
                  onChange={handleChange}
                  style={{ width: "240px", borderColor: "#7C4095" }}
                  options={[
                    { label: "Last 180 days", value: "180" },
                    { label: "Last 120 days", value: "120" },
                    { label: "Last 90 days", value: "90" },
                    { label: "Last 60 days", value: "60" },
                    { label: "Last 30 days", value: "30" },
                  ]}
                />
              </Space>

            </Header>
          </div>
        }
      />
    </div>
  );
}

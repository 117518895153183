import { Col, Divider, Flex, Form, Row, theme, Typography, Space } from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import RHFSelect from "../../components/RHF/RHFSelect";
import {
  useAddProviderPeerReferenceMutation,
  useGetAllDegreeQuery,
  useGetProviderQuery,
} from "../../redux/api/provider/provider";
import IncredableButton from "../../components/button";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { useContext, useEffect } from "react";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import IncredableContent from "../../components/incredable-content";
import { ProviderPeerReferenceRequestType } from "../../redux/api/provider/types";
import RHFPhoneNumber from "../../components/RHF/RHFPhonenumber";
import { useGetAllSpecialtyQuery } from "../../redux/api/specialty/specialty";
import { Countries, USStatesList } from "../../common/constants";

const { useToken } = theme;

export default function EditProviderPeerReference() {
  const { providerId, peerReferenceId } = useParams();
  const methods = useForm<ProviderPeerReferenceRequestType>();
  const { data: degree } = useGetAllDegreeQuery();
  const { data: specialty } = useGetAllSpecialtyQuery();
  const {
    data: provider,
    isLoading,
    isFetching,
  } = useGetProviderQuery(!!providerId ? { providerId } : skipToken);
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();
  const { token } = useToken();

  useEffect(() => {
    if (provider && provider.providerPeerReferences) {
      const peerreference = provider?.providerPeerReferences?.find(
        (peerreference) => peerreference?.id + "" === peerReferenceId + ""
      );
      if (peerreference) {
        methods.setValue("id", peerreference.id);
        methods.setValue("name", peerreference.name);
        peerreference.degree?.id &&
          methods.setValue("degreeId", peerreference.degree?.id);
        peerreference.specialty?.id &&
          methods.setValue("specialtyId", peerreference.specialty?.id);
        methods.setValue(
          "timeWithReferenceValue",
          peerreference.timeWithReferenceValue
        );
        methods.setValue(
          "timeWithReferenceUnit",
          peerreference.timeWithReferenceUnit
        );
        methods.setValue("email", peerreference.email);
        methods.setValue("phone", peerreference.phone);
        methods.setValue("fax", peerreference.fax);
        methods.setValue("addressLane1", peerreference.addressLane1);
        methods.setValue("addressLane2", peerreference.addressLane2);
        methods.setValue("city", peerreference.city);
        methods.setValue("state", peerreference.state);
        methods.setValue("zip", peerreference.zip);
        methods.setValue("country", peerreference.country);
      }
    }
  }, [provider, peerReferenceId]);

  const [updateProviderPeerReference] = useAddProviderPeerReferenceMutation();

  return (
    <IncredableContent spinning={isLoading || isFetching}>
      <FormProvider {...methods}>
        <Form
          name="editproviderliabilityinsurance"
          layout="vertical"
          onFinish={methods.handleSubmit(
            async (data: ProviderPeerReferenceRequestType) => {
              if (!!providerId) {
                spinnerContext?.showSpinner();
                await updateProviderPeerReference({
                  ...data,
                  providerId: providerId,
                });
                spinnerContext?.hidespinner();
                toastContext?.openSuccessNotification({
                  message: `Peer Reference saved successfully`,
                  placement: "topRight",
                });
              }
              navigate(-1);
            }
          )}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                color: "#1D1A1F",
                lineHeight: "25px",
                fontWeight: "700",
              }}
            >
              Peer Reference
            </Typography.Text>
            <Space>
              <IncredableButton secondary onClick={() => navigate(-1)}>
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" htmlType="submit">
                Save
              </IncredableButton>
            </Space>
          </Flex>
          <Divider />
          <Form.List name="liabilityinsurance" initialValue={[{}]}>
            {(fields, { add }) => {
              return (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div key={key}>
                      {index > 0 && <Divider />}
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Name",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "name",
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Degree" }}
                            controllerProps={{
                              name: "degreeId",
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select degree",
                              options: degree?.map((degree) => {
                                return {
                                  label: degree?.name,
                                  value: degree?.id,
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Specialty" }}
                            controllerProps={{
                              name: "specialtyId",
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select specialty",
                              options: specialty?.map((specialty) => {
                                return {
                                  label: specialty?.name,
                                  value: specialty?.id,
                                };
                              }),
                            }}
                          />
                        </Col>

                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Time With Reference Value",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "timeWithReferenceValue",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Time With Reference Unit",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "timeWithReferenceUnit",
                            }}
                          />
                        </Col>
                      </Row>
                      <Divider />
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Email",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "email",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFPhoneNumber
                            formItemProps={{
                              label: "Phone",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "phone",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Fax",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "fax",
                            }}
                          />
                        </Col>
                      </Row>
                      <Divider />
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Address Lane 1",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "addressLane1",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Address Lane 2",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "addressLane2",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "City",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "city",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "State" }}
                            controllerProps={{
                              name: `state`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select state",
                              options: USStatesList?.map((state) => {
                                return {
                                  value: state?.value,
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Country" }}
                            controllerProps={{
                              name: `country`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select country",
                              options: Countries?.map((country) => {
                                return {
                                  value: country?.value,
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Zip",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "zip",
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}
                </>
              );
            }}
          </Form.List>
        </Form>
      </FormProvider>
    </IncredableContent>
  );
}

import { Dropdown, Button, Layout, Space, Typography, ConfigProvider } from "antd";
import { DeleteOutlined, DownOutlined, EditOutlined, PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import useAffiliationFilter from "../table-filters/affiliations-filters";
import { ProviderAffiliation } from "../redux/api/affiliation/types";
import DeleteAlertModal from "../common/Modal/delete-alert-modal";
import { SpinnerContext } from "../context/spinner/spinner";
import { ToastContext } from "../context/toast/incredable-toast";
import { useDeleteProviderAffiliationMutation } from "../redux/api/provider/provider";
import IncredableDateFormat from "../common/incredable-date-format";
import { AllDocumentResponse } from "../redux/api/document/types";
import { useCollection } from "@cloudscape-design/collection-hooks";
import {
    AffiliationStatus,
    AffiliationType,
    Provider,
} from "../redux/api/provider/types";
import { useState, useContext, useEffect } from "react";
import { useLocalStorage } from "../common/localStorage";
import { Header, Link, Pagination, Table } from "@cloudscape-design/components";
import { AFFILIATION_CONTENT_DISPLAY_OPTIONS, AFFILIATION_TABLE_DEFAULT_PREFERENCES, TablePreferences } from "./table-config";

export const columns = [
    {
        title: () => <Typography.Text>Facility</Typography.Text>,
        key: "facilityName",
        width: "200px",
        ellipsis: true,
        render: (affiliation: ProviderAffiliation) => (
            <Typography.Link href={`privileges/editaffiliation/${affiliation?.id}`}>
                {affiliation?.facility?.name || "-"}
            </Typography.Link>
        ),
    },
    {
        title: () => <Typography.Text>Affiliations Status</Typography.Text>,
        dataIndex: "affiliationStatus",
        key: "affiliationStatus",
        width: "200px",
        ellipsis: true,
        render: (status: AffiliationStatus) => (
            <Typography.Text>{status?.name || "-"}</Typography.Text>
        ),
    },
    {
        title: () => <Typography.Text>Affiliations Type</Typography.Text>,
        dataIndex: "affiliationType",
        key: "affiliationType",
        width: "200px",
        ellipsis: true,
        render: (type: AffiliationType) => (
            <Typography.Text>{type?.name || "-"}</Typography.Text>
        ),
    },
    {
        title: () => <Typography.Text>Provider</Typography.Text>,
        dataIndex: "provider",
        key: "providerName",
        width: "200px",
        ellipsis: true,
        render: (provider: Provider) => (
            <Typography.Text>
                {`${provider?.firstName} ${provider?.lastName}` || "-"}
            </Typography.Text>
        ),
    },
    {
        title: () => <Typography.Text>Start Date</Typography.Text>,
        dataIndex: "startDate",
        key: "startDate",
        width: "200px",
        ellipsis: true,
        render: (date: Date) => (
            <Typography.Text>
                {date ? IncredableDateFormat(date) : "-"}
            </Typography.Text>
        ),
    },
    {
        title: () => <Typography.Text>End Date</Typography.Text>,
        dataIndex: "endDate",
        key: "endDate",
        width: "200px",
        ellipsis: true,
        render: (date: Date) => (
            <Typography.Text>
                {date ? IncredableDateFormat(date) : "-"}
            </Typography.Text>
        ),
    },
    {
        title: () => <Typography.Text>Expiration Reminder</Typography.Text>,
        dataIndex: "alertDays",
        key: "alertDays",
        width: "200px",
        ellipsis: true,
        render: (days: number) => (
            <Typography.Text>{days ? `${days} days` : "-"}</Typography.Text>
        ),
    },
    {
        title: () => <Typography.Text>Email</Typography.Text>,
        dataIndex: "email",
        key: "email",
        width: "200px",
        ellipsis: true,
        render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
    },
    {
        title: () => <Typography.Text>Phone</Typography.Text>,
        dataIndex: "phone",
        key: "phone",
        width: "200px",
        ellipsis: true,
        render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
    },
    {
        title: () => <Typography.Text>Fax</Typography.Text>,
        dataIndex: "fax",
        key: "fax",
        width: "200px",
        ellipsis: true,
        render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
    },
    {
        title: () => <Typography.Text>Document Name</Typography.Text>,
        dataIndex: "document",
        key: "documentName",
        width: "200px",
        ellipsis: true,
        render: (document: AllDocumentResponse) => (
            <Typography.Text>{document?.name || "-"}</Typography.Text>
        ),
    },
];

export default function AffiliationsListV2() {
    const [selectedRows, setSelectedRows] = useState<ProviderAffiliation[]>();
    const {
        TableFilters,
        filteredAffiliations = [],
        affiliationTotalRecords,
        UseQueryHookResult: {
            reload,
            isFetching,
            pageNumber,
            setPageNumber,
            pageSize,
            setPageSize,
            sortingDetails,
            setSortingDetails,
        }
    } =
        useAffiliationFilter();
    const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [deleteProviderAffiliation] = useDeleteProviderAffiliationMutation();
    const spinnerContext = useContext(SpinnerContext);
    const toastContext = useContext(ToastContext);
    const navigate = useNavigate();
    const [tablePreferences, setTablePreferences] = useLocalStorage(
        "RD-Affiliation-Table-Preferences",
        AFFILIATION_TABLE_DEFAULT_PREFERENCES
    );
    useEffect(() => {
        if (!!tablePreferences?.pageSize) setPageSize(tablePreferences?.pageSize);
    }, []);

    const { items, collectionProps, paginationProps } = useCollection(
        filteredAffiliations,
        {
            pagination: { pageSize: pageSize },
        }
    );
    const handleOpenDeleteModal = () => {
        setDeleteModalOpen(true);
    };

    return (
        <div style={{ padding: "32px 32px 0 32px" }}>
            <Table
                loading={isFetching}
                onSelectionChange={({ detail }) => {
                    setSelectedRows([...detail?.selectedItems]);
                }}
                onRowClick={(onRowClickDetails) => {
                    const selectedItem = onRowClickDetails?.detail?.item;
                    if (!!selectedItem) {
                        const temp = !!selectedRows ? [...selectedRows] : [];
                        const selectedItemIndex = temp.findIndex(
                            (record) => record?.id + "" === "" + selectedItem?.id
                        );
                        if (selectedItemIndex !== -1) {
                            temp?.splice(selectedItemIndex, 1);
                            setSelectedRows(temp);
                        } else {
                            setSelectedRows([...temp, selectedItem]);
                        }
                    }
                }}
                selectedItems={!!selectedRows ? selectedRows : []}
                sortingColumn={{ sortingField: sortingDetails?.sortingField }}
                sortingDescending={sortingDetails?.isDescending}
                onSortingChange={({ detail }) => {
                    console.log({ detail });
                    setSortingDetails({
                        sortingField: detail?.sortingColumn?.sortingField,
                        isDescending: detail?.isDescending,
                    });
                }}
                columnDefinitions={[
                    {
                        id: "facility",
                        header: "Facility",
                        cell: (item) => (
                            <Link href={`/privileges/editaffiliation/${item?.id}`}>{`${item?.facility?.name}`}</Link>
                        ),
                        sortingField: "facility",
                        isRowHeader: true,
                    },
                    {
                        id: "affiliationStatus",
                        header: "Affiliation Status",
                        cell: (item) => item?.affiliationStatus?.name,
                        sortingField: "affiliationStatus",
                    },
                    {
                        id: "affiliationType",
                        header: "Affiliation Type",
                        cell: (item) => item?.affiliationType?.name,
                        sortingField: "affiliationType",
                    },
                    {
                        id: "name",
                        header: "Name",
                        cell: (item) => item?.name,
                        sortingField: "name",
                    },
                    {
                        id: "email",
                        header: "Email address",
                        cell: (item) => item?.email,
                        sortingField: "email",
                    },
                    {
                        id: "fax",
                        header: "Fax",
                        cell: (item) => item?.fax,
                        sortingField: "fax",
                    },
                    {
                        id: "phone",
                        header: "Phone Number",
                        cell: (item) => item?.phone,
                        sortingField: "phone",
                    },
                    {
                        id: "affiliationType",
                        header: "Affiliation Type",
                        cell: (item) => item?.affiliationType?.name,
                    },
                    {
                        id: "provider",
                        header: "Provider",
                        cell: (item) => `${item?.provider?.lastName} ${item?.provider?.firstName}`,
                        sortingField: "provider"
                    },
                    {
                        id: "alertDays",
                        header: "Expiration Remainder",
                        cell: (item) => `${item?.alertDays} days`,
                    },
                    {
                        id: "startDate",
                        header: "Start Date",
                        cell: (item) => IncredableDateFormat(item?.startDate),
                    },
                    {
                        id: "endDate",
                        header: "End Date",
                        cell: (item) => IncredableDateFormat(item?.endDate),
                    },
                    {
                        id: "documentName",
                        header: "Document Name",
                        cell: (item) => item?.document?.name,
                    },
                ]}
                items={items}
                pagination={
                    <Pagination
                        {...paginationProps}
                        currentPageIndex={pageNumber + 1}
                        onChange={({ detail }) => {
                            setPageNumber(detail.currentPageIndex - 1);
                        }}
                        pagesCount={
                            !!tablePreferences?.pageSize && tablePreferences?.pageSize !== 0
                                ? Math.ceil(affiliationTotalRecords / tablePreferences?.pageSize)
                                : 1
                        }
                    />
                }
                preferences={
                    <TablePreferences
                        preferences={tablePreferences}
                        setPreferences={(preferences) => {
                            if (preferences.pageSize !== tablePreferences.pageSize) {
                                preferences.pageSize && setPageSize(preferences.pageSize);
                            }
                            setTablePreferences(preferences);
                        }}
                        contentDisplayOptions={AFFILIATION_CONTENT_DISPLAY_OPTIONS}
                    />
                }
                loadingText="Loading resources"
                selectionType="multi"
                trackBy="id"
                filter={TableFilters}
                header={
                    <Header
                        counter={`(${filteredAffiliations?.length})`}
                        actions={
                            <Space size="middle">
                                <Button
                                    onClick={() => {
                                        !!reload && reload();
                                    }}
                                >
                                    <ReloadOutlined />
                                </Button>
                                <Dropdown
                                    menu={{
                                        items: [
                                            {
                                                label: "Edit",
                                                key: "edit",
                                                disabled: selectedRows?.length !== 1,
                                                icon: <EditOutlined />,
                                                onClick: () => {
                                                    navigate(`editaffiliation/${selectedRows?.at(0)?.id}`);
                                                },
                                            },
                                            {
                                                label: "Delete",
                                                key: "delete",
                                                icon: <DeleteOutlined />,
                                                disabled: selectedRows?.length !== 1,
                                                onClick: () => {
                                                    handleOpenDeleteModal()
                                                }
                                            },
                                        ],
                                    }}
                                >
                                    <Button>
                                        Actions
                                        <DownOutlined />
                                    </Button>
                                </Dropdown>
                                <ConfigProvider
                                    theme={{
                                        token: {
                                            colorPrimary: "#2B6BE6",
                                        },
                                    }}
                                >
                                    <Button
                                        onClick={() => navigate("addAffiliation")}
                                        type="primary"
                                        icon={<PlusOutlined />}
                                    >
                                        Add Affiliation
                                    </Button>
                                </ConfigProvider>
                            </Space>
                        }
                    >
                        Affiliations
                    </Header>
                }
                {...collectionProps}
                columnDisplay={tablePreferences?.contentDisplay}
                wrapLines={tablePreferences?.wrapLines}
                stripedRows={tablePreferences?.stripedRows}
                contentDensity={tablePreferences?.contentDensity}
                stickyColumns={tablePreferences?.stickyColumns}
            />
            <DeleteAlertModal
                visible={isDeleteModalOpen}
                action={async () => {
                    if (selectedRows?.length === 1 && selectedRows?.at(0)?.provider?.id && selectedRows?.at(0)?.id) {
                        setDeleteModalOpen(false);
                        spinnerContext?.showSpinner();
                        await deleteProviderAffiliation({
                            providerId: String(selectedRows?.at(0)?.provider?.id),
                            affiliationId: String(selectedRows?.at(0)?.id),
                        });
                        spinnerContext?.hidespinner();
                        toastContext?.openSuccessNotification({
                            message: `Affiliation deleted successfully`,
                            placement: "topRight",
                        });
                    }
                }}
                closeModal={() => setDeleteModalOpen(false)}
                header="Delete Affiliation "
                content={
                    <>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <Typography.Text>Affiliation</Typography.Text>
                            <Typography.Text strong style={{ marginTop: "8px" }}>
                                {selectedRows?.at(0)?.name}
                            </Typography.Text>
                        </div>
                    </>
                }
                description="Are you sure you want to delete this affiliation?"
            />
        </div>
    );
}

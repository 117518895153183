import { SearchOutlined } from "@ant-design/icons";
import { Input, Select, Space } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useGetAllMembersQuery } from "../redux/api/manageadmin/manageadmin";

export default function useActiveMembersFilter() {
    const [selectedFilterkey, setSelectedFilterKey] = useState<string>("firstName");
    const [selectedFiltervalue, setSelectedFiltervalue] = useState<
        string | undefined
    >(undefined);

    const {
        data: members = [],
        refetch,
        isLoading: membersLoading,
        isFetching,
    } = useGetAllMembersQuery();

    const filteredMembers = useMemo(() => {
        if (!!selectedFiltervalue) {
            if (selectedFilterkey === "firstName")
                return members?.filter(
                    (member) =>
                        member?.firstName
                            ?.toLowerCase()
                            .includes(selectedFiltervalue?.toLowerCase())
                );

            else if (selectedFilterkey === "lastName")
                return members?.filter(
                    (member) =>
                        member?.lastName
                            ?.toLowerCase()
                            .includes(selectedFiltervalue?.toLowerCase())
                );
            else if (selectedFilterkey === "email")
                return members?.filter(
                    (member) =>
                        member?.email
                            ?.toLowerCase()
                            .includes(selectedFiltervalue?.toLowerCase())
                );

        }
        return members;
    }, [selectedFiltervalue, members]);

    useEffect(() => {
        setSelectedFiltervalue(undefined);
    }, [selectedFilterkey]);

    const TableFilters = (
        <Space size="small">
            {selectedFilterkey === "firstName" && (
                <Input
                    onChange={(event) => {
                        setSelectedFiltervalue(event?.target?.value);
                    }}
                    value={selectedFiltervalue}
                    style={{
                        width: "320px",
                    }}
                    placeholder="Find First Name"
                    prefix={<SearchOutlined />}
                />
            )}
            {selectedFilterkey === "lastName" && (
                <Input
                    onChange={(event) => {
                        setSelectedFiltervalue(event?.target?.value);
                    }}
                    value={selectedFiltervalue}
                    style={{
                        width: "320px",
                    }}
                    placeholder="Find Last Name"
                    prefix={<SearchOutlined />}
                />
            )}
            {selectedFilterkey === "email" && (
                <Input
                    onChange={(event) => {
                        setSelectedFiltervalue(event?.target?.value);
                    }}
                    value={selectedFiltervalue}
                    style={{
                        width: "320px",
                    }}
                    placeholder="Find Email"
                    prefix={<SearchOutlined />}
                />
            )}
            <Select
                value={selectedFilterkey}
                onChange={(value) => {
                    setSelectedFilterKey(value);
                }}
                style={{ width: "140px" }}
                options={[
                    { value: "firstName", label: "First Name" },
                    {
                        value: "lastName",
                        label: "Last Name",
                    },
                    { value: "email", label: "Email" }
                ]}
            />
        </Space>
    );

    return {
        TableFilters,
        filteredMembers,
        UseQueryHookResult: {
            refetch,
            isLoading: membersLoading,
            isFetching,
        },
    };
}

import {
  CollectionPreferences,
  CollectionPreferencesProps,
} from "@cloudscape-design/components";

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: "10 items" },
  { value: 30, label: "30 items" },
  { value: 50, label: "50 items" },
];

export const PAYERENROLLMENT_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "PayerEnrollment ID" },
  { id: "payer", label: "Payer" },
  { id: "network", label: "Network" },
  { id: "provider", label: "Provider" },
  { id: "payerProviderId", label: "Provider ID" },
  { id: "facility", label: "Facility" },
  { id: "facilityGroup", label: "Facility Group" },
  { id: "status", label: "Status" },
  { id: "submittedDate", label: "Submitted Date" },
  { id: "approvedDate", label: "Approved Date" },
  { id: "effectiveDate", label: "Effective Date" },
  { id: "recredentialingDate", label: "Re-Enrollment Date" },
  { id: "followupDate", label: "Follow Up Date" },
  { id: "description", label: "Notes" },
  { id: "workflow", label: "Workflow" },
];

export const PAYERENROLLMENT_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
{
  pageSize: 10,
  contentDisplay: [
    { id: "id", visible: false },
    { id: "payer", visible: true },
    { id: "network", visible: true },
    { id: "provider", visible: true },
    { id: "payerProviderId", visible: true },
    { id: "facility", visible: true },
    { id: "facilityGroup", visible: true },
    { id: "status", visible: true },
    { id: "submittedDate", visible: true },
    { id: "approvedDate", visible: true },
    { id: "effectiveDate", visible: true },
    { id: "recredentialingDate", visible: true },
    { id: "followupDate", visible: true },
    { id: "description", visible: true },
    { id: "workflow", visible: true },
  ],
  wrapLines: false,
  stripedRows: true,
  contentDensity: "comfortable",
  stickyColumns: { first: 0, last: 0 },
};
export const TablePreferences = ({
  title,
  disabled,
  preferences,
  setPreferences,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  contentDisplayOptions,
}: {
  title?: string;
  disabled?: boolean;
  preferences: CollectionPreferencesProps.Preferences<any> | undefined;
  setPreferences: (
    preferences: CollectionPreferencesProps.Preferences<any>
  ) => void;
  pageSizeOptions?: CollectionPreferencesProps.PageSizeOption[];
  contentDisplayOptions: CollectionPreferencesProps.ContentDisplayOption[];
}) => (
  <CollectionPreferences
    disabled={disabled || !contentDisplayOptions.length}
    preferences={preferences}
    onConfirm={({ detail }) => {
      setPreferences(detail);
    }}
    title={title}
    pageSizePreference={{ options: pageSizeOptions }}
    wrapLinesPreference={{}}
    stripedRowsPreference={{}}
    contentDensityPreference={{}}
    contentDisplayPreference={{ options: contentDisplayOptions }}
    stickyColumnsPreference={{
      firstColumns: {
        title: "Stick first column(s)",
        description:
          "Keep the first column(s) visible while horizontally scrolling the table content.",
        options: [
          { label: "None", value: 0 },
          { label: "First column", value: 1 },
          { label: "First two columns", value: 2 },
        ],
      },
      lastColumns: {
        title: "Stick last column",
        description:
          "Keep the last column visible while horizontally scrolling the table content.",
        options: [
          { label: "None", value: 0 },
          { label: "Last column", value: 1 },
        ],
      },
    }}
  />
);

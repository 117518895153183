import { Space, Spin, Table, Typography } from "antd";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import TableTitle from "../../components/incredable-table/table-title";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { TablePreferenceModal } from "../../common/TablePreferenceModal";
import IncredableButton from "../../components/button";
import IncredableContent from "../../components/incredable-content";
import { CredentialPackageStatus } from "../../redux/api/credentialpackages/types";
import {
  useDeletePackageStatusMutation,
  useGetAllPackageStatusQuery,
} from "../../redux/api/credentialpackages/credentialpackages";
import DeleteAlertModal from "../../common/Modal/delete-alert-modal";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";

export const credentialPackageStatusColumn = [
  {
    title: () => <Typography.Text>Status</Typography.Text>,
    key: "name",
    ellipsis: true,
    dataIndex: ["name"],
    render: (name: string) => <Typography.Text>{name || "-"}</Typography.Text>,
    sorter: (a: CredentialPackageStatus, b: CredentialPackageStatus) => {
      return a?.name?.toLowerCase()?.localeCompare(b?.name?.toLowerCase());
    },
  },
];

export default function CredentialPackageStatusList() {
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState<CredentialPackageStatus>();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    credentialPackageStatusColumn.map((col) => col.key as string)
  );
  const [visibleColumnOrder, setVisibleColumnOrder] = useState<string[]>(
    credentialPackageStatusColumn.map((col) => col.key as string)
  );
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  const {
    data: packageStatus,
    isLoading,
    isFetching,
    error,
    refetch,
  } = useGetAllPackageStatusQuery();
  const [deletePackageStatus] = useDeletePackageStatusMutation();

  const visibleColumns = visibleColumnOrder
    .filter((colKey) => selectedColumns.includes(colKey))
    .map(
      (colKey) =>
        credentialPackageStatusColumn.find((col) => col.key === colKey)!
    );

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <Spin tip="Loading..." />
      </div>
    );
  }

  if (error) {
    return <div>Error loading package status</div>;
  }
  const selectRow = (record: CredentialPackageStatus) => {
    if (selectedRow?.id + "" === record?.id + "") {
      setSelectedRow(undefined);
    } else {
      setSelectedRow(record);
    }
  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const handleSavePreferences = (
    selectedColumns: string[],
    columnOrder: string[]
  ) => {
    setSelectedColumns(selectedColumns);
    setVisibleColumnOrder(columnOrder);
    handleCloseModal();
  };

  return (
    <>
      <IncredableButton
        style={{
          margin: "12px 0 0 48px",
          padding: "12px",
        }}
        secondary
        onClick={() => navigate(-1)}
      >
        Go back
      </IncredableButton>
      <IncredableContent
        style={{ margin: "12px 0 0 48px" }}
        spinning={isLoading || isFetching}
      >
        <Table
          size="small"
          rowKey="id"
          dataSource={!!packageStatus ? [...packageStatus] : []}
          columns={visibleColumns}
          onRow={(record) => ({
            onClick: () => {
              selectRow(record);
            },
          })}
          rowSelection={{
            type: "radio",
            selectedRowKeys: !!selectedRow?.id ? [selectedRow?.id] : [],
            onChange: (selectedRowKeys, selectedRows, info) => {
              if (!!selectedRows && !!selectedRows?.at(0))
                setSelectedRow(selectedRows?.at(0));
            },
            columnWidth: 60,
          }}
          pagination={{
            position: ["bottomRight"],
            style: { backgroundColor: "white", padding: "10px", margin: "0" },
            pageSizeOptions: [10, 25, 50],
            showSizeChanger: true,
            total: packageStatus?.length,
            showTotal: (total) => `Total ${total} items`,
          }}
          scroll={{ y: 400, x: "100vw" }}
          title={() => (
            <Space
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span style={{ fontSize: "22px", fontWeight: "700" }}>
                {`Package Status(${packageStatus?.length})`}
              </span>
              <Space>
                <IncredableButton
                  onClick={() => navigate(`add`)}
                  type="primary"
                  icon={<PlusOutlined />}
                  size="middle"
                  style={{
                    backgroundColor: "#598BEB",
                    border: "2px solid #598BEB",
                    boxShadow: "none",
                  }}
                >
                  Add Package Status
                </IncredableButton>
                <TableTitle
                  filter={[]}
                  actionDropDownProps={{
                    disabled: !selectedRow,
                    menu: {
                      items: [
                        {
                          label: "Edit",
                          key: "edit",
                          icon: <EditOutlined />,
                        },
                        {
                          label: "Delete",
                          key: "delete",
                          icon: <DeleteOutlined />,
                          danger: true,
                        },
                      ],
                      onClick: async (e) => {
                        if (e?.key === "edit") {
                          navigate(`${selectedRow?.id}/edit`);
                        } else if (e?.key === "delete") {
                          handleOpenDeleteModal();
                        }
                      },
                    },
                  }}
                  onSettingsClick={handleOpenModal}
                  onReloadClick={refetch}
                />
              </Space>
            </Space>
          )}
        />
        <TablePreferenceModal
          visible={isModalVisible}
          onCancel={handleCloseModal}
          onSave={handleSavePreferences}
          availableColumns={credentialPackageStatusColumn.map(
            (col) => col.key as string
          )}
          selectedColumns={selectedColumns}
          columnOrderState={visibleColumnOrder}
        />
        <DeleteAlertModal
          visible={isDeleteModalOpen}
          action={async () => {
            if (selectedRow?.id) {
              setDeleteModalOpen(false);
              spinnerContext?.showSpinner();
              await deletePackageStatus({
                packageStatusId: selectedRow?.id,
              });
              spinnerContext?.hidespinner();
              toastContext?.openSuccessNotification({
                message: `Package Status Deleted Successfully`,
                placement: "topRight",
              });
            }
          }}
          closeModal={() => setDeleteModalOpen(false)}
          header="Delete Package Status"
          content={
            <Typography.Text>
              Status: {""}
              <Typography.Text strong>{selectedRow?.name}</Typography.Text>
            </Typography.Text>
          }
          description="Are you sure you want to delete this package status?"
        />
      </IncredableContent>
    </>
  );
}

import { useContext, useState } from "react";
import { Flex, Layout, Space, Table, theme, Typography } from "antd";
import {
  CalendarOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import TableTitle from "../components/incredable-table/table-title";
import { TablePreferenceModal } from "../common/TablePreferenceModal/";
import IncredableButton from "../components/button";
import { SpinnerContext } from "../context/spinner/spinner";
import { ToastContext } from "../context/toast/incredable-toast";
import { Contract } from "../redux/api/contracts/types";
import { useDeleteContractMutation } from "../redux/api/contracts/contract";
import useContractFilter from "../table-filters/contract-filters";
import IncredableDateFormat from "../common/incredable-date-format";
import DeleteAlertModal from "../common/Modal/delete-alert-modal";
const { useToken } = theme;

export const contractColumns = [
  {
    title: () => "Company Name",
    key: "companyName",
    ellipsis: true,
    render: (contract: Contract) => (
      <Typography.Link href={`/contract/${contract?.id}/edit`}>
        {contract?.title}
      </Typography.Link>
    ),
    sorter: (a: Contract, b: Contract) =>
      a?.title.toLowerCase()?.localeCompare(b?.title.toLowerCase()),
  },
  {
    title: () => "Contract Type",
    dataIndex: ["contractType", "type"],
    key: "contractType",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
    sorter: (a: Contract, b: Contract) =>
      a?.contractType?.type?.toLowerCase()?.localeCompare(b?.contractType?.type?.toLowerCase()),
  },
  {
    title: () => "Facility",
    dataIndex: ["facility", "name"],
    key: "facility",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
    sorter: (a: Contract, b: Contract) =>
      a?.facility?.name?.toLowerCase()?.localeCompare(b?.facility?.name.toLowerCase()),
  },
  {
    title: () => <Typography.Text>Execution Date</Typography.Text>,
    dataIndex: "executionDate",
    key: "executionDate",
    ellipsis: true,
    render: (executionDate: string) => (
      <Typography.Text>
        {executionDate ? (
          <Space>
            {" "}
            <CalendarOutlined /> {IncredableDateFormat(executionDate)}
          </Space>
        ) : (
          "-"
        )}
      </Typography.Text>
    ),
    sorter: (a: Contract, b: Contract) =>
      new Date(a?.executionDate).getTime() - new Date(b?.executionDate).getTime()
  },
  {
    title: () => <Typography.Text>Expiration Date</Typography.Text>,
    dataIndex: "expirationDate",
    key: "expirationDate",
    ellipsis: true,
    render: (expirationDate: string) => (
      <Typography.Text>
        {expirationDate ? (
          <Space>
            {" "}
            <CalendarOutlined /> {IncredableDateFormat(expirationDate)}
          </Space>
        ) : (
          "-"
        )}
      </Typography.Text>
    ),
    sorter: (a: Contract, b: Contract) =>
      new Date(a?.expirationDate).getTime() - new Date(b?.expirationDate).getTime()
  },
  {
    title: () => <Typography.Text>Last Renewal Date</Typography.Text>,
    dataIndex: "lastRenewalDate",
    key: "lastRenewalDate",
    ellipsis: true,
    render: (lastRenewalDate: string) => (
      <Typography.Text>
        {lastRenewalDate ? (
          <Space>
            {" "}
            <CalendarOutlined /> {IncredableDateFormat(lastRenewalDate)}
          </Space>
        ) : (
          "-"
        )}
      </Typography.Text>
    ),
    sorter: (a: Contract, b: Contract) =>
      new Date(a?.lastRenewalDate).getTime() - new Date(b?.lastRenewalDate).getTime()
  },
  {
    title: () => <Typography.Text>Primary Contact</Typography.Text>,
    dataIndex: ["primaryContact", "email"],
    key: "primaryContact",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Entity Name</Typography.Text>,
    dataIndex: "entityName",
    key: "entityName",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
    sorter: (a: Contract, b: Contract) =>
      a?.entityName?.toLowerCase()?.localeCompare(b?.entityName?.toLowerCase()),
  },
  {
    title: () => <Typography.Text>Entity Phone</Typography.Text>,
    dataIndex: "entityPhone",
    key: "entityPhone",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Entity Email</Typography.Text>,
    dataIndex: "entityEmail",
    key: "entityEmail",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Alert Days</Typography.Text>,
    dataIndex: "alertDays",
    key: "alertDays",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
];

export default function ContractsList() {
  const [selectedRow, setSelectedRow] = useState<Contract>();
  const { TableFilters, filteredContracts, UseQueryHookResult } =
    useContractFilter();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    contractColumns.map((col) => col.key as string)
  );
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [visibleColumnOrder, setVisibleColumnOrder] = useState<string[]>(
    contractColumns.map((col) => col.key as string)
  );
  const navigate = useNavigate();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  // https://codesandbox.io/p/sandbox/000vqw38rl
  const selectRow = (record: Contract) => {
    if (selectedRow?.id + "" === record?.id + "") {
      setSelectedRow(undefined);
    } else {
      setSelectedRow(record);
    }
  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const handleSavePreferences = (
    selectedColumns: string[],
    columnOrder: string[]
  ) => {
    setSelectedColumns(selectedColumns);
    setVisibleColumnOrder(columnOrder);
    handleCloseModal();
  };

  const visibleColumns = visibleColumnOrder
    .filter((colKey) => selectedColumns.includes(colKey))
    .map((colKey) => contractColumns.find((col) => col.key === colKey)!);

  const [deleteContract] = useDeleteContractMutation();

  return (
    <Layout style={{ margin: "20px 30px 0 30px" }}>
      <Space size="large" direction="vertical">
        <Table
          style={{
            boxShadow: "0px 10px 25px 0px #5911920D",
            borderRadius: "16px",
            border: "1px solid #E5E7EB",
            overflow: "hidden",
          }}
          size="small"
          columns={visibleColumns}
          loading={
            UseQueryHookResult.isLoading || UseQueryHookResult.isFetching
          }
          rowKey="id"
          dataSource={!!filteredContracts ? [...filteredContracts] : []}
          onRow={(record) => ({
            onClick: () => {
              selectRow(record);
            },
          })}
          rowSelection={{
            type: "radio",
            selectedRowKeys: !!selectedRow?.id ? [selectedRow?.id] : [],
            onChange: (selectedRowKeys, selectedRows, info) => {
              if (!!selectedRows && !!selectedRows?.at(0))
                setSelectedRow(selectedRows?.at(0));
            },
            columnWidth: 60,
          }}
          pagination={{
            position: ["bottomRight"],
            style: { backgroundColor: "white", padding: "10px", margin: "0" },
            pageSizeOptions: [10, 25, 50],
            showSizeChanger: true,
            total: filteredContracts?.length,
            showTotal: (total) => `Total ${total} items`,
          }}
          title={() => (
            <Space direction="vertical" style={{ display: "flex" }}>
              <Flex
                align="start"
                justify={"space-between"}
                style={{ margin: "16px 10px 0 10px" }}
              >
                <Space size="middle">
                  <Typography.Title
                    style={{ paddingTop: "0", marginTop: "0" }}
                    level={4}
                  >
                    {`Contracts (${filteredContracts?.length})`}
                  </Typography.Title>
                </Space>
                <Space size="middle">
                  <IncredableButton
                    onClick={() => navigate("add")}
                    type="primary"
                    icon={<PlusOutlined />}
                    size="large"
                  >
                    Add Contract
                  </IncredableButton>
                </Space>
              </Flex>
              <TableTitle
                filter={TableFilters}
                actionDropDownProps={{
                  disabled: !selectedRow,
                  menu: {
                    items: [
                      {
                        label: "Edit",
                        key: "edit",
                        icon: <EditOutlined />,
                      },
                      {
                        label: "Delete",
                        key: "delete",
                        icon: <DeleteOutlined />,
                        danger: true,
                      },
                    ],
                    onClick: async (e) => {
                      if (e?.key === "edit") {
                        navigate(`${selectedRow?.id}/edit`);
                      }
                      if (e?.key === "delete") {
                        handleOpenDeleteModal();
                      }
                    },
                  },
                }}
                onSettingsClick={handleOpenModal}
              />
            </Space>
          )}
        />
        <TablePreferenceModal
          visible={isModalVisible}
          onCancel={handleCloseModal}
          onSave={handleSavePreferences}
          availableColumns={contractColumns.map((col) => col.key as string)}
          selectedColumns={selectedColumns}
          columnOrderState={visibleColumnOrder}
        />
        <DeleteAlertModal
          visible={isDeleteModalOpen}
          action={async () => {
            if (selectedRow?.id) {
              setDeleteModalOpen(false);
              spinnerContext?.showSpinner();
              await deleteContract({
                contractId: selectedRow?.id,
              });
              spinnerContext?.hidespinner();
              toastContext?.openSuccessNotification({
                message: `Contract deleted successfully`,
                placement: "topRight",
              });
            }
          }}
          closeModal={() => setDeleteModalOpen(false)}
          header="Delete Contract"
          content={
            <Typography.Text>
              Contract Name:{" "}
              <Typography.Text strong>{selectedRow?.title}</Typography.Text>
            </Typography.Text>
          }
          description="Are you sure you want to delete this contract?"
        />
      </Space>
    </Layout>
  );
}

import { SearchOutlined } from "@ant-design/icons";
import { Input, Select, Space } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useGetBasicAllProvidersQuery } from "../redux/api/provider/provider";
import {
  useGetAllTaskStatusQuery,
  useGetAllTaskTypeQuery,
  useGetMyTasksQuery,
} from "../redux/api/task";
import { useGetAllWorkflowQuery } from "../redux/api/workflow/workflow";

export default function useMyTasksFilter() {
  const [selectedFilterkey, setSelectedFilterKey] = useState<string>("name");
  const [selectedFiltervalue, setSelectedFiltervalue] = useState<
    string | undefined
  >(undefined);

  const { data: providers, isLoading: providersLoading } =
    useGetBasicAllProvidersQuery();
  const { data: taskTypes, isLoading: taskTypesLoading } =
    useGetAllTaskTypeQuery();
  const { data: taskStatus, isLoading: taskStatusLoading } =
    useGetAllTaskStatusQuery();
  const { data: workflow, isLoading: facilitiesLoading } =
    useGetAllWorkflowQuery();
  const {
    data: myTasks,
    refetch,
    isLoading: workflowLoading,
    isFetching,
  } = useGetMyTasksQuery();

  const filteredMyTasks = useMemo(() => {
    if (!!selectedFiltervalue) {
      if (selectedFilterkey === "name")
        return myTasks?.filter((task) =>
          task?.name?.toLowerCase().includes(selectedFiltervalue?.toLowerCase())
        );
      else if (selectedFilterkey === "taskType")
        return myTasks?.filter(
          (task) => task?.taskType?.id + "" === selectedFiltervalue + ""
        );
      else if (selectedFilterkey === "status")
        return myTasks?.filter(
          (task) => task?.taskStatus?.id + "" === selectedFiltervalue + ""
        );
      else if (selectedFilterkey === "provider")
        return myTasks?.filter(
          (task) => task?.provider?.id + "" === selectedFiltervalue + ""
        );
      else if (selectedFilterkey === "workflow")
        return myTasks?.filter(
          (task) => task?.workflow?.id + "" === selectedFiltervalue + ""
        );
    }
    return myTasks;
  }, [selectedFiltervalue, myTasks]);

  useEffect(() => {
    setSelectedFiltervalue(undefined);
  }, [selectedFilterkey]);

  const TableFilters = (
    <Space size="small">
      {selectedFilterkey === "taskType" && (
        <Select
          allowClear
          showSearch
          value={selectedFiltervalue}
          placeholder="Choose task type"
          onChange={(value) => {
            setSelectedFiltervalue(value);
          }}
          style={{ width: "320px" }}
          optionFilterProp="label"
          options={taskTypes?.map((taskType) => ({
            label: taskType?.name,
            searchValue: taskType?.name,
            value: taskType?.id + "",
          }))}
        />
      )}
      {selectedFilterkey === "name" && (
        <Input
          onChange={(event) => {
            setSelectedFiltervalue(event?.target?.value);
          }}
          value={selectedFiltervalue}
          style={{
            width: "320px",
          }}
          placeholder="Find Name"
          prefix={<SearchOutlined />}
        />
      )}
      {selectedFilterkey === "status" && (
        <Select
          allowClear
          showSearch
          optionFilterProp="label"
          key="status-select"
          value={selectedFiltervalue}
          placeholder="Select status"
          onChange={(value) => {
            setSelectedFiltervalue(value);
          }}
          style={{ width: "320px" }}
          options={taskStatus?.map((taskStatu) => ({
            label: taskStatu?.name,
            searchValue: taskStatu?.name,
            value: taskStatu?.id + "",
          }))}
        />
      )}
      {selectedFilterkey === "provider" && (
        <Select
          allowClear
          showSearch
          optionFilterProp="label"
          value={selectedFiltervalue}
          placeholder="Select Provider"
          onChange={(value) => {
            setSelectedFiltervalue(value);
          }}
          style={{ width: "320px" }}
          options={providers?.map((provider) => ({
            label: `${provider?.firstName} ${provider?.lastName}`,
            searchValue: `${provider?.firstName} ${provider?.lastName}`,
            value: provider?.id + "",
          }))}
        />
      )}
      {selectedFilterkey === "workflow" && (
        <Select
          allowClear
          showSearch
          optionFilterProp="label"
          value={selectedFiltervalue}
          placeholder="Select Workflow"
          onChange={(value) => {
            setSelectedFiltervalue(value);
          }}
          style={{ width: "320px" }}
          options={workflow?.map((work) => ({
            label: work?.name,
            searchValue: work?.name,
            value: work?.id + "",
          }))}
        />
      )}
      <Select
        value={selectedFilterkey}
        onChange={(value) => {
          setSelectedFilterKey(value);
        }}
        style={{ width: "140px" }}
        options={[
          { value: "name", label: "Name" },
          { value: "taskType", label: "Task Type" },
          { value: "status", label: "Status" },
          { value: "provider", label: "Provider" },
          { value: "workflow", label: "Workflow" },
        ]}
      />
    </Space>
  );

  return {
    TableFilters,
    filteredMyTasks,
    UseQueryHookResult: {
      refetch,
      isLoading:
        workflowLoading &&
        providersLoading &&
        taskTypesLoading &&
        taskStatusLoading &&
        facilitiesLoading,
      isFetching,
    },
  };
}

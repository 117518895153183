import { Col, Divider, Flex, Form, Row, theme, Typography, Space } from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import { useGetBasicAllProvidersQuery } from "../../redux/api/provider/provider";
import IncredableButton from "../../components/button";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import IncredableContent from "../../components/incredable-content";
import { alertReminderOptions } from "../../common/constants";
import RHFSelect from "../../components/RHF/RHFSelect";
import { PayerEnrollmentRequest } from "../../redux/api/payerenrollment/types";
import {
  useCreatePayerEnrollmentMutation,
  useGetAllPayerEnrollmentStatusQuery,
} from "../../redux/api/payerenrollment/payerenrollment";
import { useGetAllPayerQuery } from "../../redux/api/payers/payers";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";
import { useGetAllPayerGroupQuery } from "../../redux/api/payergroup/payergroup";
import { useGetAllWorkflowQuery } from "../../redux/api/workflow/workflow";
import { ReloadOutlined } from "@ant-design/icons";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import { useGetAllFacilityGroupQuery } from "../../redux/api/facilitygroup/facilityGroup";

const { useToken } = theme;

export default function AddProviderEnrolledPayer() {
  const { providerId, deaId } = useParams();
  const methods = useForm<PayerEnrollmentRequest>();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();
  const { token } = useToken();
  const { data: status = [] } = useGetAllPayerEnrollmentStatusQuery();
  const { data: payer = [] } = useGetAllPayerQuery();
  const { data: networks = [] } = useGetAllPayerGroupQuery();
  const { data: workflows = [], refetch } = useGetAllWorkflowQuery();
  const {
    data: providers = [],
    isLoading,
    isFetching,
  } = useGetBasicAllProvidersQuery();
  const { data: facilities = [] } = useGetFacilitiesQuery();
  const { data: facilityGroup = [] } = useGetAllFacilityGroupQuery();
  useEffect(() => {
    if (providerId) {
      methods.setValue("providerId", providerId);
    }
  }, [providerId]);

  const [addPayerEnrollment] = useCreatePayerEnrollmentMutation();

  return (
    <IncredableContent spinning={isLoading || isFetching}>
      <FormProvider {...methods}>
        <Form
          name="addproviderenrolledpayer"
          layout="vertical"
          onFinish={methods.handleSubmit(
            async (data: PayerEnrollmentRequest) => {
              if (!!providerId) {
                if (!data.payerId && !data.payerGroupId) {
                  toastContext?.openErrorNotification({
                    message: `Network or Payer is required.`,
                    placement: "top",
                  });
                  return;
                }
                if (!data.providerId && !data.facilityId) {
                  toastContext?.openErrorNotification({
                    message: `Provider or Facility is required.`,
                    placement: "top",
                  });
                  return;
                }
                spinnerContext?.showSpinner();
                await addPayerEnrollment({ ...data, providerId: providerId });
                spinnerContext?.hidespinner();
                toastContext?.openSuccessNotification({
                  message: `Enrolled Payer saved successfully`,
                  placement: "topRight",
                });
                navigate(-1);
              }
            }
          )}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                color: "#1D1A1F",
                lineHeight: "25px",
                fontWeight: "700",
              }}
            >
              Add Enrolled Payer
            </Typography.Text>
            <Space>
              <IncredableButton
                secondary
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" htmlType="submit">
                Save
              </IncredableButton>
            </Space>
          </Flex>
          <Divider />
          <Form.List name="enrolled payer" initialValue={[{}]}>
            {(fields, { add }) => {
              return (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div key={key}>
                      {index > 0 && <Divider />}
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Payer" }}
                            controllerProps={{
                              name: `payerId`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select payer",
                              options: payer?.map((name) => {
                                return {
                                  label: name?.name,
                                  value: name?.id,
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Network" }}
                            controllerProps={{
                              name: `payerGroupId`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select network",
                              options: networks?.map((name) => {
                                return {
                                  label: name?.name,
                                  value: name?.id,
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Provider" }}
                            controllerProps={{
                              name: `providerId`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select provider",
                              options: providers?.map((name) => {
                                return {
                                  label: name?.firstName + " " + name?.lastName,
                                  value: name?.id + "",
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Facility" }}
                            controllerProps={{
                              name: `facilityId`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select facility",
                              options: facilities?.map((name) => {
                                return {
                                  label: name?.name + "",
                                  value: name?.id + "",
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Facility Group" }}
                            controllerProps={{
                              name: `facilityGroupId`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select Facility Group",
                              options: facilityGroup?.map((name) => {
                                return {
                                  label: name?.name + "",
                                  value: name?.id + "",
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Status" }}
                            controllerProps={{
                              name: `enrollmentStatusId`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select status",
                              options: status?.map((name) => {
                                return {
                                  label: name?.name,
                                  value: name?.id,
                                };
                              }),
                              onModifyClick: () => {
                                navigate("/payerenrollmentstatus");
                              },
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{
                              label: "Submitted Date",
                            }}
                            controllerProps={{
                              name: `submittedDate`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{
                              label: "Approved Date",
                            }}
                            controllerProps={{
                              name: `approvedDate`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{
                              label: "Effective Date",
                            }}
                            controllerProps={{
                              name: `effectiveDate`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{
                              label: "Re-Enrollment Date",
                            }}
                            controllerProps={{
                              name: `recredentialingDate`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Payer Provider Id",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `payerProviderId`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Follow Up Date" }}
                            controllerProps={{
                              name: `alertDays`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select Days",
                              options: alertReminderOptions?.map((alert) => {
                                return {
                                  label: alert?.label,
                                  value: alert?.value,
                                };
                              }),
                            }}
                          />
                        </Col>
                      </Row>
                      <Divider />
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={24} style={{ marginBottom: "5px" }}>
                          <Typography.Text>Workflows</Typography.Text>
                        </Col>
                        <Col span={12}>
                          <RHFSelect
                            formItemProps={{ label: "" }}
                            controllerProps={{
                              name: `workflowId`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select workflow",
                              options: workflows?.map((name) => {
                                return {
                                  label: name?.name,
                                  value: name?.id,
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={12}>
                          <ReloadOutlined
                            size={20}
                            style={{ marginLeft: "10px" }}
                            onClick={() => refetch()}
                          />
                          <IncredableButton
                            style={{
                              borderColor: "#598BEB",
                              color: "#598BEB",
                              marginLeft: "10px",
                            }}
                            secondary
                            onClick={() => navigate("/workflows/add")}
                          >
                            Create New Workflow
                          </IncredableButton>
                        </Col>
                      </Row>
                      <Divider />
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={24}>
                          <RHFTextArea
                            formItemProps={{ label: "Notes" }}
                            controllerProps={{ name: "description" }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}
                </>
              );
            }}
          </Form.List>
        </Form>
      </FormProvider>
    </IncredableContent>
  );
}
